import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/layout/actions";
import {
  get_menu,
  add_menu,
  delete_menu,
  put_menu,
} from "../../store/menu/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.initMenu();
    await this.props.get_menu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show");
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {this.props.menu.menuitems &&
              this.props.menu.menuitems.map((menuitm, index) => (
                <li key={index}>
                  <Link
                    key={menuitm.id}
                    to={menuitm.link}
                    className="waves-effect"
                  >
                    <i className={menuitm.icon + " ri-2x"}></i>
                    <span className="ml-3">{menuitm.title}</span>
                  </Link>
                </li>
              ))}
            {this.props.menu.submenus &&
              this.props.menu.submenus.map((submenu, index) => (
                <li key={index}>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-store-2-line"></i>
                    <span className="ml-1">{submenu.name}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {submenu.menuitems.map((mi, idx) => (
                      <li key={idx}>
                        <Link to={mi.link}>{mi.title}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}

            {/* {this.props.menu.map((menuob, index) => (
              <li key={index}>
                {menuob.menuitems && menuob.menuitems.length == 0 ? (
                  <Link
                    key={menuob.id}
                    to={menuob.link}
                    className="waves-effect"
                  >
                    <i className={menuob.icon + " ri-2x"}></i>
                    <span className="ml-3">{menuob.name}</span>
                  </Link>
                ) : (
                  <>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="ri-store-2-line"></i>
                      <span className="ml-1">{menuob.name}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      {menuob.menuitems.map((mi, idx) => (
                        <li>
                          <Link to="ecommerce-shops">{mi.code}</Link>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </li>
            ))} */}
          </ul>
        </div>
        {/* <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">1Menu2</li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="badge badge-pill badge-success float-right">
                  3
                </span>
                <span className="ml-1">1Dashboard2</span>
              </Link>
            </li>

            <li>
              <Link to="calendar" className=" waves-effect">
                <i className="ri-calendar-2-line"></i>
                <span className="ml-1">1Calendar2</span>
              </Link>
            </li>

            <li>
              <Link to="apps-chat" className=" waves-effect">
                <i className="ri-chat-1-line"></i>
                <span className="ml-1">1Chat2</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-store-2-line"></i>
                <span className="ml-1">1Ecommerce2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="ecommerce-products">1Products2</Link>
                </li>
                <li>
                  <Link to="ecommerce-product-detail">1Product Detail2</Link>
                </li>
                <li>
                  <Link to="ecommerce-orders">1Orders2</Link>
                </li>
                <li>
                  <Link to="ecommerce-customers">1Customers2</Link>
                </li>
                <li>
                  <Link to="ecommerce-cart">1Cart2</Link>
                </li>
                <li>
                  <Link to="ecommerce-checkout">1Checkout2</Link>
                </li>
                <li>
                  <Link to="ecommerce-shops">1Shops2</Link>
                </li>
                <li>
                  <Link to="ecommerce-add-product">1Add Product2</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">1Email2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="email-inbox">1Inbox2</Link>
                </li>
                <li>
                  <Link to="email-read">1Read Email2</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="apps-kanban-board" className=" waves-effect">
                <i className="ri-artboard-2-line"></i>
                <span className="ml-1">1Kanban Board2</span>
              </Link>
            </li>

            <li className="menu-title">1Pages2</li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-account-circle-line"></i>
                <span className="ml-1">1Authentication2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="auth-login">1Login2</Link>
                </li>
                <li>
                  <Link to="auth-register">1Register2</Link>
                </li>
                <li>
                  <Link to="auth-recoverpw">1Recover Password2</Link>
                </li>
                <li>
                  <Link to="auth-lock-screen">1Lock Screen2</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-profile-line"></i>
                <span className="ml-1">1Utility2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="pages-starter">1Starter Page2</Link>
                </li>
                <li>
                  <Link to="pages-maintenance">1Maintenance2</Link>
                </li>
                <li>
                  <Link to="pages-comingsoon">1Coming Soon2</Link>
                </li>
                <li>
                  <Link to="pages-timeline">1Timeline2</Link>
                </li>
                <li>
                  <Link to="pages-faqs">1FAQs2</Link>
                </li>
                <li>
                  <Link to="pages-pricing">1Pricing2</Link>
                </li>
                <li>
                  <Link to="pages-404">1Error 4042</Link>
                </li>
                <li>
                  <Link to="pages-500">1Error 5002</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">1Components2</li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">1UI Elements2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="ui-alerts">1Alerts2</Link>
                </li>
                <li>
                  <Link to="ui-buttons">1Buttons2</Link>
                </li>
                <li>
                  <Link to="ui-cards">1Cards2</Link>
                </li>
                <li>
                  <Link to="ui-carousel">1Carousel2</Link>
                </li>
                <li>
                  <Link to="ui-dropdowns">1Dropdowns2</Link>
                </li>
                <li>
                  <Link to="ui-grid">1Grid2</Link>
                </li>
                <li>
                  <Link to="ui-images">1Images2</Link>
                </li>
                <li>
                  <Link to="ui-lightbox">1Lightbox2</Link>
                </li>
                <li>
                  <Link to="ui-modals">1Modals2</Link>
                </li>
                <li>
                  <Link to="ui-rangeslider">1Range Slider2</Link>
                </li>
                <li>
                  <Link to="ui-roundslider">1Round Slider2</Link>
                </li>
                <li>
                  <Link to="ui-session-timeout">1Session Timeout2</Link>
                </li>
                <li>
                  <Link to="ui-progressbars">1Progress Bars2</Link>
                </li>
                <li>
                  <Link to="ui-sweet-alert">1Sweet Alerts2</Link>
                </li>
                <li>
                  <Link to="ui-tabs-accordions">1Tabs & Accordions2</Link>
                </li>
                <li>
                  <Link to="ui-typography">1Typography2</Link>
                </li>
                <li>
                  <Link to="ui-video">1Video2</Link>
                </li>
                <li>
                  <Link to="ui-general">1General2</Link>
                </li>
                <li>
                  <Link to="ui-rating">1Rating2</Link>
                </li>
                <li>
                  <Link to="ui-notifications">1Notifications2</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <i className="ri-eraser-fill"></i>
                <span className="badge badge-pill badge-danger float-right">
                  6
                </span>
                <span className="ml-1">1Forms2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="form-elements">1Elements2</Link>
                </li>
                <li>
                  <Link to="form-validation">1Validation2</Link>
                </li>
                <li>
                  <Link to="form-advanced">1Advanced Plugins2</Link>
                </li>
                <li>
                  <Link to="form-editors">1Editors2</Link>
                </li>
                <li>
                  <Link to="form-uploads">1File Upload2</Link>
                </li>
                <li>
                  <Link to="form-xeditable">1X-editable2</Link>
                </li>
                <li>
                  <Link to="form-wizard">1Wizard2</Link>
                </li>
                <li>
                  <Link to="form-mask">1Mask2</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-table-2"></i>
                <span className="ml-1">1Tables2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="tables-basic">1Basic Tables2</Link>
                </li>
                <li>
                  <Link to="tables-datatable">1Data Tables2</Link>
                </li>
                <li>
                  <Link to="tables-responsive">1Responsive Table2</Link>
                </li>
                <li>
                  <Link to="tables-editable">1Editable Table2</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-bar-chart-line"></i>
                <span className="ml-1">1Charts2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="charts-apex">1Apexcharts2</Link>
                </li>
                <li>
                  <Link to="charts-chartjs">1Chartjs2</Link>
                </li>
                <li>
                  <Link to="charts-knob">1Jquery Knob2</Link>
                </li>
                <li>
                  <Link to="charts-sparkline">1Sparkline2</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-brush-line"></i>
                <span className="ml-1">1Icons2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="icons-remix">1Remix Icons2</Link>
                </li>
                <li>
                  <Link to="icons-materialdesign">1Material Design2</Link>
                </li>
                <li>
                  <Link to="icons-dripicons">1Dripicons2</Link>
                </li>
                <li>
                  <Link to="icons-fontawesome">1Font awesome 52</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-map-pin-line"></i>
                <span className="ml-1">1Maps2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="maps-google">1Google Maps2</Link>
                </li>
                <li>
                  <Link to="maps-vector">1Vector Maps2</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-share-line"></i>
                <span className="ml-1">1Multi Level2</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/#">1Level 1.12</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    1Level 1.22
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/#">1Level 2.12</Link>
                    </li>
                    <li>
                      <Link to="/#">1Level 2.22</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      */}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { menu } = state.menu;
  return { menu };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
    get_menu,
    add_menu,
    delete_menu,
    put_menu,
  })(SidebarContent)
);
