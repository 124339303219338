export const BRAND = 'brand';
export const BRAND_ADD = 'brand_add';
export const BRAND_DELETE = 'brand_deleted';
export const BRAND_EDIT = 'brand_edit';

export const BRAND_SETROWDATA = 'brand_set_rowdata';
export const MODEL_OPEN = 'brand_model_open';
export const MODEL_CLOSE = 'brand_model_close';

export const BRAND_LIST_SUCCESSFUL = 'brand_list_successfull';
export const BRAND_ADD_SUCCESSFUL = 'brand_add_successfull';
export const BRAND_EDIT_SUCCESSFUL = 'brand_edit_successfull';

export const API_ERROR = 'api_failed';
