export const DEALERS = 'dealers';
export const DEALERS_ADD = 'dealers_add';
export const DEALERS_DELETE = 'dealers_deleted';
export const DEALERS_EDIT = 'dealers_edit';

// export const DEALERS_SETROWDATA = 'dealers_set_rowdata';
export const MODEL_OPEN = 'dealers_model_open';
export const MODEL_CLOSE = 'dealers_model_close';

export const DEALERS_LIST_SUCCESSFUL = 'dealers_list_successfull';
export const DEALERS_ADD_SUCCESSFUL = 'dealers_add_successfull';
export const DEALERS_EDIT_SUCCESSFUL = 'dealers_edit_successfull';

export const API_ERROR = 'api_failed';
