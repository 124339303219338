import {
  API_ERROR,
  VERSIONS,
  VERSIONS_LIST_SUCCESSFUL,
  VERSIONS_ADD,
  VERSIONS_ADD_SUCCESSFUL,
  VERSIONS_EDIT,
  VERSIONS_EDIT_SUCCESSFUL,
  VERSIONS_DELETE,
  // VERSIONS_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

export const getVersionsList = () => {
   return {
    type: VERSIONS,
  };
};

export const VersionsListSuccessful = (Versions) => {
  return {
    type: VERSIONS_LIST_SUCCESSFUL,
    payload: Versions,
  };
};

export const VersionsAdd = (Versions, history) => {
  return {
    type: VERSIONS_ADD,
    payload: { Versions, history },
  };
};

export const VersionsAddSuccessful = (response) => {
  return {
    type: VERSIONS_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const VersionsEdit = (data) => {
  return {
    type: VERSIONS_EDIT,
    payload: data,
  };
};

export const VersionsEditSuccessful = (response) => {
  return {
    type: VERSIONS_EDIT_SUCCESSFUL,
    payload: response,
  };
};

export const VersionsDelete = (MyId, history) => {
  return {
    type: VERSIONS_DELETE,
    payload: { MyId, history },
  };
};

// export const SetEditData = (data) => {
//   return {
//     type: VERSIONS_SETROWDATA,
//     payload: data,
//   };
// };

export const model_open = () => {
  return {
    type: MODEL_OPEN,
  };
};

export const model_close = () => {
  return {
    type: MODEL_CLOSE,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
