import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  CustomInput,
  // InputGroup,
  // Form,
  // InputGroupAddon,
} from "reactstrap";
import AdminSidebar from "./AdminSidebar/AdminSidebar";
import { AvForm } from "availity-reactstrap-validation";
import avatar2 from "../../assets/images/users/avatar-2.jpg";

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Row className="no-gutters">
          <Col md={1}>
            <AdminSidebar />
          </Col>
          <Col md={11} className="pl-5">
            <div className="page-content">
              <Container fluid={true}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <h3 className="card-title-18 pt-2">Company Profile</h3>
                  </CardHeader>
                  <CardBody>
                    {/* <h4 className="card-title-18">General</h4> */}
                    <AvForm className="needs-validation">
                      <Row className="justify-content-center">
                        <Col xs={9}>
                          <FormGroup row>
                            <Col md={3}></Col>
                            <Col md={9}>
                              <Row className="justify-content-center">
                                <Col md={3}>
                                <div className="p-image">
                                      <div className="mt-1 align-items-center ">
                                        <label htmlFor="customFile">
                                          <div className="img-icon">
                                            <i class="ri-camera-fill "></i>
                                          </div>
                                          <img
                                            className="img-thumbnail rounded-circle"
                                            alt="User"
                                            src={avatar2}
                                          />
                                          <p className="text-center mt-2">Company Logo</p>
                                        </label>

                                        <CustomInput
                                          type="file"
                                          className="file-upload"
                                          id="customFile"
                                          accept="image/*"
                                          multiple="false"
                                        />
                                      </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                <div className="p-image">
                                      <div className="mt-1 align-items-center ">
                                        <label htmlFor="customFile">
                                          <div className="img-icon">
                                            <i class="ri-camera-fill "></i>
                                          </div>
                                          <img
                                            className="img-thumbnail rounded-circle"
                                            alt="User"
                                            src={avatar2}
                                          />
                                          <p className="text-center mt-2">Small Logo</p>
                                        </label>

                                        <CustomInput
                                          type="file"
                                          className="file-upload"
                                          id="customFile"
                                          accept="image/*"
                                          multiple="false"
                                        />
                                      </div>
                                    </div>
                                </Col>
                              </Row>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationCname"
                                className="col-form-label float-right"
                              >
                                Company Name
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                name="CompanyName"
                                placeholder="Company Name"
                                type="text"
                                errormessage="Enter Company Name"
                                className="form-control"
                                defaultValue="ABSOLIN SOFTWARE SOLUTIONS"
                                validate={{ required: { value: true } }}
                                id="validationCname"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationEmail"
                                className="col-form-label float-right"
                              >
                                Company Email
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                name="Email"
                                placeholder="Email ID"
                                type="email"
                                errormessage="Enter Email ID"
                                className="form-control"
                                defaultValue="info@absolinsoft.com"
                                validate={{ required: { value: true } }}
                                id="validationEmail"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationMobile"
                                className="col-form-label float-right"
                              >
                                Company Mobile
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                name="Mobile"
                                placeholder="Mobile "
                                type="number"
                                errormessage="Enter Mobile No"
                                className="form-control"
                                defaultValue="1223456789"
                                validate={{ required: { value: true } }}
                                id="validationMobile"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationDataBase"
                                className=" col-form-label float-right"
                              >
                                Database Name
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                name="DataBase"
                                placeholder="DataBase Name"
                                type="text"
                                errormessage="Enter DataBase Name"
                                className="form-control"
                                defaultValue="absolinsoft"
                                validate={{ required: { value: true } }}
                                id="validationDataBase"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationWebsite"
                                className=" col-form-label float-right"
                              >
                                Website
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                name="Website"
                                placeholder="Website"
                                type="url"
                                errormessage="Enter Company Website"
                                className="form-control"
                                defaultValue="www.wheelsmart.in"
                                validate={{ required: { value: true } }}
                                id="validationWebsite"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationGST"
                                className=" col-form-label float-right "
                              >
                                GST No
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                name="GSTNo"
                                placeholder="GST No"
                                type="text"
                                errormessage="Enter GST No"
                                className="form-control"
                                defaultValue="QWERT"
                                validate={{ required: { value: true } }}
                                id="validationGST"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationAddress"
                                className=" col-form-label float-right "
                              >
                                Company Address:{" "}
                              </Label>
                            </Col>
                            <Col md={9}>
                              <Input
                                name="CompanyAddress"
                                placeholder="Company Address"
                                type="textarea"
                                errormessage=" Please provide Company Address."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationAddress"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="user-Profile-input"
                                className=" col-form-label float-right "
                                id="user-Profile-input"
                              >
                                Select City
                              </Label>
                            </Col>
                            <Col md={9}>
                              <select className="form-control">
                                <option>Select</option>
                                <option>visakhapatnam</option>
                                <option>vijayawada</option>
                                <option>kakinada</option>
                              </select>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="user-location-input"
                                className=" col-form-label float-right "
                                id="user-location-input"
                              >
                                Select State
                              </Label>
                            </Col>
                            <Col md={9}>
                              <select className="form-control">
                                <option>Select</option>
                                <option>Andhra pradesh</option>
                                <option>telangana</option>
                              </select>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="user-location-input"
                                className=" col-form-label float-right "
                                id="user-location-input"
                              >
                                Select Country
                              </Label>
                            </Col>
                            <Col md={9}>
                              <select className="form-control">
                                <option>Select</option>
                                <option>India</option>
                                {/* <option>telangana</option> */}
                              </select>
                            </Col>
                          </FormGroup>
                          <div className="float-right">
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-2"
                            >
                              Save
                            </Button>
                            {/* <Button type="submit" color="light">
                              Cancel
                            </Button> */}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CompanyDetails;
