import {
  API_ERROR,
  VERSIONS,
  VERSIONS_LIST_SUCCESSFUL,
  VERSIONS_ADD,
  VERSIONS_ADD_SUCCESSFUL,
  VERSIONS_EDIT,
  VERSIONS_EDIT_SUCCESSFUL,
  VERSIONS_DELETE,
  // VERSIONS_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  Versionslist: [],
  model: false,
  error_msg: null,
  rowdata: {},
};

const Versions = (state = initialState, action) => {
  switch (action.type) {
    case VERSIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VERSIONS_LIST_SUCCESSFUL:
      state = {
        ...state,
        Versionslist: action.payload.data,
        loading: false,
      };
      break;
    case VERSIONS_ADD:
      state = {
        ...state,
        loading: false,
      };
      break;
    case VERSIONS_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
      };
      break;
    case VERSIONS_EDIT:
      state = {
        ...state,
        model: true,
        rowdata: action.payload,
      };
      break;
    case VERSIONS_EDIT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case VERSIONS_DELETE:
      state = {
        ...state,
        loading: false,
      };
      break;
    // case VERSIONS_SETROWDATA:
    //   state = {
    //     ...state,
    //     rowdata: action.payload,
    //     // model: true,
    //   };
      break;
    case MODEL_OPEN:
      state = {
        ...state,
        model: true,
      };
      break;
    case MODEL_CLOSE:
      state = {
        ...state,
        model: false,
        rowdata: { id: 0 },
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Versions;
