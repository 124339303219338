import {
  API_ERROR,
  VERSION_TYPES,
  VERSION_TYPES_LIST_SUCCESSFUL,
  VERSION_TYPES_ADD,
  VERSION_TYPES_ADD_SUCCESSFUL,
  VERSION_TYPES_EDIT,
  VERSION_TYPES_EDIT_SUCCESSFUL,
  VERSION_TYPES_DELETE,
  VERSION_TYPES_CHANGEVALUE,
  VERSION_TYPES_SELECT_CHANGEVALUE,
  // VERSION_TYPES_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

export const getVersionTypesList = () => {
  return {
    type: VERSION_TYPES,
  };
};

export const VersionTypesListSuccessful = (VersionTypes) => {
  return {
    type: VERSION_TYPES_LIST_SUCCESSFUL,
    payload: VersionTypes,
  };
};

export const VersionTypesAdd = (VersionTypes, history) => {
  return {
    type: VERSION_TYPES_ADD,
    payload: { VersionTypes, history },
  };
};

export const VersionTypesAddSuccessful = (response) => {
  return {
    type: VERSION_TYPES_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const VersionTypesEdit = (data) => {
  return {
    type: VERSION_TYPES_EDIT,
    payload: data,
  };
};

export const VersionTypesEditSuccessful = (response) => {
  return {
    type: VERSION_TYPES_EDIT_SUCCESSFUL,
    payload: response,
  };
};

export const VersionTypesDelete = (MyId, history) => {
  return {
    type: VERSION_TYPES_DELETE,
    payload: { MyId, history },
  };
};

export const VersionTypesChangeValue = ({ key, value }) => {
  return {
    type: VERSION_TYPES_CHANGEVALUE,
    payload: { key, value },
  };
};
export const VersionTypesSelecteChangeValue = ({ key, label,key2, value }) => {
  return {
    type: VERSION_TYPES_SELECT_CHANGEVALUE,
    payload: { key, label,key2, value },
  };
};

// export const SetEditData = (data) => {
//   return {
//     type: VERSION_TYPES_SETROWDATA,
//     payload: data,
//   };
// };

export const model_open = ({ id }) => {
  console.log("model.Id", id);
  return {
    type: MODEL_OPEN,
    payload: { id },
  };
};

export const model_close = () => {
  return {
    type: MODEL_CLOSE,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
