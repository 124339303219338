import {
  API_ERROR,
  USERS_LIST,
  USERS_LIST_SUCCESS,
  // MODEL_OPEN,
  // MODEL_CLOSE,
  // USER_ADD,
  // USER_ADD_SUCCESSFUL,
  // USER_DELETE,
  // GROUPS,
  // GROUPS_LIST_SUCCESS,
  // GROUP_ADD,
  // GROUP_ADD_SUCCESSFUL,
} from "./actionTypes";

export const getUsers = () => {
  return {
    type: USERS_LIST,
  };
};
export const usersListSuccessful = (userRowList) => {
  return {
    type: USERS_LIST_SUCCESS,
    payload: userRowList,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

// export const model_open = () => {
//   return {
//     type: MODEL_OPEN,
//   };
// };

// export const model_close = () => {
//   return {
//     type: MODEL_CLOSE,
//   };
// };

// export const userAdd = (userdata, history) => {
//   return {
//     type: USER_ADD,
//     payload: { userdata, history },
//   };
// };
// export const userAddSuccessfull = (response) => {
//   return {
//     type: USER_ADD_SUCCESSFUL,
//     payload: response,
//   };
// };
// export const userDelete = (userId, history) => {
//   return {
//     type: USER_DELETE,
//     payload: { userId, history },
//   };
// };

// export const getGroups = () => {
//   return {
//     type: GROUPS,
//   };
// };

// export const groupsListSuccessful = (groupslist) => {
//   return {
//     type: GROUPS_LIST_SUCCESS,
//     payload: groupslist,
//   };
// };

// export const groupAdd = (groupdata, history) => {
//   return {
//     type: GROUP_ADD,
//     payload: { groupdata, history },
//   };
// };
// export const groupAddSuccessfull = (response) => {
//   return {
//     type: GROUP_ADD_SUCCESSFUL,
//     payload: response,
//   };
// };
