import React, { Component } from "react";

import { Row, Col, Card, CardHeader, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./Masters.scss";

import * as Locations from "../../store/Masters/Locations/actions";
import * as Brand from "../../store/Masters/Brand/actions";
import * as ModelYears from "../../store/Masters/ModelYears/actions";
import * as VehicleTypes from "../../store/Masters/VehicleTypes/actions";
import * as Dealers from "../../store/Masters/Dealers/actions";
import * as Versions from "../../store/Masters/Versions/actions";
import * as VersionTypes from "../../store/Masters/VersionTypes/actions";

import {
  getMastersMenu,
  // add_menu,
  // delete_menu,
  // put_menu
} from "../../store/Masters/MastersMenu/actions";

class Masters extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.getMastersMenu();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardHeader className="bg-transparent border-bottom">
                <h3 className="card-title-18 pt-2">Masters Dashboard</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <h3 className="card-title-15 pt-2">Company</h3>
                    {/* <hr className="my-3" /> */}
                  </Col>
                  <Col md={12}>
                    <div className="Master-dashboard">
                      <div className="Master-dashboard-menu">
                        <Row className="mb-2">
                          {this.props.mastersMenuList.menuitems &&
                            this.props.mastersMenuList.menuitems.map(
                              (report, key) => (
                                <Col md={4} key={key}>
                                  <div className="Master-dashboard-item mb-2 ">
                                    <div className="title-box d-inline-block">
                                      <span className="mt-4">
                                        {report.title}{" "}
                                      </span>
                                    </div>

                                    <div className="view-box float-right">
                                      <Link
                                        to="#"
                                        onClick={()=>this.props[report.link]({id:0})}
                                        className="waves-effect ml-2"
                                      >
                                        <i className="ri-add-fill"></i>
                                      </Link>
                                      <Link
                                        to={report.link}
                                        className="waves-effect ml-2"
                                      >
                                        <i className="ri-menu-fill"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              )
                            )}
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  const { mastersMenuList } = state.MastersMenu;
  return { mastersMenuList };
};
export default withRouter(
  connect(mapStatetoProps, {
    getMastersMenu,
    "/VehicleTypes": VehicleTypes.model_open,
    "/ModelYears": ModelYears.model_open,
    "/Brand": Brand.model_open,
    "/Locations": Locations.model_open,
    "/Dealers": Dealers.model_open,
    "/Versions":Versions.model_open,
    "/VersionTypes" : VersionTypes.model_open,

    })(Masters)
);
