import React, { Component } from "react";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
} from "reactstrap";

import {
  getVehicleTypesList,
  VehicleTypesAdd,
  VehicleTypesEdit,
  VehicleTypesDelete,
  model_open,
  model_close,
} from "../../../store/Masters/VehicleTypes/actions";

class AddVehicleTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    this.props.VehicleTypesAdd(values, this.props.history);
  }
  render() {
    var data = this.props.rowdata;
    return (
      <React.Fragment>
        <Modal isOpen={this.props.model} backdrop="static" centered>
          <ModalHeader toggle={() => this.props.model_close()}>
            ADD Vehicle Types
          </ModalHeader>
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.handleValidSubmit}
          >
            <ModalBody>
              <Row className="justify-content-center">
                <Col md={10}>
                  {/* <Row className="justify-content-center">
                    <Col md={9}> */}
                      {/* <FormGroup row>
                        <Col md={4}>
                          <Label
                            htmlFor="VehicleTypesCode"
                            className="col-form-label float-right"
                          >
                            Model Years Code
                          </Label>
                        </Col>
                        <Col md={8}>
                          <AvField
                            name="code"
                            type="text"
                            readOnly
                            value={data.code}
                            className="form-control"
                            id="VehicleTypesCode"
                            placeholder="Enter Model Year Code"
                            errormessage=" Please provide a valid Model Year Code"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </FormGroup> */}
                      <FormGroup row>
                        <Col md={4}>
                          <Label
                            htmlFor="VehicleTypes"
                            className="col-form-label float-right"
                          >
                           Vehicle Type
                          </Label>
                        </Col>
                        <Col md={8}>
                          <AvField
                            name="name"
                            type="text"
                            value={data.name}
                            className="form-control"
                            id="VehicleTypes"
                            placeholder="Enter Vehicle Type"
                            errormessage=" Please provide a valid Vehicle Type"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <AvField
                          className="form-control"
                          type="hidden"
                          value={data.createdon}
                          id="createdon"
                          name="createdon"
                        />
                        <AvField
                          className="form-control"
                          type="hidden"
                          value={data.id}
                          id="id"
                          name="id"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                {/* </Col>
              </Row> */}

              <ModalFooter>
                <Button
                  type="button"
                  className="btn btn-danger"
                  color="light"
                  onClick={() => this.props.model_close()}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  color="primary"
                >
                  Save
                </Button>
              </ModalFooter>
            </ModalBody>
          </AvForm>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { VehicleTypeslist, model, rowdata } = state.VehicleTypes;
  return { VehicleTypeslist, model, rowdata };
};

export default connect(mapStatetoProps, {
  getVehicleTypesList,
  VehicleTypesAdd,
  VehicleTypesEdit,
  VehicleTypesDelete,
  model_open,
  model_close,
})(AddVehicleTypes);
