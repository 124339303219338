import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { USERS_LIST, 
    // USER_ADD, USER_DELETE, GROUPS, GROUP_ADD
 } from "./actionTypes";
import {
  apiError,
  usersListSuccessful,
//   getUsers,
//   userAddSuccessfull,
//   groupsListSuccessful,
//   groupAddSuccessfull,
//   getGroups,
} from "./actions";

// AUTH related methods
import {
//   postAdd,
  getList,
//   postEdit,
//   postDelete,
//   getRowData,
//   postUpload,
} from "../../helpers/Helper";
import swal from "sweetalert";

function* getUserList() {
  console.log("saga_User_table_data");
  try {
    const response = yield call(getList, "/users/usergroups/", {});
    console.log("List Response", response);
    yield put(usersListSuccessful(response));
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}
//If user is login then dispatch redux action's are directly from here.
// function* AddUser({ payload: { userdata } }) {
//   console.log("User data: ", userdata);
//   try {
//     if (userdata.id === 0) {
//       const response = yield call(postAdd, "/users/usergroups/", {
//         username: userdata.username,
//         password: userdata.password,
//         first_name: userdata.first_name,
//         last_name: userdata.last_name,
//         email: userdata.email,
//         mobile: userdata.mobile,
//         location: userdata.location,
//       });
//       console.log(response);
//       if (response.status === 200) {
//         swal("Poof! Your User Added Successfully.!", {
//           icon: "success",
//         });
//         yield put(getUsers());
//       } else {
//         swal("Sorry! Unable to ad User. Please try agine!", {
//           icon: "error",
//         });
//       }
//       yield put(userAddSuccessfull(null));
//     } else {
//       const response = yield call(
//         postEdit,
//         "/users/usergroups/" + userdata.id,
//         {
//           username: userdata.username,
//           first_name: userdata.first_name,
//           last_name: userdata.last_name,
//           email: userdata.email,
//           mobile: userdata.mobile,
//           location: userdata.location,
//         }
//       );
//       if (response.status === 200) {
//         swal("Poof! Your User Edited Successfully.!", {
//           icon: "success",
//         });
//         yield put(getUsers());
//       } else {
//         swal("Sorry! Unable to edit User. Please try agine!", {
//           icon: "error",
//         });
//       }
//       yield put(userAddSuccessfull(null));
//     }
//   } catch (error) {
//     swal(error, {
//       icon: "error",
//     });
//     //yield put(apiError(error));
//   }
// }

// function* DeleteUsers(userId) {
//   console.log(userId.payload.userId);
//   try {
//     const response = yield call(
//       postDelete,
//       "/apimasters/musers/" + userId.payload.userId + "/"
//     );
//     console.log(response);
//     if (response.status === 204) {
//       swal("Poof! Your User has been deleted!", {
//         icon: "success",
//       });
//       yield put(getUsers());
//     } else {
//       swal("Sorry! Your User has been not deleted!", {
//         icon: "error",
//       });
//     }
//     // yield put(locationDeleteSuccessful(response.statusText))
//   } catch (error) {
//     swal(error, {
//       icon: "error",
//     });

//     //yield put(apiError(error));
//   }
// }

// function* getGroupList() {
//   try {
//     const response = yield call(
//       getList,
//       "/apimasters/mgroups/?format=datatables",
//       {}
//     );
//     console.log("List Response", response.status);
//     yield put(groupsListSuccessful({ response }));
//   } catch (error) {
//     console.log(error);
//     yield put(apiError(error));
//   }
// }

// function* AddGroup({ payload: { groupdata } }) {
//   console.log("User data: ", groupdata);
//   try {
//     if (groupdata.id === 0) {
//       const response = yield call(postAdd, "/apimasters/mgroups/", {
//         name: groupdata.pName,
//       });
//       console.log(response);
//       if (response.status === 200) {
//         swal("Poof! Your User Added Successfully.!", {
//           icon: "success",
//         });
//         yield put(getGroups());
//       } else {
//         swal("Sorry! Unable to ad User. Please try agine!", {
//           icon: "error",
//         });
//       }
//       yield put(groupAddSuccessfull(null));
//     } else {
//       const response = yield call(
//         postEdit,
//         "/apimasters/musers/" + groupdata.id + "/",
//         { name: groupdata.username }
//       );
//       if (response.status === 200) {
//         swal("Poof! Your User Edited Successfully.!", {
//           icon: "success",
//         });
//         yield put(getGroups());
//       } else {
//         swal("Sorry! Unable to edit User. Please try agine!", {
//           icon: "error",
//         });
//       }
//       yield put(groupAddSuccessfull(null));
//     }
//   } catch (error) {
//     swal(error, {
//       icon: "error",
//     });
//     //yield put(apiError(error));
//   }
// }

export function* watchgetUsers() {
  yield takeEvery(USERS_LIST, getUserList);
}
// export function* watchUserAdd() {
//   console.log("THis is Saga Add Loc");
//   yield takeEvery(USER_ADD, AddUser);
// }
// export function* watchUserDelete() {
//   console.log("THis is Saga Delete Loc");
//   yield takeEvery(USER_DELETE, DeleteUsers);
// }
// export function* watchgetGroups() {
//   yield takeEvery(GROUPS, getGroupList);
// }

// export function* watchGroupAdd() {
//   console.log("THis is Saga Add Loc");
//   yield takeEvery(GROUP_ADD, AddGroup);
// }

function* UsersSaga() {
  yield all([
    fork(watchgetUsers),
    // fork(watchUserAdd),
    // fork(watchUserDelete),
    // fork(watchgetGroups),
    // fork(watchGroupAdd),
  ]);
}

export default UsersSaga;
