import {
  API_ERROR,
  VERSION_TYPES,
  VERSION_TYPES_LIST_SUCCESSFUL,
  VERSION_TYPES_ADD,
  VERSION_TYPES_ADD_SUCCESSFUL,
  VERSION_TYPES_EDIT,
  VERSION_TYPES_EDIT_SUCCESSFUL,
  VERSION_TYPES_DELETE,
  VERSION_TYPES_CHANGEVALUE,
  VERSION_TYPES_SELECT_CHANGEVALUE,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  VersionTypeslist: [],
  model: false,
  error_msg: null,
  rowdata: {},
};

const VersionTypes = (state = initialState, action) => {
  switch (action.type) {
    case VERSION_TYPES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VERSION_TYPES_LIST_SUCCESSFUL:
      state = {
        ...state,
        VersionTypeslist: action.payload.data,
        loading: false,
      };
      break;
    case VERSION_TYPES_ADD:
      state = {
        ...state,
        loading: false,
        // rowdata: action.payload,
      };

      break;
    case VERSION_TYPES_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
      };
      break;
    case VERSION_TYPES_EDIT:
      state = {
        ...state,
        model: true,
        rowdata: action.payload,
      };
      break;
    case VERSION_TYPES_EDIT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case VERSION_TYPES_DELETE:
      state = {
        ...state,
        loading: false,
      };
      break;
      case VERSION_TYPES_CHANGEVALUE:
        state = {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            // [e.target.name]: e.target.value
          },
        };
        break;

        case VERSION_TYPES_SELECT_CHANGEVALUE:
          state = {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.label,
              [action.payload.key2]: action.payload.value,
              // [e.target.name]: e.target.value
            },
          };
          break;
      
    case MODEL_OPEN:
      state = {
        ...state,
        model: true,
        rowdata: { id: action.payload.id },
      };
      break;
    case MODEL_CLOSE:
      state = {
        ...state,
        model: false,
        rowdata: {  id: 0 },
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VersionTypes;
