export const VERSION_TYPES = 'version_types';
export const VERSION_TYPES_ADD = 'version_types_add';
export const VERSION_TYPES_DELETE = 'version_types_deleted';
export const VERSION_TYPES_EDIT = 'version_types_edit';
export const VERSION_TYPES_CHANGEVALUE = 'version_types_chagevalue';
export const VERSION_TYPES_SELECT_CHANGEVALUE = 'version_types_select_chagevalue';

// export const VERSION_TYPES_SETROWDATA = 'version_types_set_rowdata';
export const MODEL_OPEN = 'version_types_model_open';
export const MODEL_CLOSE = 'version_types_model_close';

export const VERSION_TYPES_LIST_SUCCESSFUL = 'version_types_list_successfull';
export const VERSION_TYPES_ADD_SUCCESSFUL = 'version_types_add_successfull';
export const VERSION_TYPES_EDIT_SUCCESSFUL = 'version_types_edit_successfull';

export const API_ERROR = 'api_failed';
