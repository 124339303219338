import { takeEvery, fork, put, all, call } from "redux-saga/effects";
// import React, { Component }  from 'react';
// Login Redux States
import {
  LOCATIONS,
  LOCATION_ADD,
  LOCATION_DELETE,
  MODEL_OPEN,
} from "./actionTypes";
import {
  apiError,
  getLocationslist,
  locationListSuccessful,
  locationAddSuccessful,
  SetEditData,
} from "./actions";

// AUTH related methods
import {
  postAdd,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";

// import swal from "sweetalert";
import Swal from "sweetalert2";
// import SweetAlert from "react-bootstrap-sweetalert";

function* getLocation() {
  try {
    const data = yield call(getList, "/masters/locations/", {});

    yield put(locationListSuccessful({ data }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* OnSetEditData() {
  var today = new Date(),
    code =
      "LOC" +
      today.getFullYear() +
      (today.getMonth() + 1) +
      today.getDate() +
      today.getHours() +
      today.getMinutes() +
      today.getSeconds();
  const createdon =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    " " +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  yield put(SetEditData({ id: 0, code, createdon }));
}
//If user is login then dispatch redux action's are directly from here.
function* AddLocation({ payload: { location } }) {
  try {
    if (location.id == 0) {
      const response = yield call(postAdd, "/masters/locations/", {
        code: location.code,
        name: location.name,
        createdon: new Date(location.createdon),
      });
      if (response.status === 201) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your Location Added Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        // swal("Poof! Your Location Added Successfully.!", {
        //   icon: "success",
        // });
        yield put(getLocationslist());
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to add Location. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
        // swal("Sorry! Unable to add Location. Please try agine!", {
        //   icon: "error",
        // });
      }
      yield put(locationAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/locations/" + location.id,
        {
          code: location.code,
          name: location.name,
          modifiedon: new Date(location.createdon),
        }
      );
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your Location Added Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        // swal("Poof! Your Location Edited Successfully.!", {
        //   icon: "success",
        // });
        yield put(getLocationslist());
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to add Location. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
        // swal("Sorry! Unable to edit Location. Please try agine!", {
        //   icon: "error",
        // });
      }
      yield put(locationAddSuccessful(null));
    }
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });
    // swal("Sorry! Permission Denied!", {
    //   icon: "error",
    // });
    //yield put(apiError(error));
  }
}

//If user is login then dispatch redux action's are directly from here.
function* DeleteLocation(locId) {
  try {
    const response = yield call(
      postDelete,
      "/masters/locations/" + locId.payload.locId
    );
    if (response.status === 204) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your Location has been deleted!",
        showConfirmButton: false,
        timer: 1500,
      });
      // swal("Poof! Your Location has been deleted!", {
      //   icon: "success",
      // });
      yield put(getLocationslist());
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Sorry! Your Location has been not deleted!",
        showConfirmButton: false,
        timer: 1500,
      });
      // swal("Sorry! Your Location has been not deleted!", {
      //   icon: "error",
      // });
    }
    // yield put(locationDeleteSuccessful(response.statusText))
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });

    // swal("Sorry! Permission Denied!", {
    //   icon: "error",
    // });

    yield put(apiError(error));
  }
}

export function* watchgetLocationslist() {
  yield takeEvery(LOCATIONS, getLocation);
}
export function* watchLocationAdd() {
  yield takeEvery(LOCATION_ADD, AddLocation);
}

export function* watchLocationDelete() {
  yield takeEvery(LOCATION_DELETE, DeleteLocation);
}
export function* watchSetEditData() {
  yield takeEvery(MODEL_OPEN, OnSetEditData);
}
function* LocationSaga() {
  yield all([
    fork(watchgetLocationslist),
    fork(watchLocationAdd),
    fork(watchLocationDelete),
    fork(watchSetEditData),
  ]);
}

export default LocationSaga;
