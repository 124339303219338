import axios from "axios";

const postLogin = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

const getList = (url) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);

  return axios
    .get(url, { headers: { Authorization: "Token " + sjson.token } })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// postForgetPwd
const postAdd = (url, data) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .post(url, data, {
      headers: { Authorization: "Token " + sjson.token },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err[1];

      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postEdit = (url, data) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .put(url, data, { headers: { Authorization: "Token " + sjson.token } })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// postForgetPwd
const postAddmultipart = (url, data) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .post(url, data, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err[1];

      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postEditmultipart = (url, data) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .put(url, data, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err[1];

      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};
const postDelete = (url) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .delete(url, {
      headers: {
        Authorization: "Token " + sjson.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err;

      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const getRowData = (url) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .get(url, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err;

      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postUpload = (url, csvfile) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  // var formData = new FormData();
  // formData.append("file", csvfile.files[0]);
  return axios
    .post(url, csvfile, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err;

      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

export {
  postLogin,
  getList,
  postAdd,
  postEdit,
  postDelete,
  getRowData,
  postUpload,
  postAddmultipart,
  postEditmultipart,
};
