export const USERS_LIST = "users_list";
export const USERS_LIST_SUCCESS = "users_list_success";
export const API_ERROR = "api_failed";


// export const MODEL_OPEN = 'users_model_open';
// export const MODEL_CLOSE = 'users_model_close';
// export const USER_ADD = 'user_add';
// export const USER_ADD_SUCCESSFUL = 'user_add_successful';
// export const USER_DELETE = 'user_delete';
// export const GROUPS = 'list_groups';
// export const GROUPS_LIST_SUCCESS = 'groups_list_group';
// export const GROUP_ADD = 'group_add';
// export const GROUP_ADD_SUCCESSFUL = 'group_add_successful';
