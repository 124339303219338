import React, { Component } from "react";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  ButtonGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Alert,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import SimpleBar from "simplebar-react";
// import TableScrollbar from "react-table-scrollbar";

import img1 from "../../assets/images/product/img-1.png";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/product/img-3.png";
import img4 from "../../assets/images/product/img-4.png";

class AllOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionMenu: false,
      modal_static: false,
      activeTab: 1,
      isAlertOpen: false,
      Material: [
        {
          id: 1,
          list: "PI055",
          color: "primary",
          listItems: 2,
          Description: "No Description Provided",
          dDate: "Dec 04 2020",
          City: "Visakhapatnam",
          ProductName: "Honda SP 125",
          CustomerName: "Nagendra Gopal",
          by: "PENDING",
          userImg: img2,
          userNmae: "Nagendra Gopal",
          actionMenu: false,
        },
        {
          id: 2,
          list: "PI025",
          color: "success",
          listItems: 12,
          Description: "No Description Provided",

          dDate: "Jun 04 2020",
          City: "Visakhapatnam",
          ProductName: "Honda SP 125",
          CustomerName: "Nagendra Gopal",
          by: "APPROVED",
          userImg: img2,
          userNmae: "Nagendra Gopal",
          actionMenu: false,
        },
        {
          id: 3,
          list: "PI025",
          color: "primary",
          listItems: 12,
          Description: "No Description Provided",
          dDate: "Jun 04 2020",
          City: "Visakhapatnam",
          ProductName: "Honda SP 125",
          CustomerName: "Nagendra Gopal",
          by: "PENDING",
          userImg: img2,
          userNmae: "Nagendra Gopal",
          actionMenu: false,
        },
        {
          id: 4,
          list: "PI025",
          color: "danger",
          listItems: 12,
          Description: "No Description Provided",
          dDate: "Jun 04 2020",
          City: "Visakhapatnam",
          ProductName: "Honda SP 125",
          CustomerName: "Nagendra Gopal",
          by: "REJECTED",
          userImg: img2,
          userNmae: "Nagendra Gopal",
          actionMenu: false,
        },
        {
          id: 5,
          list: "PI025",
          color: "primary",
          listItems: 12,
          Description: "No Description Provided",
          dDate: "Jun 04 2020",
          City: "Visakhapatnam",
          ProductName: "Honda SP 125",
          CustomerName: "Nagendra Gopal",
          by: "PENDING",
          userImg: img2,
          userNmae: "Nagendra Gopal",
          actionMenu: false,
        },
        {
          id: 6,
          list: "PI025",
          color: "success",
          listItems: 12,
          Description: "No Description Provided",
          dDate: "Jun 04 2020",
          City: "Visakhapatnam",
          ProductName: "Honda SP 125",
          CustomerName: "Nagendra Gopal",
          by: "APPROVED",
          userImg: img2,
          userNmae: "Nagendra Gopal",
          actionMenu: false,
        },
      ],
      products: [
        //Unique Id must be required
        {
          id: 1,
          img: img1,
          name: "50MM C I FLANGE 2INCH",
          desc: "Pipe Fittings",
          price: 240,
          Quantity: 2,
        },
        {
          id: 2,
          img: img2,
          name: "32MM CP V C TEE JOINT 11/4INCH",
          desc: "Pipe Fittings",
          price: 225,
          Quantity: 4,
        },
        {
          id: 3,
          img: img3,
          name: "32 X 25MM CP V C REDUCER BUSH 1 1/4INCH X IINCH",
          desc: "Pipe Fittings",
          price: 275,
          Quantity: 1,
        },
        {
          id: 4,
          img: img4,
          name: "32MM CP V C FTA 11/4",
          desc: "Pipe Fittings",
          price: 275,
          Quantity: 2,
        },
      ],
    };
    this.tog_static = this.tog_static.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }
  tog_static() {
    this.setState((prevState) => ({
      modal_static: !prevState.modal_static,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  toggleMenu = (item) => {
    var arr = this.state.Material.map((i, idx) => {
      if (i.id === item.id) {
        if (i.actionMenu === true) {
          i.actionMenu = !i.actionMenu;
        } else {
          i.actionMenu = !i.actionMenu;
        }
      } else {
        i.actionMenu = false;
      }

      return i;
    });
    this.setState({ arr: arr });
  };

  render() {
    const paginationItem = [];
    for (var i = 1; i <= 5; i++) {
      if ((i === 1)) {
        paginationItem.push(
          <>
            <PaginationItem disabled>
              <span className="page-link">
                <i className="mdi mdi-chevron-left"></i>
              </span>
            </PaginationItem>

            <PaginationItem active>
              <PaginationLink href="#">{i}</PaginationLink>
            </PaginationItem>
          </>
        );
      } else {
        paginationItem.push(
          <>
          {/* <PaginationItem>
              <span className="page-link">
                <i className="mdi mdi-chevron-left"></i>
              </span>
            </PaginationItem> */}
            <PaginationItem>
              <PaginationLink href="#">{i}</PaginationLink>
            </PaginationItem>
          </>
        );
      }
    }
    return (
      <React.Fragment>
        <div className="mt-2 ">
          <div className="pagebody">
            <Row>
              <Col md={3}>
                <Form className="p-1">
                  <FormGroup className="m-0">
                    <InputGroup>
                      <Input
                        type="text"
                        className="form-control d-inline-block"
                        placeholder="Search"
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="light" type="submit">
                          <i className="ri-search-line"></i>
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Form>
              </Col>
              <Col md={9}>
                <div className="float-right p-1">
                  <Pagination
                    aria-label="Page navigation example"
                    className="pagination-rounded d-inline-block"
                  >
                    {paginationItem}
                    {/* <PaginationItem active>
                      <PaginationLink  href="#">1</PaginationLink>
                    </PaginationItem>
                    <PaginationItem >
                      <span className="page-link">
                        2<span className="sr-only">(current)</span>
                      </span>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink href="#">3</PaginationLink>
                    </PaginationItem> */}
                    <PaginationItem>
                      <PaginationLink href="#">
                        <i className="mdi mdi-chevron-right"></i>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                  <Button
                    type="button"
                    color="light"
                    className="waves-effect waves-light d-inline-block mr-2"
                    // onClick=""
                  >
                    <i className="mdi mdi-sort-descending mr-1"></i>
                    Order Number
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ minHeight: "10px" }}>
            <Card className="p-0">
              <CardBody className="p-2">
                {this.state.Material.map((mt, idx) => (
                  <div className="bg_color p-2 mb-1" key={mt.id}>
                    <Row className="justify-content-center">
                      <Col md={2} className="pt-2">
                        <div className="Material_detiles p-1">
                          <Button
                            type="button"
                            color={mt.color}
                            className="waves-effect waves-light orderlist_but p-1 mb-2"
                            style={{ width: "100%" }}
                            href="/Order"
                          >
                            <i className="mdi mdi-file-document mr-1"></i>
                            {mt.list}
                          </Button>
                        </div>
                        <div className="p-1">
                          <Link
                            to="#"
                            className="m-0"
                            onClick={this.tog_static}
                          >
                            <i className="mdi mdi-download mr-1"></i>
                            Download Order
                          </Link>
                        </div>
                      </Col>
                      <Col md={10}>
                        <div className="Material_body">
                          <div className="float-right ml-3">
                            <ButtonGroup>
                              <Dropdown
                                isOpen={mt.actionMenu}
                                toggle={() => this.toggleMenu(mt)}
                              >
                                <DropdownToggle caret color="light" size="sm">
                                  <i className="ri-more-line "></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem>Edit </DropdownItem>
                                  <DropdownItem>Delete </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>{" "}
                            </ButtonGroup>
                          </div>
                          {/* <p className="mb-1 body_title">{mt.Description}</p> */}
                        </div>
                        {/* <hr className="my-0" /> */}
                        <Row className="p-0">
                          <Col md={8} className="p-0">
                            <div className="Material-panel-content">
                              <div className="list-column">
                                <ul className="list-style-seven p-0">
                                  <li className="clearfix">
                                    <span className="ttl">Date:</span>
                                    <span className="dtl">{mt.dDate}</span>
                                  </li>
                                  <li className="clearfix">
                                    <span className="ttl">Customer City:</span>
                                    <span className="dtl">{mt.City}</span>
                                  </li>
                                  <li className="clearfix">
                                    <span className="ttl">Product Name:</span>
                                    <span className="dtl">
                                      {mt.ProductName}
                                    </span>
                                  </li>
                                  <li className="clearfix">
                                    <span className="ttl">Customer Name:</span>
                                    <span className="dtl">
                                      {mt.CustomerName}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="float-right">
                              <div className="user-content-box">
                                <ul className="list-style-seven mb-1">
                                  <li className="clearfix">
                                    <span className="ttl">{mt.by}</span>
                                  </li>
                                </ul>
                              </div>
                              <ul className="user-column p-0">
                                <li className="clearfix p-0 m-0">
                                  <span className="img">
                                    <img
                                      className="img-fluid p-0"
                                      src={mt.userImg}
                                      alt="user"
                                    />
                                  </span>
                                  <span className="ttl">{mt.userNmae}</span>
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ))}
              </CardBody>
            </Card>
          </SimpleBar>
        </div>
        <Modal
          isOpen={this.state.modal_static}
          toggle={this.tog_static}
          backdrop="static"
          centered
          size="lg"
        >
          <ModalHeader toggle={() => this.setState({ modal_static: false })}>
            Item Details
          </ModalHeader>
          <ModalBody className="pt-0">
            <AvForm onValidSubmit={this.handleValidSubmit}>
              <Row className="justify-content-center">
                <Col md={12}>
                  <Alert
                    color="success"
                    isOpen={this.state.isAlertOpen}
                    toggle={() => this.setState({ isAlertOpen: false })}
                  >
                    User Added Successfully...!
                  </Alert>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col lg={12}>
                      <Nav tabs className="nav-tabs-custom ">
                        <NavItem>
                          <NavLink
                            onClick={() => {
                              this.toggleTab(1);
                            }}
                            className={classnames(
                              { active: this.state.activeTab === 1 },
                              "pt-3 font-weight-bold card-title-16"
                            )}
                          >
                            Items List | <span>6</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            // onClick={() => {
                            //   this.toggleTab(2);
                            // }}
                            className={classnames(
                              { active: this.state.activeTab === 2 },
                              "pt-3 font-weight-bold card-title-16"
                            )}
                            //   className={
                            //     this.state.activeTab === 1
                            //       ? "previous disabled"
                            //       : "previous"
                            //   }
                          >
                            Details
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={1}>
                          <div className="mt-2">
                            <Form className="p-1">
                              <FormGroup className="m-0">
                                <InputGroup>
                                  <Input
                                    type="text"
                                    className="form-control d-inline-block"
                                    placeholder="Search Item"
                                  />
                                  <InputGroupAddon addonType="append">
                                    <Button color="light" type="submit">
                                      <i className="ri-search-line"></i>
                                    </Button>
                                  </InputGroupAddon>
                                </InputGroup>
                              </FormGroup>
                            </Form>
                          </div>

                          <div className="table-responsive">
                            <Table className="table-centered mb-0 table-nowrap">
                              <thead className="bg-light ">
                                <tr>
                                  <th style={{ width: "120px" }}>
                                    Item Images
                                  </th>
                                  <th style={{ width: "220px" }}>Item Name</th>
                                  <th style={{ width: "120px" }}>Quantity</th>
                                  <th style={{ width: "120px" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.products.map((product, key) => (
                                  <tr key={key}>
                                    <td>
                                      <img
                                        src={product.img}
                                        alt="product-img"
                                        title="product-img"
                                        className="avatar-sm"
                                      />
                                    </td>
                                    <td>
                                      <h5 className="font-size-14 text-truncate">
                                        <Link
                                          to="#"
                                          className="text-dark"
                                          onClick={() => {
                                            this.toggleTab(2);
                                          }}
                                        >
                                          {product.name}
                                        </Link>
                                      </h5>
                                      <p className="mb-0">
                                        Description :{" "}
                                        <span className="font-weight-medium">
                                          {product.desc}
                                        </span>
                                      </p>
                                    </td>
                                    <td>{product.Quantity}</td>

                                    <td
                                    //   style={{ width: "120px" }}
                                    //   className="text-center"
                                    >
                                      <Button
                                        type="sudmit"
                                        color="primary"
                                        className="waves-effect waves-light mr-2"
                                        onClick={() => {
                                          this.toggleTab(2);
                                        }}
                                        // onClick={() =>
                                        //     this.removeCartItem(product.id)
                                        //   }
                                      >
                                        <i className="mdi mdi-arrow-top-right mr-2"></i>
                                        View
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Row>
                            <Col md={5}>
                              <div className="product-detail">
                                <img
                                  src={img1}
                                  alt="product-img"
                                  title="product-img"
                                  className="img-fluid mx-auto tab-img rounded"
                                  style={{ height: "350px", width: "400px" }}
                                />
                              </div>
                            </Col>
                            <Col xl={7}>
                              <div className="mt-4 mt-xl-3">
                                <div className="float-right ">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="waves-effect waves-light d-inline-block mr-2"
                                    onClick={() => {
                                      this.toggleTab(1);
                                    }}
                                  >
                                    <i className="mdi mdi-backup-restore mr-1"></i>
                                    Back
                                  </Button>
                                </div>
                                <div>
                                  <h5 className="mt-1 mb-1">
                                    50MM C I FLANGE 2INCH
                                  </h5>
                                  <div>
                                    <p className="d-inline-block">Code:0001</p>
                                    <p className="float-right d-inline-block mr-3">
                                      Make: ************
                                    </p>
                                  </div>
                                </div>
                                <hr className="my-1" />

                                <Row>
                                  <Col md={12}>
                                    <div className="Order-panel-content">
                                      <h5 className="font-size-14">
                                        Specification :
                                      </h5>
                                      <div className="list-column">
                                        <ul className="list-style-two p-0 product-desc-list">
                                          <li className="clearfix">
                                            <span className="ttl">
                                              <i className="mdi mdi-circle-medium mr-1 align-middle"></i>
                                              Quantity :
                                            </span>
                                            <span className="dtl">
                                              04
                                              {/* {Material.dDate} */}
                                            </span>
                                          </li>
                                          <li className="clearfix">
                                            <span className="ttl">
                                              <i className="mdi mdi-circle-medium mr-1 align-middle"></i>
                                              Section :
                                            </span>
                                            <span className="dtl">
                                              CI
                                              {/* {Material.DEPARTMENT} */}
                                            </span>
                                          </li>
                                          <li className="clearfix">
                                            <span className="ttl">
                                              <i className="mdi mdi-circle-medium mr-1 align-middle"></i>
                                              Required Date:
                                            </span>
                                            <span className="dtl">
                                              15-03-2021
                                              {/* {Material.DEPARTMENT} */}
                                            </span>
                                          </li>
                                          <li className="clearfix">
                                            <span className="ttl">
                                              <i className="mdi mdi-circle-medium mr-1 align-middle"></i>
                                              Item Unit :
                                            </span>
                                            <span className="dtl">
                                              00001
                                              {/* {Material.DEPARTMENT} */}
                                            </span>
                                          </li>
                                          {/* <li className="clearfix">
                                            <span className="ttl">
                                              <i className="mdi mdi-circle-medium mr-1 align-middle"></i>
                                              Code :
                                            </span>
                                            <span className="dtl">
                                              ##001
                                             
                                            </span>
                                          </li> */}
                                          <li className="clearfix">
                                            <span className="ttl">
                                              <i className="mdi mdi-circle-medium mr-1 align-middle"></i>
                                              Department :
                                            </span>
                                            <span className="dtl">
                                              Plumbing
                                              {/* {Material.SUBMITED} */}
                                            </span>
                                          </li>
                                          <li className="clearfix">
                                            <span className="ttl">
                                              <i className="mdi mdi-circle-medium mr-1 align-middle"></i>
                                              Category :
                                            </span>
                                            <span className="dtl">
                                              Pipe Fittings
                                              {/* {Material.SUBMITED} */}
                                            </span>
                                          </li>
                                          <li className="clearfix">
                                            <span className="ttl">
                                              <i className="mdi mdi-circle-medium mr-1 align-middle"></i>
                                              Product Group :
                                            </span>
                                            <span className="dtl">
                                              PIPE & PIPE FITTIGS/VALVES GROUP
                                              {/* {Material.SUBMITED} */}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </Col>{" "}
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </AvForm>
            <ModalFooter>
              <div className="float-right">
                <Button
                  type="button"
                  color="light"
                  className="waves-effect waves-light"
                  onClick={() => {
                    this.setState({ modal_static: false });
                  }}
                >
                  Close
                </Button>
              </div>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AllOrders;
