import React, { Component } from "react";
import {
  Container,
  Card,
  CardHeader,
  Button,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import classnames from "classnames";
// import { Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
// import SimpleBar from "simplebar-react";


// import { connect } from "react-redux"

import AllOrders from "./AllOrders";

import "./PurchaseIndent.scss";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader className="bg-transparent pb-0">
                    <h5 className="card-title-18 pt-2 d-inline-block">
                    Orders Dashboard
                    </h5>
                    <div className="float-right">
                    <Dropdown
                        isOpen={this.state.isSocialPf}
                        toggle={() =>
                          this.setState({ isSocialPf: !this.state.isSocialPf })
                        }
                        className="d-lg-inline-block"
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-light mr-2"
                          // className="btn header-item noti-icon waves-effect"
                        >
                          <i className="mdi mdi-filter mr-1"></i>
                          Filters
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-lg" right>
                          <div className="px-lg-2">
                            <AvForm>
                              <Row
                              // className="no-gutters"
                              >
                                <Col md={6} className="mb-0">
                                  <FormGroup className="mb-0">
                                    <Label
                                      htmlFor="validationFromDate"
                                      className="mb-0 col-form-label"
                                    >
                                      From Date:
                                    </Label>

                                    <Input
                                      name="Fdate"
                                      placeholder="From Date"
                                      type="date"
                                      errormessage="Select From Date "
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="validationFromDate"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-0">
                                  <FormGroup className="mb-0">
                                    <Label
                                      htmlFor="validationToDate"
                                      className="mb-0 col-form-label"
                                    >
                                      To Date:
                                    </Label>

                                    <Input
                                      name="Tdate"
                                      placeholder="To Date"
                                      type="date"
                                      errormessage="Select To Date "
                                      className="mb-0 form-control"
                                      validate={{ required: { value: true } }}
                                      id="validationToDate"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="mb-0">
                                    <Label
                                      htmlFor="Location-selection"
                                      className="col-form-label"
                                    >
                                      Locations :
                                    </Label>

                                    <Select
                                      //   options={this.props.locationslist.map(
                                      //     (item, index) => ({
                                      //       label: item.name,
                                      //       value: item.id,
                                      //     })
                                      //   )}
                                      //   value={{
                                      //     label: this.state.location_name,
                                      //     value: this.state.location_id,
                                      //   }}
                                      //   onChange={(e) => {
                                      //     this.setState({
                                      //       location_name: e.label,
                                      //       location_id: e.value,
                                      //     });
                                      //     this.setLocValues(e.label, e.value);
                                      //   }}
                                      name="location-selection"
                                      classNamePrefix="Location-selection"
                                      id="Location-selection"
                                      errormessage=" Please provide a valid Location"
                                      validate={{ required: { value: true } }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="mb-0">
                                    <Label
                                      htmlFor="Department-selection"
                                      className="col-form-label"
                                    >
                                      Department :
                                    </Label>

                                    <Select
                                      //   options={this.props.locationslist.map(
                                      //     (item, index) => ({
                                      //       label: item.name,
                                      //       value: item.id,
                                      //     })
                                      //   )}
                                      //   value={{
                                      //     label: this.state.location_name,
                                      //     value: this.state.location_id,
                                      //   }}
                                      //   onChange={(e) => {
                                      //     this.setState({
                                      //       location_name: e.label,
                                      //       location_id: e.value,
                                      //     });
                                      //     this.setLocValues(e.label, e.value);
                                      //   }}
                                      name="Department-selection"
                                      classNamePrefix="Department-selection"
                                      id="Location-selection"
                                      errormessage=" Please provide a valid Location"
                                      validate={{ required: { value: true } }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <div className="float-right pt-1">
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="waves-effect waves-light"
                                    >
                                      Apply
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </AvForm>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                      {/* <Button
                        type="button"
                        color="primary"
                        className="waves-effect waves-light"
                        href="/NewPurchaseIndentRequest"
                      >
                        New Purchase Indent
                      </Button> */}
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <Nav tabs className="nav-tabs-custom ">
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                          className={classnames(
                            { active: this.state.activeTab === "1" },
                            "pt-3 font-weight-bold card-title-16"
                          )}
                        >
                          All | <span>6</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("2");
                          }}
                          className={classnames(
                            { active: this.state.activeTab === "2" },
                            "pt-3 font-weight-bold card-title-16"
                          )}
                        >
                          Pending | <span>0</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("3");
                          }}
                          className={classnames(
                            { active: this.state.activeTab === "3" },
                            "pt-3 font-weight-bold card-title-16"
                          )}
                        >
                          Approved | <span>0</span> 
                        {/* confirmed */}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            this.toggleTab("4");
                          }}
                          className={classnames(
                            { active: this.state.activeTab === "4" },
                            "pt-3 font-weight-bold card-title-16"
                          )}
                        >
                          Rejected | <span>0</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId={"1"}>
                        <AllOrders />
                     
                      </TabPane>
                      <TabPane tabId={"2"}>
                      <AllOrders />
                      </TabPane>
                      <TabPane tabId={"3"}>
                      <AllOrders />
                      </TabPane>
                      <TabPane tabId={"4"}>
                      <AllOrders />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default Orders;
