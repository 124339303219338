import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// users
import avatar2 from "../../../assets/images/users/avatar-2.jpg";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    let username = "Admin";
    let company = "Absolin";
    if (localStorage.getItem("authUser")) {
      // const obj = JSON.parse(localStorage.getItem("authUser"));
      const uNm = username;
      // const uNm = obj.data ? obj.data.username : "";
      username = uNm.toUpperCase();
      company = company.toUpperCase();
    }

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className=" user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <img
              className="rounded-circle header-profile-user mr-1"
              src={avatar2}
              alt="Header Avatar"
            />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>{username}</DropdownItem>
            <DropdownItem text="true" href="/UserProfile">
              <i className="ri-user-line align-middle mr-2"></i> Profile
            </DropdownItem>
            <DropdownItem text="true" href="/ChangePassword">
              <i className="ri-lock-unlock-line align-middle mr-2"></i> Change
              Password
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>{company}</DropdownItem>
            <DropdownItem text="true" href="/MastersDashboard">
              <i className="ri-file-list-line align-middle mr-2"></i> Masters
            </DropdownItem>
            <DropdownItem text="true" href="/Getcompany">
              <i className="ri-settings-2-line align-middle mr-2"></i> Company
              Settings
            </DropdownItem>

            <DropdownItem text="true" href="/Users">
              <i className="ri-user-add-line align-middle mr-2"></i> Add More
              Users
            </DropdownItem>

            {/* <DropdownItem text="true" href="#">
              <i className="ri-database-2-line align-middle mr-2"></i>{" "}
              Erase Databases
            </DropdownItem> */}
            <DropdownItem divider />
            <DropdownItem text="true" className="text-danger" href="/logout">
              <i className="ri-shut-down-line align-middle mr-2 text-danger"></i>{" "}
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default ProfileMenu;
