import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  // CustomInput,
  // InputGroup,
  // Form,
  // InputGroupAddon,
} from "reactstrap";
// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
// import avatar2 from "../../assets/images/users/avatar-2.jpg";

class ChangePassword extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Card>
              <CardHeader className="bg-transparent border-bottom">
                <h3 className="card-title-18 pt-2">Password and Login</h3>
              </CardHeader>
              <CardBody>
                <Row className="justify-content-center">
                  <Col xs={9}>
                    <Card>
                      <CardBody>
                        <h4 className="card-title-18">Change Password</h4>
                      
                        <Row className="justify-content-center">
                          <Col xs={9}>
                           <FormGroup row>
                              <Col md={4}>
                                <Label
                                  htmlFor="Current-password-input"
                                  className="col-form-label float-right"
                                >
                                Current Password
                                </Label>
                              </Col>
                              <Col md={8}>
                                <Input
                                  className="form-control"
                                  type="password"
                                  defaultValue="NagendraGopalK"
                                  id="Current-password-input"
                                  disabled = {true}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col md={4}>
                                <Label
                                  htmlFor="new-password-input"
                                  className="col-form-label float-right"
                                >
                                  New Password
                                </Label>
                              </Col>
                              <Col md={8}>
                                <Input
                                  className="form-control"
                                  type="password"
                                  defaultValue="Nagendra Gopal"
                                  id="New-password-input"
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col md={4}>
                                <Label
                                  htmlFor="confirm-password-input"
                                  className="col-form-label float-right"
                                >
                                  Confirm Password
                                </Label>
                              </Col>
                              <Col md={8}>
                                <Input
                                  className="form-control"
                                  type="password"
                                  defaultValue="Gopal@gmail.com"
                                  id="confirm-password-input"
                                />
                              </Col>
                            </FormGroup>
                            
                            <div className="float-right">
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-2"
                          >
                           Change password
                          </Button>
                          <Button type="submit" color="light">
                          Cancel
                          </Button>
                        </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ChangePassword;
