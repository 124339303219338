import {
  API_ERROR,
  MODEL_YEAR,
  MODEL_YEAR_LIST_SUCCESSFUL,
  MODEL_YEAR_ADD,
  MODEL_YEAR_ADD_SUCCESSFUL,
  MODEL_YEAR_EDIT,
  MODEL_YEAR_EDIT_SUCCESSFUL,
  MODEL_YEAR_DELETE,
  MODEL_YEAR_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

export const getModelYearList = () => {
   return {
    type: MODEL_YEAR,
  };
};

export const ModelYearListSuccessful = (ModelYear) => {
  return {
    type: MODEL_YEAR_LIST_SUCCESSFUL,
    payload: ModelYear,
  };
};

export const ModelYearAdd = (ModelYear, history) => {
  return {
    type: MODEL_YEAR_ADD,
    payload: { ModelYear, history },
  };
};

export const ModelYearAddSuccessful = (response) => {
  return {
    type: MODEL_YEAR_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const ModelYearEdit = (data) => {
  return {
    type: MODEL_YEAR_EDIT,
    payload: data,
  };
};

export const ModelYearEditSuccessful = (response) => {
  return {
    type: MODEL_YEAR_EDIT_SUCCESSFUL,
    payload: response,
  };
};

export const ModelYearDelete = (MyId, history) => {
  return {
    type: MODEL_YEAR_DELETE,
    payload: { MyId, history },
  };
};

export const SetEditData = (data) => {
  return {
    type: MODEL_YEAR_SETROWDATA,
    payload: data,
  };
};

export const model_open = () => {
  return {
    type: MODEL_OPEN,
  };
};

export const model_close = () => {
  return {
    type: MODEL_CLOSE,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
