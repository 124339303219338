import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { BRAND, BRAND_ADD, BRAND_DELETE, MODEL_OPEN } from "./actionTypes";
import {
  apiError,
  getbrandlist,
  brandListSuccessful,
  brandAddSuccessful,
  SetEditData,
} from "./actions";

// AUTH related methods
import {
  postAdd,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import Swal from "sweetalert2";

function* getbrand() {
  try {
    const data = yield call(getList, "/masters/brands/", {});
    yield put(brandListSuccessful({ data }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* OnSetEditData() {
  var today = new Date(),
    code =
      "BRN" +
      today.getFullYear() +
      (today.getMonth() + 1) +
      today.getDate() +
      today.getHours() +
      today.getMinutes() +
      today.getSeconds();
  const createdon =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    " " +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  yield put(SetEditData({ id: 0, code, createdon }));
}
//If user is login then dispatch redux action's are directly from here.
function* Addbrand({ payload: { brands } }) {
  try {
    if (brands.id == 0) {
      const response = yield call(postAdd, "/masters/brands/", {
        code: brands.code,
        name: brands.name,
        createdon: new Date(brands.createdon),
      });
      if (response.status === 201) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your brand Added Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        yield put(getbrandlist());
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to add brand. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      yield put(brandAddSuccessful(null));
    } else {
      const response = yield call(postEdit, "/masters/brands/" + brands.id, {
        code: brands.code,
        name: brands.name,
        modifiedon: new Date(brands.createdon),
      });
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your brand Added Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        yield put(getbrandlist());
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to add brand. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      yield put(brandAddSuccessful(null));
    }
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });
    //yield put(apiError(error));
  }
}

//If user is login then dispatch redux action's are directly from here.
function* Deletebrand(BrnId) {
  try {
    const response = yield call(
      postDelete,
      "/masters/brands/" + BrnId.payload.BrnId
    );
    if (response.status === 204) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your brand Added Successfully.!",
        showConfirmButton: false,
        timer: 1500,
      });
      yield put(getbrandlist());
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Sorry! Unable to add brand. Please try agine!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    // yield put(locationDeleteSuccessful(response.statusText))
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });

    yield put(apiError(error));
  }
}

export function* watchgetbrandslist() {
  yield takeEvery(BRAND, getbrand);
}
export function* watchbrandsAdd() {
  yield takeEvery(BRAND_ADD, Addbrand);
}

export function* watchbrandsDelete() {
  yield takeEvery(BRAND_DELETE, Deletebrand);
}
export function* watchSetEditData() {
  yield takeEvery(MODEL_OPEN, OnSetEditData);
}
function* BrandSaga() {
  yield all([
    fork(watchgetbrandslist),
    fork(watchbrandsAdd),
    fork(watchbrandsDelete),
    fork(watchSetEditData),
  ]);
}

export default BrandSaga;
