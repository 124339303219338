import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Progress,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

//Dropzone
import Dropzone from "react-dropzone";
//
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//select
import Select from "react-select";

//
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import "./ImportData.scss";
import {
  getMastersMenu,
  // add_menu,
  // delete_menu,
  // put_menu
} from "../../../store/Masters/MastersMenu/actions";

class ImportData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      importFile_id: 0,
      importFile_name: "",
      selectedFiles: [],
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);

    this.handleFileSubmit = this.handleFileSubmit.bind(this);
  }
  componentDidMount() {
    this.props.getMastersMenu();
    // console.log(" this.props.getMastersMenu()", this.props.getMastersMenu())
  }

  handleAcceptedFiles = (files) => {
    var reader = new FileReader();
    reader.onload = function(e) {
        // Use reader.result
        alert(reader.result)
    }
    reader.readAsText(files[0]);
    // const reader = new FileReader();
    // reader.onload = () => resolve(reader.result);
    // // reader.onerror = (error) => reject(error);
    // reader.readAsDataURL(file);
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }
  //   onChangeHandler = event => {
  //     this.setState({
  //         selectedFile: event.target.files[0],
  //         loaded: 0,
  //     })
  //     console.log(event.target.files[0])

  // }
  handleDownloadDoc = (fileName) => (e) => {
    if (this.state.importFile_name == "") {
      alert("select upload file");
    } else {
      switch (fileName) {
        case "Version Types":
          let link = document.createElement("a");
          link.download = `${fileName}.csv`;
          let blob = new Blob(["code,name,brand,version,vehicletype"], {
            type: "csv",
          });
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
          break;

        default:
          alert("CSV File Not found");
          break;
      }
    }

    // Opens in new window
    // let blob = new Blob(["Hello, world!"], { type: "text/plain" });
    // const fileURL = URL.createObjectURL(blob);
    // window.open(fileURL);
  };
  handleFileSubmit() {
    const data = new FormData()
    data.append('file', this.state.selectedFiles)
    console.log("File ::", this.state.selectedFiles, data);
    this.props.UploadCSVFile(data, this.props.history);
  }
  render() {
    return (
      <React.Fragment>
        <Row className="no-gutters">
          <Col md={1}>
            <AdminSidebar />
          </Col>
          <Col md={11} className="pl-5">
            <div className="page-content">
              <Container fluid>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardHeader className="bg-transparent border-bottom ">
                        <h3 className="card-title-18 pt-2">Import data</h3>
                      </CardHeader>
                      <CardBody>
                        <div
                          //   id="addproduct-nav-pills-wizard"
                          className="import-data-tab"
                        >
                          <Nav className="import-data-tab-nav">
                            <NavItem>
                              <NavLink
                                onChange={() => {
                                  this.toggleTab(1);
                                }}
                                className={classnames({
                                  active: this.state.activeTab === 1,
                                })}
                              >
                                <span className="step-number">Step 1: </span>
                                <span className="step-title">Upload file</span>
                                <i className="mdi mdi-arrow-right-drop-circle-outline ml-2"></i>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                onChange={() => {
                                  this.toggleTab(2);
                                }}
                                className={classnames({
                                  active: this.state.activeTab === 2,
                                })}
                              >
                                <span className="step-number">Step 2: </span>
                                <span className="step-title">Mapping</span>
                                <i className="mdi mdi-arrow-right-drop-circle-outline ml-2"></i>
                              </NavLink>
                            </NavItem>

                            <NavItem>
                              <NavLink
                                onChange={() => {
                                  this.toggleTab(3);
                                }}
                                className={classnames({
                                  active: this.state.activeTab === 3,
                                })}
                              >
                                <span className="step-number">Step 3: </span>

                                <span className="step-title">
                                  Preview and finish
                                </span>
                                <i className="mdi mdi-arrow-right-drop-circle-outline ml-2"></i>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent
                            activeTab={this.state.activeTab}
                            className="import-data-tab-content"
                          >
                            <TabPane tabId={1}>
                              <h4 className="card-title-18 mb-4">
                                Before uploading your file
                              </h4>
                              {/* <p className="card-title-desc">
                            Fill all information below
                          </p> */}
                              <Row>
                                <Col md={6}>
                                  <Form>
                                    <FormGroup className="select2-container">
                                      <Label>Select Upload</Label>
                                      <Select
                                        options={
                                          this.props.mastersMenuList
                                            .menuitems &&
                                          this.props.mastersMenuList.menuitems.map(
                                            (item, index) => ({
                                              label: item.title,
                                              value: item.id,
                                            })
                                          )
                                        }
                                        value={{
                                          label: this.state.importFile_name,
                                          value: this.state.importFile_id,
                                        }}
                                        onChange={(e) => {
                                          this.setState({
                                            importFile_name: e.label,
                                            importFile_id: e.value,
                                          });
                                          // this.setBrandValues(e.label, e.value);
                                        }}
                                        name="importFile-selection"
                                        classNamePrefix="importFile-selection"
                                        id="importFile-selection"
                                        errorMessage=" Please provide a valid Selection"
                                        validate={{ required: { value: true } }}
                                      />
                                    </FormGroup>
                                    <ul className="list-unstyled m-2 font-size-16">
                                      <li>
                                        <i className="mdi mdi-circle-medium mr-1 align-middle"></i>{" "}
                                        Reaching the targets and goals set for
                                        my area.
                                      </li>
                                      <li>
                                        <i className="mdi mdi-circle-medium mr-1 align-middle"></i>{" "}
                                        Servicing the needs of my existing
                                        customers.
                                      </li>
                                      <li>
                                        <i className="mdi mdi-circle-medium mr-1 align-middle"></i>{" "}
                                        Maintaining the relationships with
                                        existing customers for repeat business.
                                      </li>
                                      <li>
                                        <i className="mdi mdi-circle-medium mr-1 align-middle"></i>{" "}
                                        Reporting to top managers.
                                      </li>
                                      <li>
                                        <i className="mdi mdi-circle-medium mr-1 align-middle"></i>{" "}
                                        Keeping up to date with the products.
                                      </li>
                                    </ul>

                                    <div className="download-link pt-4 m-2">
                                      {/* <Link
                                    
                                      className="btn btn-md btn-link font-size-16 btn-inline-block  "
                                    >
                                      <i className="mdi mdi-arrow-right-circle mr-1"></i>
                                      Download .xlsx sample file
                                    </Link> */}
                                      <Link
                                        to="#"
                                        onClick={this.handleDownloadDoc(
                                          this.state.importFile_name
                                        )}
                                        className="btn btn-md btn-link font-size-16 btn-inline-block"
                                      >
                                        <i className="mdi mdi-arrow-right-circle mr-1"></i>
                                        Download .csv sample file
                                      </Link>
                                    </div>
                                  </Form>
                                </Col>
                                <Col md={6}>
                                  <Form>
                                    <Dropzone
                                      onDrop={(acceptedFiles) =>
                                        this.handleAcceptedFiles(acceptedFiles)
                                      }

                                      //  disabled={flase}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick mt-2"
                                            {...getRootProps()}
                                          >
                                            <input
                                              {...getInputProps()}
                                              accept=".csv"
                                              name="file"
                                            />
                                            {/* onChange={this.onChangeHandler} */}

                                            <div className="mb-3">
                                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                            </div>
                                            <h4>
                                              Drop files here or click to
                                              upload.
                                            </h4>
                                            <p className="card-title-desc font-size-14">
                                              Only Excel (.xls and .xlsx) and
                                              .csv file types are supported.
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    <div
                                      className="dropzone-previews mt-3"
                                      id="file-previews"
                                    >
                                      {this.state.selectedFiles.map((f, i) => {
                                        return (
                                          <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                          >
                                            <div className="p-2">
                                              <Row className="align-items-center">
                                                <Col md={12}>
                                                  <div className="mb-2">
                                                    <Progress
                                                      color="primary"
                                                      value={50}
                                                      style={{ height: "24px" }}
                                                    >
                                                      Uploading
                                                    </Progress>
                                                  </div>
                                                </Col>
                                                <Col>
                                                  <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                  >
                                                    {f.name}
                                                  </Link>
                                                  <p className="mb-0">
                                                    <strong>
                                                      {f.formattedSize}
                                                    </strong>
                                                  </p>
                                                </Col>
                                              </Row>
                                              <Button
                                                color="primary"
                                                className="w-100"
                                                onClick={this.handleFileSubmit(
                                                  f.file
                                                )}
                                              >
                                                Import File
                                              </Button>
                                            </div>
                                          </Card>
                                        );
                                      })}
                                    </div>
                                  </Form>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                              <h4 className="card-title-18 mb-4">Mapping</h4>
                              <Row>
                                <Col md={8}></Col>
                                <Col md={4}></Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={3}>
                              <h4 className="card-title-18 mb-4">
                                Preview import data
                              </h4>

                              <Form>
                                <Row>
                                  <Col sm={6}>
                                    <FormGroup>
                                      <Label htmlFor="metatitle">
                                        Meta title
                                      </Label>
                                      <Input
                                        id="metatitle"
                                        name="metatitle"
                                        type="text"
                                        className="form-control"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col sm={6}>
                                    <FormGroup>
                                      <Label htmlFor="metakeywords">
                                        Meta Keywords
                                      </Label>
                                      <Input
                                        id="metakeywords"
                                        name="metakeywords"
                                        type="text"
                                        className="form-control"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <FormGroup>
                                  <Label htmlFor="metadescription">
                                    Meta Description
                                  </Label>
                                  <textarea
                                    className="form-control"
                                    id="metadescription"
                                    rows="5"
                                  ></textarea>
                                </FormGroup>
                              </Form>

                              <div className="text-center mt-4">
                                <Button
                                  color="primary"
                                  type="submit"
                                  className="mr-2 waves-effect waves-light"
                                >
                                  Save Changes
                                </Button>
                                <Button
                                  color="light"
                                  type="submit"
                                  className="waves-effect ml-1"
                                >
                                  Cancel
                                </Button>
                              </div>
                            </TabPane>
                          </TabContent>
                          <ul className="pager wizard import-data-tab-pager-link">
                            <li
                              className={
                                this.state.activeTab === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  this.toggleTab(this.state.activeTab - 1);
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={
                                this.state.activeTab === 3
                                  ? "next disabled"
                                  : "next"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  this.toggleTab(this.state.activeTab + 1);
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  const { mastersMenuList } = state.MastersMenu;
  return { mastersMenuList };
};
export default connect(mapStatetoProps, {
  getMastersMenu,
  // model_open,
  // model_close,
  // userAdd,
  // // getMasterLocations,
  // userDelete,
})(ImportData);
