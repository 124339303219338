import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  // Card,
  // CardBody,
  Modal,
  // Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
  Alert,
  FormGroup,
  Label,
} from "reactstrap";
// import Select from "react-select";

//Import Breadcrumb

import "./datatables.scss";

//import BootstrapTable from 'react-bootstrap-table-next';
//import ToolkitProvider from 'react-bootstrap-table2-toolkit';


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertOpen: false,

   
    };
    this.tog_static = this.tog_static.bind(this);
  }
  tog_static() {
    this.setState((prevState) => ({
      modal_static: !prevState.modal_static,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }


  render() {
   
    const data = {
      columns: [
        {
          label: "S.No",
          field: "SNo",
          sort: "asc",
          width: 50,
        },
        {
          label: "Profile",
          field: "Profile",
          sort: "asc",
          width: 270,
        },

        {
          label: "Settings",
          field: "Settings",
          sort: "asc",
          width: 150,
        },
      ],
      rows: [
        {
          SNo: "01",
          Profile: "Admin",
          Settings: (
            <>
              <Link to="#" className="mr-3 text-primary" id="edit2">
                <i className="mdi mdi-pencil font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="edit2">
                Edit
              </UncontrolledTooltip>
              <Link to="#" className="text-danger" id="delete2">
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="delete2">
                Delete
              </UncontrolledTooltip>
            </>
          ),
        },
        {
          SNo: "01",
        
          Profile: "Manager",
          
          Settings: (
            <>
              <Link to="#" className="mr-3 text-primary" id="edit2">
                <i className="mdi mdi-pencil font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="edit2">
                Edit
              </UncontrolledTooltip>
              <Link to="#" className="text-danger" id="delete2">
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="delete2">
                Delete
              </UncontrolledTooltip>
            </>
          ),
        },
      ],
    };

    return (
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={6} xl={6}>
              <div className="text-left">
                <h5 className="card-title-18">Profile information</h5>
              </div>
            </Col>
            <Col xs={6} xl={6}>
              <div className="text-right">
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                //   onClick={this.tog_static}
                  href="/AddProfile"
                >
                  Add Profile
                </Button>
              </div>
            </Col>
          </Row>

          <Modal
            isOpen={this.state.modal_static}
            toggle={this.tog_static}
            backdrop="static"
            centered
            size="ls"
          >
            <ModalHeader toggle={() => this.setState({ modal_static: false })}>
             Add Profile
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={this.addCustomer}>
                <Row>
                  <Col lg={12}>
                    <Alert
                      color="success"
                      isOpen={this.state.isAlertOpen}
                      toggle={() => this.setState({ isAlertOpen: false })}
                    >
                      Profile Added Successfully...!
                    </Alert>
                    <FormGroup>
                      <Label htmlFor="profilename">Profile Name</Label>
                      <AvField
                        name="profilename"
                        type="text"
                        className="form-control"
                        id="profilename"
                        placeholder="Enter Profile Name"
                        required
                      />
                    </FormGroup>
                  </Col>
                 
                </Row>

                
                <ModalFooter>
                  <Button
                    type="button"
                    color="light"
                    onClick={() => this.setState({ modal_static: false })}
                  >
                    Calcel
                  </Button>
                  <Button type="submit" color="primary">
                  Add Profile
                  </Button>
                </ModalFooter>
              </AvForm>
            </ModalBody>
          </Modal>

          <MDBDataTable bordered striped data={data} />
        </Col>
      </Row>
    );
  }
}

export default Profile;
