import React, { Component } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";
import {
  // hideRightSidebar,
  // changeLayout,
  // changeLayoutWidth,
  // changeSidebarTheme,
  changeSidebarType,
  // changePreloader,
  // changeTopbarTheme,
} from "../../../store/actions";

import "./AdminSidebar.scss";

class AdminSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.changeSidebarType = this.changeSidebarType.bind(this);
  }

  componentDidMount() {
    this.initMenu();

    this.props.changeSidebarType("condensed", this.state.isMobile);
  }
  componentWillUnmount() {
    this.props.changeSidebarType("default", this.state.isMobile);
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#admin-side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("admin-side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("adminactive");

    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-adminactive");
      // const parent2 = parent.parentElement;
      return false;
    }

    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="menu-position">
          <div data-simplebar className="vertical-admin-menu h-100">
            <SimpleBar style={{ maxHeight: "100%" }}>
              <div className="admin-sidebar-menu">
                <ul className="metismenu list-unstyled" id="admin-side-menu">
                  <li>
                    <Link to="/Getcompany" className="waves-effect">
                      <i className="ri-settings-3-line"></i>
                      <span className="ml-1">Company Setup</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/Users" className=" waves-effect">
                      <i className="ri-group-fill"></i>
                      <span className="ml-1">Users & Profile</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/EmailSMS" className=" waves-effect">
                      <i className="ri-mail-lock-line"></i>
                      <span className="ml-1">Email & SMS</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Importdata" className=" waves-effect">
                      <i className="ri-upload-cloud-line"></i>
                      <span className="ml-1">Import data</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/yourdevices" className=" waves-effect">
                      <i className="ri-device-line"></i>
                      <span className="ml-1">Your devices</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/SecurityDashboard" className=" waves-effect">
                      <i className="ri-spy-line"></i>
                      <span className="ml-1">Security Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className=" waves-effect">
                      <i className="ri-base-station-fill"></i>
                      <span className="ml-1">Others</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </SimpleBar>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  return { ...state.Layout };
};
export default withRouter(
  connect(mapStatetoProps, {
    // hideRightSidebar,
    // changeLayout,
    // changeSidebarTheme,
    changeSidebarType,
    // changeLayoutWidth,
    // changeTopbarTheme,
    // changePreloader
  })(AdminSidebar)
);
// export default withRouter(withNamespaces()(AdminSidebar));
