import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";

import { Link } from "react-router-dom";

import { AvForm } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  // Card,
  // CardBody,
  Modal,
  // Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
  Alert,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import swal from "sweetalert";

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
// import Input from "react-select/src/components/input";

//import BootstrapTable from 'react-bootstrap-table-next';
//import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {
  getUsers,
  // model_open,
  // model_close,
  // userAdd,
  // userDelete,
} from "../../../store/Users/actions";
const optionGroupRole = [
  {
    options: [
      { label: "Admin", value: "Admin" },
      { label: "Manager", value: "Manager" },
    ],
  },
];
const optionGroupZone = [
  {
    options: [
      { label: "Developer", value: "Developer" },
      { label: "Suport", value: "Suport" },
    ],
  },
];
const optionGroupGender = [
  {
    options: [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
    ],
  },
];

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertOpen: false,
      selectedGroupRole: null,
      selectedGroupZone: null,
      selectedGroupGender: null,
    };
    this.tog_static = this.tog_static.bind(this);
  }
  tog_static() {
    this.setState((prevState) => ({
      modal_static: !prevState.modal_static,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  componentDidMount() {
    this.props.getUsers();
    console.log(this.props.getUsers());
    // this.props.getMasterLocations();
  }

  handleSelectGroupRole = (selectedGroupRole) => {
    this.setState({ selectedGroupRole });
  };
  handleSelectGroupZone = (selectedGroupZone) => {
    this.setState({ selectedGroupZone });
  };
  handleSelectGroupGender = (selectedGroupGender) => {
    this.setState({ selectedGroupGender });
  };

  // handleValidSubmit(event, values) {
  //   this.props.userAdd(values, this.props.history);
  // }
  // handleDelete(values) {
  //   this.props.userDelete(values, this.props.history);
  // }

  // editRecord = (record) => {
  //   console.log("Edit record", record);
  //   this.props.model_open();
  //   this.setState({
  //     id: record.id,
  //     username: record.username,
  //     first_name: record.first_name,
  //     last_name: record.last_name,
  //     email: record.email,
  //     mobile: record.mobile,
  //     location_id: record.location.id,
  //     location_name: record.location.name,
  //     pass_disabled: true,
  //     passwordtext: "Absolin$$",
  //   });
  // };

  // deleteRecord = (record) => {
  //   console.log("Delete record", record.id);
  //   swal({
  //     title: "Are you sure?",
  //     text:
  //       "Once deleted, you will not be able to recover this imaginary file!",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       this.handleDelete(record.id);
  //     } else {
  //       swal("Your imaginary file is safe!");
  //     }
  //   });
  // };

  render() {
    const { selectedGroupZone } = this.state;
    const { selectedGroupGender } = this.state;

    const { selectedGroupRole } = this.state;

    var listdata = this.props.userRowList.map((row, order) => ({
      ...row,
      action: (
        <Fragment>
          <i
            className="fa fa-edit"
            title="Edit User"
            style={{ cursor: "pointer", color: "#fc6f03" }}
            // onClick={() => this.editRecord(row)}
          ></i>
          <i
            className="fa fa-trash ml-3"
            title="Detele User"
            style={{ cursor: "pointer", color: "#ff0000" }}
            // onClick={() => this.deleteRecord(row)}
          ></i>
        </Fragment>
      ),
    }));
    const data = {
      columns: [
        {
          label: "S.No",
          field: "id",
          // sort: "disabled",
          sort: "sc",
          width: 50,
        },
        {
          label: "User Name",
          field: "username",
          sort: "asc",
          width: 250,
        },
        {
          label: "Name",
          field: "first_name",
          sort: "asc",
          width: 250,
        },

        {
          label: "Profile",
          field: "Profile",
          sort: "asc",
          width: 100,
        },
        {
          label: "Email ID",
          field: "email",
          sort: "asc",
          width: 100,
        },
        {
          label: "Status",
          field: "is_active",
          sort: "asc",
          width: 100,

        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150,
        },
      ],
      rows: listdata,
    };

    return (
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={6} xl={6}>
              <div className="text-left">
                <h5 className="card-title-15">Users information</h5>
              </div>
            </Col>
            <Col xs={6} xl={6}>
              <div className="text-right">
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={this.tog_static}
                >
                  Add User
                </Button>
              </div>
            </Col>
          </Row>

          <MDBDataTableV5
            hover
            striped
            responsive
            bordered
            paging={true}
            data={data}
            searchTop
            searchBottom={false}
          />

          <Modal
            isOpen={this.state.modal_static}
            toggle={this.tog_static}
            backdrop="static"
            centered
            size="lg"
          >
            <ModalHeader toggle={() => this.setState({ modal_static: false })}>
              Add User
            </ModalHeader>
            <ModalBody>
              <AvForm onValidSubmit={this.addCustomer}>
                <Row className="justify-content-center">
                  <Col md={10}>
                    <Alert
                      color="success"
                      isOpen={this.state.isAlertOpen}
                      toggle={() => this.setState({ isAlertOpen: false })}
                    >
                      User Added Successfully...!
                    </Alert>
                    <Row className="justify-content-center">
                      <Col md={9}>
                        <FormGroup row>
                          <Col md={3}>
                            <Label
                              htmlFor="Name"
                              className="col-form-label float-right"
                            >
                              Name
                            </Label>
                          </Col>
                          <Col md={9}>
                            <Input
                              name="Name"
                              type="text"
                              className="form-control"
                              id="Name"
                              placeholder="Enter Name"
                              errorMessage=" Please provide a valid Name"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col md={3}>
                            <Label className="Gender-selection col-form-label float-right">
                              Select Gender
                            </Label>
                          </Col>
                          <Col md={9}>
                            <Select
                              value={selectedGroupGender}
                              onChange={this.handleSelectGroupGender}
                              options={optionGroupGender}
                              classNamePrefix="Gender-selection"
                              errorMessage=" Please provide a valid Gender"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col md={3}>
                            <Label
                              htmlFor="Username"
                              className="col-form-label float-right"
                            >
                              Username
                            </Label>
                          </Col>
                          <Col md={9}>
                            <Input
                              name="Username"
                              type="text"
                              className="form-control"
                              id="Username"
                              placeholder="Enter Username"
                              errorMessage=" Please provide a valid Username"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col md={3}>
                            <Label
                              htmlFor="Mobno"
                              className="col-form-label float-right"
                            >
                              Mobile No
                            </Label>
                          </Col>
                          <Col md={9}>
                            <Input
                              name="Mobno"
                              mask="(999) 999-9999"
                              type="tel"
                              className="form-control"
                              id="Mobno"
                              placeholder="Enter Mobile No"
                              errorMessage=" Please provide a valid Mobile No"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col md={3}>
                            <Label
                              htmlFor="Email"
                              className="col-form-label float-right"
                            >
                              Email
                            </Label>
                          </Col>
                          <Col md={9}>
                            <Input
                              name="Email"
                              type="email"
                              className="form-control"
                              id="Email"
                              placeholder="Enter Email"
                              errorMessage=" Please provide a valid Email"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col md={3}>
                            <Label className="Role-selection col-form-label float-right">
                              Select Role
                            </Label>
                          </Col>
                          <Col md={9}>
                            <Select
                              value={selectedGroupRole}
                              name="Role-selection"
                              onChange={this.handleSelectGroupRole}
                              options={optionGroupRole}
                              classNamePrefix="Role-selection"
                              id="Role-selection"
                              errorMessage=" Please provide a valid Role"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col md={3}>
                            <Label className="Zone-selection col-form-label float-right">
                              Select Zone
                            </Label>
                          </Col>
                          <Col md={9}>
                            <Select
                              value={selectedGroupZone}
                              name="Role-selection"
                              onChange={this.handleSelectGroupZone}
                              options={optionGroupZone}
                              classNamePrefix="Zone-selection"
                              id="Zone-selection"
                              errorMessage=" Please provide a valid Zone"
                              validate={{ required: { value: true } }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4}></Col>
                </Row>

                <ModalFooter>
                  <Button
                    type="button"
                    color="light"
                    onClick={() => this.setState({ modal_static: false })}
                  >
                    Calcel
                  </Button>
                  <Button type="submit" color="primary">
                    Add User
                  </Button>
                </ModalFooter>
              </AvForm>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    );
  }
}
const mapStatetoProps = (state) => {
  const { userRowList } = state.Users;
  // const { locationslist } = state.Product;
  return { userRowList };
};
export default connect(mapStatetoProps, {
  getUsers,
  // model_open,
  // model_close,
  // userAdd,
  // // getMasterLocations,
  // userDelete,
})(Users);
