import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  VEHICLE_DETAILS,
  ROW_VEHICLE_DETAILS,
  VEHICLE_DETAILS_ADD,
  VEHICLE_DETAILS_DELETE,
  MODEL_OPEN,
} from "./actionTypes";
import {
  apiError,
  getVehicleDetailsList,
  VehicleDetailsListSuccessful,
  RowVehicleDetailsListSuccessful,
  VehicleDetailsAddSuccessful,
  // SetEditData,
} from "./actions";

// AUTH related methods
import {
  postAdd,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";

import Swal from "sweetalert2";

function* getVehicleDetails() {
  try {
    const data = yield call(getList, "/masters/vehicledetails/", {});
    yield put(VehicleDetailsListSuccessful({ data }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getRowVehicleDetails({ payload: { VehicleDetailsID } }) {
  try {
    // if(VehicleDetails.id !== 0)
    const data = yield call(
      getList,
      "/masters/vehicledetails/" + VehicleDetailsID,
      {}
    );
    console.log("SagagetRowVehicleDetails", data);
    yield put(
      RowVehicleDetailsListSuccessful({
        data: {
          ...data,
          brand: data.brand ? data.brand.id : 0,
          brandLabel: data.brand ? data.brand.name : "",
          version: data.version ? data.version.id : 0,
          versionlabel: data.version ? data.version.name : "",
          vehicletype: data.vehicletype ? data.vehicletype.id : 0,
          vehicletypelabel: data.vehicletype ? data.vehicletype.name : "",
          modelyear: data.modelyear ? data.modelyear.id : 0,
          modelyearlabel: data.modelyear ? data.modelyear.name : "",
          versiontype: data.versiontype ? data.versiontype.id : 0,
          versiontypeslabel: data.versiontype ? data.versiontype.name : "",
        },
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}
// function* OnSetEditData() {
//   var today = new Date(),
//     code =
//       "MY" +
//       today.getFullYear() +
//       (today.getMonth() + 1) +
//       today.getDate() +
//       today.getHours() +
//       today.getMinutes() +
//       today.getSeconds();
//   const createdon =
//     today.getFullYear() +
//     "-" +
//     (today.getMonth() + 1) +
//     "-" +
//     today.getDate() +
//     " " +
//     today.getHours() +
//     ":" +
//     today.getMinutes() +
//     ":" +
//     today.getSeconds();

//   yield put(SetEditData({ id: 0, code, createdon }));
// }
//If user is login then dispatch redux action's are directly from here.
function* AddVehicleDetails({ payload: { VehicleDetails, VDId, history } }) {
  try {
    if (VDId == 0) {
      const response = yield call(
        postAdd,
        "/masters/vehicledetails/",
        VehicleDetails
      );

      if (response.status === 201) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your Vehicle Type Added Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        yield put(getVehicleDetailsList());
        history.push("/VehicleDetails");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to add Vehicle Type. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      yield put(VehicleDetailsAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/vehicledetails/" + VDId,
        VehicleDetails
      );
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your Vehicle Type Edited Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        yield put(getVehicleDetailsList());
        history.push("/VehicleDetails");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to edit Vehicle Type. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      yield put(VehicleDetailsAddSuccessful(null));
    }
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });
    //yield put(apiError(error));
  }
}

//If user is login then dispatch redux action's are directly from here.
function* DeleteVehicleDetails(MyId) {
  try {
    const response = yield call(
      postDelete,
      "/masters/vehicledetails/" + MyId.payload.MyId
    );
    if (response.status === 204) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Poof! Your Vehicle Type has been deleted!",
        showConfirmButton: false,
        timer: 1500,
      });

      yield put(getVehicleDetailsList());
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Sorry! Your Vehicle Type has been not deleted!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    // yield put(locationDeleteSuccessful(response.statusText))
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });
    yield put(apiError(error));
  }
}

export function* watchgetVehicleDetailslist() {
  yield takeEvery(VEHICLE_DETAILS, getVehicleDetails);
}
export function* watchgetRowVehicleDetailslist() {
  yield takeEvery(ROW_VEHICLE_DETAILS, getRowVehicleDetails);
}
export function* watchVehicleDetailsAdd() {
  yield takeEvery(VEHICLE_DETAILS_ADD, AddVehicleDetails);
}

export function* watchVehicleDetailsDelete() {
  yield takeEvery(VEHICLE_DETAILS_DELETE, DeleteVehicleDetails);
}
// export function* watchSetEditData() {
//   yield takeEvery(MODEL_OPEN, OnSetEditData);
// }
function* VehicleDetailsSaga() {
  yield all([
    fork(watchgetVehicleDetailslist),
    fork(watchgetRowVehicleDetailslist),
    fork(watchVehicleDetailsAdd),
    fork(watchVehicleDetailsDelete),
    // fork(watchSetEditData),
  ]);
}

export default VehicleDetailsSaga;
