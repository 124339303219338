import {
  API_ERROR,
  LOCATIONS,
  LOCATION_LIST_SUCCESSFUL,
  LOCATION_ADD,
  LOCATION_ADD_SUCCESSFUL,
  LOCATION_EDIT,
  LOCATION_EDIT_SUCCESSFUL,
  LOCATION_DELETE,
  LOCATION_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

export const getLocationslist = () => {
  return {
    type: LOCATIONS,
  };
};

export const locationListSuccessful = (locations) => {
  return {
    type: LOCATION_LIST_SUCCESSFUL,
    payload: locations,
  };
};

export const locationAdd = (location, history) => {
  return {
    type: LOCATION_ADD,
    payload: { location, history },
  };
};

export const locationAddSuccessful = (response) => {
  return {
    type: LOCATION_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const locationEdit = (data) => {
  return {
    type: LOCATION_EDIT,
    payload: data,
  };
};

export const locationEditSuccessful = (response) => {
  return {
    type: LOCATION_EDIT_SUCCESSFUL,
    payload: response,
  };
};

export const locationDelete = (locId, history) => {
  return {
    type: LOCATION_DELETE,
    payload: { locId, history },
  };
};

export const SetEditData = (data) => {
  return {
    type: LOCATION_SETROWDATA,
    payload: data,
  };
};

export const model_open = () => {
  return {
    type: MODEL_OPEN,
  };
};

export const model_close = () => {
  return {
    type: MODEL_CLOSE,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
