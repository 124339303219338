import React, { Component } from "react";
import {
  // TabContent,
  // TabPane,
  Collapse,
  // NavLink,
  // NavItem,
  // CardText,
  // Nav,
  // Input,
  Button,
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Switch from "react-switch";

import { Link } from "react-router-dom";
// import classnames from "classnames";
// import Breadcrumbs from "../../../components/Common/Breadcrumb";

import AdminSidebar from "../../AdminSidebar/AdminSidebar";

import "./AddProfile.scss";

// import CompanyDetails from "./CompanyDetails";
// import Users from "./Users";
// import Profile from "./Profile";

class AddProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      col: [
        {
          id: 1,
        },
      ],
      // col1: true,
      // col2: false,
      // col3: false,
      arr: [
        {
          id: 1,
          title: "Company",
          titlecol: false,
          linkcol: false,
          commonFalse: false,
          checkedSee: false,
          seeDisabled: true,
          checkedCreate: false,
          createDisabled: true,
          checkedModify: false,
          modifyDisabled: true,
          checkedDelete: false,
          deleteDisabled: true,
          // linkDisabled:true,
          checkedtext: false,
        },
        {
          id: 3,
          title: "Company",
          titlecol: false,
          linkcol: false,
          commonFalse: false,
          checkedSee: false,
          seeDisabled: true,
          checkedCreate: false,
          createDisabled: true,
          checkedModify: false,
          modifyDisabled: true,
          checkedDelete: false,
          deleteDisabled: true,
          // linkDisabled:true,
          checkedtext: false,
        },
        {
          id: 2,
          title: "Contact",
          titlecol: false,
          linkcol: false,
          commonFalse: false,
          checkedSee: false,
          seeDisabled: true,
          checkedCreate: false,
          createDisabled: true,
          checkedModify: false,
          modifyDisabled: true,
          checkedDelete: false,
          deleteDisabled: true,
          // linkDisabled:true,
          checkedtext: false,
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSee = this.handleChangeSee.bind(this);
    this.handleChangeCreate = this.handleChangeCreate.bind(this);
    this.handleChangeModify = this.handleChangeModify.bind(this);
    this.handleChangeDelete = this.handleChangeDelete.bind(this);

    this.t_col1 = this.t_col1.bind(this);
  }
  t_col1(item) {
    var arr = this.state.arr.map((i, idx) => {
      if (i.id === item.id) {
        if (i.titlecol === true) {
          i.titlecol = !i.titlecol;
        } else {
          i.titlecol = !i.titlecol;
        }
      } else {
        i.titlecol = false;
      }

      return i;
    });
    this.setState({ arr: arr });
  }
  handleChange(item) {
    var arr = this.state.arr.map((i, idx) => {
      if (i.id === item.id) {
        if (i.commonFalse === true) {
          i.commonFalse = false;
          i.titlecol = false;
          i.linkcol = false;
          i.checkedSee = false;
          i.seeDisabled = true;
          i.checkedCreate = false;
          i.createDisabled = true;
          i.checkedModify = false;
          i.modifyDisabled = true;
          i.checkedDelete = false;
          i.deleteDisabled = true;
          // i.linkDisabled = true;
        } else {
          i.commonFalse = true;
          // i.titlecol=true;
          i.linkcol = true;
          i.seeDisabled = false;
          i.createDisabled = false;
          i.modifyDisabled = false;
          i.deleteDisabled = false;
        }
      }
      return i;
    });
    this.setState({ arr: arr });

    // this.setState({
    //   checked: !this.state.checked,
    //   checkedSee: false,
    //   checkedCreate: false,
    //   checkedModify: false,
    //   checkedDelete: false,
    // });
  }
  handleChangeSee(item) {
    var arr = this.state.arr.map((i, idx) => {
      if (i.id === item.id) {
        if (i.commonFalse === false) {
          i.commonFalse = false;
          i.checkedSee = false;
          i.seeDisabled = true;
          i.checkedCreate = false;
          i.createDisabled = true;
          i.checkedModify = false;
          i.modifyDisabled = true;
          i.checkedDelete = false;
          i.deleteDisabled = true;
          i.linkDisabled = true;
        } else {
          i.commonFalse = true;
          i.checkedSee = !i.checkedSee;

          // i.checkedCreate = true;
          // i.checkedModify = true;
          // i.checkedDelete = true;
        }
      }

      return i;
    });
    this.setState({ arr: arr });
    // this.setState({ checkedSee });
  }
  handleChangeCreate(item) {
    var arr = this.state.arr.map((i, idx) => {
      if (i.id === item.id) {
        if (i.commonFalse === false) {
          i.commonFalse = false;
          i.checkedSee = false;
          i.seeDisabled = true;
          i.checkedCreate = false;
          i.createDisabled = true;
          i.checkedModify = false;
          i.modifyDisabled = true;
          i.checkedDelete = false;
          i.deleteDisabled = true;
          i.linkDisabled = true;
        } else {
          i.commonFalse = true;
          i.checkedCreate = !i.checkedCreate;
          // i.checkedCreate = true;
          // i.checkedModify = true;
          // i.checkedDelete = true;
        }
      }

      return i;
    });
    this.setState({ arr: arr });
    // this.setState({ checkedCreate });
  }
  handleChangeModify(item) {
    var arr = this.state.arr.map((i, idx) => {
      if (i.id === item.id) {
        if (i.commonFalse === false) {
          i.commonFalse = false;
          i.checkedSee = false;
          i.seeDisabled = true;
          i.checkedCreate = false;
          i.createDisabled = true;
          i.checkedModify = false;
          i.modifyDisabled = true;
          i.checkedDelete = false;
          i.deleteDisabled = true;
          i.linkDisabled = true;
        } else {
          i.commonFalse = true;
          i.checkedModify = !i.checkedModify;
          // i.checkedCreate = true;
          // i.checkedModify = true;
          // i.checkedDelete = true;
        }
      }
      return i;
    });
    this.setState({ arr: arr });
    // this.setState({ checkedModify });
  }
  handleChangeDelete(item) {
    var arr = this.state.arr.map((i, idx) => {
      if (i.id === item.id) {
        if (i.commonFalse === false) {
          i.commonFalse = false;
          i.checkedSee = false;
          i.seeDisabled = true;
          i.checkedCreate = false;
          i.createDisabled = true;
          i.checkedModify = false;
          i.modifyDisabled = true;
          i.checkedDelete = false;
          i.deleteDisabled = true;
          i.linkDisabled = true;
        } else {
          i.commonFalse = true;
          i.checkedDelete = !i.checkedDelete;
          // i.checkedCreate = true;
          // i.checkedModify = true;
          // i.checkedDelete = true;
        }
      }

      return i;
    });
    this.setState({ arr: arr });
    // this.setState({ checkedDelete });
  }

  render() {
    return (
      <React.Fragment>
        <Row className="no-gutters">
          <Col md={1}>
            <AdminSidebar />
          </Col>
          <Col md={11} className="pl-5">
            <div className="page-content">
              <Container fluid>
                {/* <Breadcrumbs
              title="Add Profile"
              breadcrumbItems={this.state.breadcrumbItems}
            /> */}

                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardHeader className="bg-transparent border-bottom">
                        <div className="float-right">
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-2"
                          >
                            Save
                          </Button>
                          <Button type="submit" color="light" href="/Users">
                            Cancel
                          </Button>
                        </div>
                        <h3 className="card-title-18 pt-2">Add Profile</h3>
                      </CardHeader>
                      <CardBody className="pt-2">
                        <AvForm>
                          <Row>
                            <Col md={12}>
                              <FormGroup row className="m-3 float-center">
                                <Label
                                  htmlFor="pName"
                                  className="col-md-3 col-form-label font-size-18"
                                >
                                  Profile Name:
                                </Label>
                                <AvField
                                  name="pName"
                                  type="text"
                                  className="form-control"
                                  id="Name"
                                  placeholder="Enter Name"
                                  errorMessage=" Please provide a valid Name"
                                  validate={{ required: { value: true } }}
                                />
                              </FormGroup>
                              {/* <hr className="my-3" /> */}
                            </Col>
                            <Col md={12}>
                              <h3 className="card-title-15 pt-2">
                                Profile Permission
                              </h3>
                              <hr className="my-1" />
                              <div id="accordion" className="mt-2">
                                {this.state.arr.map((item) => (
                                  <Card className="mb-1" key={item.id}>
                                    <CardHeader className="bg-transparent border-bottom">
                                      <div className="m-0 ">
                                        <Row>
                                          <Col md={3}>
                                            <i className="mdi mdi-account-box-outline mdi-18px mr-2"></i>
                                            <h5 className="d-inline-block">
                                              {item.title}
                                            </h5>
                                            <label className="d-inline-block float-right">
                                              <Switch
                                                onChange={() =>
                                                  this.handleChange(item)
                                                }
                                                checked={item.commonFalse}
                                                handleDiameter={10}
                                                height={20}
                                                width={42}
                                                className="react-switch"
                                              />
                                            </label>
                                          </Col>
                                          <Col md={7}>
                                            <label
                                              htmlFor="See-switch"
                                              className="font-size-16 ml-1"
                                            >
                                              <Switch
                                                checked={item.checkedSee}
                                                onChange={() =>
                                                  this.handleChangeSee(item)
                                                }
                                                disabled={item.seeDisabled}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={20}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={28}
                                                className="react-switch mr-2"
                                                id="material-switch"
                                              />
                                              <span>See</span>
                                            </label>
                                            <label
                                              htmlFor="Create-switch"
                                              className="font-size-16 ml-1"
                                            >
                                              <Switch
                                                checked={item.checkedCreate}
                                                onChange={() =>
                                                  this.handleChangeCreate(item)
                                                }
                                                disabled={item.createDisabled}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={20}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={28}
                                                className="react-switch mr-2"
                                                id="material-switch"
                                              />
                                              <span>Create</span>
                                            </label>
                                            <label
                                              htmlFor="Modify-switch"
                                              className="font-size-16 ml-1"
                                            >
                                              <Switch
                                                checked={item.checkedModify}
                                                onChange={() =>
                                                  this.handleChangeModify(item)
                                                }
                                                disabled={item.modifyDisabled}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={20}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={28}
                                                className="react-switch mr-2"
                                                id="material-switch"
                                              />
                                              <span>Modify</span>
                                            </label>
                                            <label
                                              htmlFor="Delete-switch"
                                              className="font-size-16 ml-1"
                                            >
                                              <Switch
                                                checked={item.checkedDelete}
                                                onChange={() =>
                                                  this.handleChangeDelete(item)
                                                }
                                                disabled={item.deleteDisabled}
                                                onColor="#86d3ff"
                                                onHandleColor="#2693e6"
                                                handleDiameter={20}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                                height={10}
                                                width={28}
                                                className="react-switch mr-2"
                                                id="material-switch"
                                              />
                                              <span>Delete</span>
                                            </label>
                                          </Col>
                                          <Col md={2}>
                                            <Link
                                              to="#"
                                              onClick={() => this.t_col1(item)}
                                              // checkedtext={this.checkedtext}
                                              className={
                                                item.linkcol
                                                  ? "enabled-link"
                                                  : "disabled-link"
                                              }
                                              // style={{ cursor: "pointer" }}
                                            >
                                              <p className="float-right mr-2">
                                                <span>
                                                  {item.titlecol
                                                    ? "Hide All"
                                                    : "View All"}
                                                </span>{" "}
                                                <i
                                                  className={
                                                    item.titlecol
                                                      ? "mdi mdi-minus accor-plus-icon ml-2"
                                                      : "mdi mdi-plus accor-plus-icon ml-2"
                                                  }
                                                ></i>
                                              </p>
                                            </Link>
                                          </Col>
                                        </Row>
                                      </div>
                                    </CardHeader>

                                    <Collapse isOpen={item.titlecol}>
                                      <CardBody>
                                        <h3 className="card-title-15 pt-2">
                                          Field Permission
                                        </h3>
                                        <Row>
                                          <Col md={6}>
                                            <div className="m-2">
                                              <Row>
                                                <Col md={4}>
                                                  <p className="d-inline-block">
                                                    Company Name :
                                                  </p>
                                                </Col>
                                                <Col md={8}>
                                                  <div className="float-left">
                                                    <label
                                                      htmlFor="label1"
                                                      className="font-size-14 ml-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        value="Male"
                                                        name="label1"
                                                        className="redio-switch"
                                                      />
                                                      <span className="redio-switch">
                                                        See & Modify
                                                      </span>
                                                    </label>
                                                    <label
                                                      htmlFor="label1"
                                                      className="font-size-14 ml-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        value="female"
                                                        name="label1"
                                                        className="redio-switch"
                                                      />
                                                      <span className="redio-switch">
                                                        Only See
                                                      </span>
                                                    </label>
                                                    <label
                                                      htmlFor="label1"
                                                      className="font-size-14 ml-1 "
                                                    >
                                                      <input
                                                        type="radio"
                                                        value="female"
                                                        name="label1"
                                                        className="redio-switch"
                                                      />
                                                      <span className="redio-switch">
                                                        Hidden
                                                      </span>
                                                    </label>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </Col>
                                          <Col md={6}>
                                            <div className="m-2">
                                              <Row className="justify-content-center">
                                                <Col md={4}>
                                                  <p className="font-size-16  d-inline-block">
                                                    Website :
                                                  </p>
                                                </Col>
                                                <Col md={8}>
                                                  <div className="float-left">
                                                    <label
                                                      htmlFor="WebsiteRadio"
                                                      className="font-size-14 ml-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        value="Male"
                                                        name="WebsiteRadio"
                                                        className="redio-switch"
                                                      />
                                                      <span className="redio-switch">
                                                        See & Modify
                                                      </span>
                                                    </label>
                                                    <label
                                                      htmlFor="WebsiteRadio"
                                                      className="font-size-14 ml-1"
                                                    >
                                                      <input
                                                        type="radio"
                                                        value="female"
                                                        name="WebsiteRadio"
                                                        className="redio-switch"
                                                      />
                                                      <span className="redio-switch">
                                                        Only See
                                                      </span>
                                                    </label>
                                                    <label
                                                      htmlFor="WebsiteRadio"
                                                      className="font-size-14 ml-1 "
                                                    >
                                                      <input
                                                        type="radio"
                                                        value="female"
                                                        name="WebsiteRadio"
                                                        className="redio-switch"
                                                      />
                                                      <span className="redio-switch">
                                                        Hidden
                                                      </span>
                                                    </label>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Collapse>
                                  </Card>
                                ))}
                                {/* <Card className="mt-2">
                              <CardHeader className="bg-transparent border-bottom">
                                <div className="m-0 ">
                                  <Row>
                                    <Col md={3}>
                                      <i className="mdi mdi-account-box-outline mdi-18px mr-2"></i>
                                      <h5 className="d-inline-block">
                                        Company
                                      </h5>
                                      <label className="d-inline-block float-right">
                                        <Switch
                                          onChange={this.handleChange}
                                          checked={this.state.checked}
                                          handleDiameter={10}
                                          height={20}
                                          width={42}
                                          className="react-switch"
                                        />
                                      </label>
                                    </Col>
                                    <Col md={7}>
                                      <label
                                        htmlFor="See-switch"
                                        className="font-size-16 ml-1"
                                      >
                                        <Switch
                                          checked={this.state.checkedSee}
                                          onChange={this.handleChangeSee}
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                          height={10}
                                          width={28}
                                          className="react-switch mr-2"
                                          id="material-switch"
                                        />
                                        <span>See</span>
                                      </label>
                                      <label
                                        htmlFor="Create-switch"
                                        className="font-size-16 ml-1"
                                      >
                                        <Switch
                                          checked={this.state.checkedCreate}
                                          onChange={this.handleChangeCreate}
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                          height={10}
                                          width={28}
                                          className="react-switch mr-2"
                                          id="material-switch"
                                        />
                                        <span>Create</span>
                                      </label>
                                      <label
                                        htmlFor="Modify-switch"
                                        className="font-size-16 ml-1"
                                      >
                                        <Switch
                                          checked={this.state.checkedModify}
                                          onChange={this.handleChangeModify}
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                          height={10}
                                          width={28}
                                          className="react-switch mr-2"
                                          id="material-switch"
                                        />
                                        <span>Modify</span>
                                      </label>
                                      <label
                                        htmlFor="Delete-switch"
                                        className="font-size-16 ml-1"
                                      >
                                        <Switch
                                          checked={this.state.checkedDelete}
                                          onChange={this.handleChangeDelete}
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={20}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                          height={10}
                                          width={28}
                                          className="react-switch mr-2"
                                          id="material-switch"
                                        />
                                        <span>Delete</span>
                                      </label>
                                    </Col>
                                    <Col md={2}>
                                      <Link
                                        to="#"
                                        onClick={this.t_col2}
                                        checkedtext={this.checkedtext}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <p className="float-right mr-2">
                                          <span>
                                            {this.state.checkedtext
                                              ? "View"
                                              : "Hide"}
                                          </span>{" "}
                                          All
                                          <i
                                            className={
                                              this.state.col1
                                                ? "mdi mdi-minus accor-plus-icon ml-2"
                                                : "mdi mdi-plus accor-plus-icon ml-2"
                                            }
                                          ></i>
                                        </p>
                                      </Link>
                                    </Col>
                                  </Row>
                                </div>
                              </CardHeader>

                              <Collapse isOpen={this.state.col2}>
                                <CardBody>
                                  <h3 className="card-title-15 pt-2">
                                    Field Permission
                                  </h3>
                                  <Row>
                                    <Col md={6}>
                                      <div className="m-2">
                                        <Row>
                                          <Col md={4}>
                                            <p className="d-inline-block">
                                              Company Name :
                                            </p>
                                          </Col>
                                          <Col md={8}>
                                            <div className="float-left">
                                              <label
                                                htmlFor="label1"
                                                className="font-size-14 ml-1"
                                              >
                                                <input
                                                  type="radio"
                                                  value="Male"
                                                  name="label1"
                                                  className="redio-switch"
                                                />
                                                <span className="redio-switch">
                                                  See & Modify
                                                </span>
                                              </label>
                                              <label
                                                htmlFor="label1"
                                                className="font-size-14 ml-1"
                                              >
                                                <input
                                                  type="radio"
                                                  value="female"
                                                  name="label1"
                                                  className="redio-switch"
                                                />
                                                <span className="redio-switch">
                                                  Only See
                                                </span>
                                              </label>
                                              <label
                                                htmlFor="label1"
                                                className="font-size-14 ml-1 "
                                              >
                                                <input
                                                  type="radio"
                                                  value="female"
                                                  name="label1"
                                                  className="redio-switch"
                                                />
                                                <span className="redio-switch">
                                                  Hidden
                                                </span>
                                              </label>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="m-2">
                                        <Row className="justify-content-center">
                                          <Col md={4}>
                                            <p className="font-size-16  d-inline-block">
                                              Website :
                                            </p>
                                          </Col>
                                          <Col md={8}>
                                            <div className="float-left">
                                              <label
                                                htmlFor="WebsiteRadio"
                                                className="font-size-14 ml-1"
                                              >
                                                <input
                                                  type="radio"
                                                  value="Male"
                                                  name="WebsiteRadio"
                                                  className="redio-switch"
                                                />
                                                <span className="redio-switch">
                                                  See & Modify
                                                </span>
                                              </label>
                                              <label
                                                htmlFor="WebsiteRadio"
                                                className="font-size-14 ml-1"
                                              >
                                                <input
                                                  type="radio"
                                                  value="female"
                                                  name="WebsiteRadio"
                                                  className="redio-switch"
                                                />
                                                <span className="redio-switch">
                                                  Only See
                                                </span>
                                              </label>
                                              <label
                                                htmlFor="WebsiteRadio"
                                                className="font-size-14 ml-1 "
                                              >
                                                <input
                                                  type="radio"
                                                  value="female"
                                                  name="WebsiteRadio"
                                                  className="redio-switch"
                                                />
                                                <span className="redio-switch">
                                                  Hidden
                                                </span>
                                              </label>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Collapse>
                            </Card>

                            <Card className="mb-0">
                              <Link
                                to="#"
                                onClick={this.t_col3}
                                style={{ cursor: "pointer" }}
                                className="text-dark"
                              >
                                <CardHeader id="headingThree">
                                  <h6 className="m-0 font-14">
                                    Collapsible Group Item #3
                                    <i
                                      className={
                                        this.state.col3
                                          ? "mdi mdi-minus float-right accor-plus-icon"
                                          : "mdi mdi-plus float-right accor-plus-icon"
                                      }
                                    ></i>
                                  </h6>
                                </CardHeader>
                              </Link>
                              <Collapse isOpen={this.state.col3}>
                                <CardBody>
                                  Anim pariatur cliche reprehenderit, enim
                                  eiusmod high life accusamus terry richardson
                                  ad squid. 3 wolf moon officia aute, non
                                  cupidatat skateboard dolor brunch. Food truck
                                  quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                                  moon tempor, sunt aliqua put a bird on it
                                  squid single-origin coffee nulla assumenda
                                  shoreditch et. Nihil anim keffiyeh helvetica,
                                  craft beer labore wes anderson cred nesciunt
                                  sapiente ea proident. Ad vegan excepteur
                                  butcher vice lomo. Leggings occaecat craft
                                  beer farm-to-table, raw denim aesthetic synth
                                  nesciunt you probably haven't heard of them
                                  accusamus labore sustainable VHS.
                                </CardBody>
                              </Collapse>{" "}
                            </Card>
                           */}
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default AddProfile;
