import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  GET_MENU,
  ADD_MENU,
  // PUT_MENU,
  // DELETE_MENU,
  // API_ERROR,
} from "./actionTypes";

import {
  apiError,
  // get_menu, add_menu,
  get_menu_successful,
} from "./actions";
import {
  getList,
  // postAdd, postEdit, postDelete
} from "../../helpers/Helper";
//If user is login then dispatch redux action's are directly from here.
function* getMenu({ payload: { menu, history } }) {
  try {
    const response = yield call(getList, "/system/menu/4", {});
    // localStorage.setItem("authUser", JSON.stringify(response));
    yield put(get_menu_successful(response));

    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* addMenu({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchMenu() {
  yield takeEvery(GET_MENU, getMenu);
}

export function* watchAddMenu() {
  yield takeEvery(ADD_MENU, addMenu);
}

function* menuSaga() {
  yield all([fork(watchMenu), fork(watchAddMenu)]);
}

export default menuSaga;
