import {
  API_ERROR,
  BRAND,
  BRAND_LIST_SUCCESSFUL,
  BRAND_ADD,
  BRAND_ADD_SUCCESSFUL,
  BRAND_EDIT,
  BRAND_EDIT_SUCCESSFUL,
  BRAND_DELETE,
  BRAND_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

export const getbrandlist = () => {
  return {
    type: BRAND,
  };
};

export const brandListSuccessful = (brands) => {
  return {
    type: BRAND_LIST_SUCCESSFUL,
    payload: brands,
  };
};

export const brandAdd = (brands, history) => {
  return {
    type: BRAND_ADD,
    payload: { brands, history },
  };
};

export const brandAddSuccessful = (response) => {
   return {
    type: BRAND_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const brandEdit = (data) => {
  return {
    type: BRAND_EDIT,
    payload: data,
  };
};

export const brandEditSuccessful = (response) => {
  return {
    type: BRAND_EDIT_SUCCESSFUL,
    payload: response,
  };
};

export const brandDelete = (BrnId, history) => {
  return {
    type: BRAND_DELETE,
    payload: { BrnId, history },
  };
};

export const SetEditData = (data) => {
  return {
    type: BRAND_SETROWDATA,
    payload: data,
  };
};

export const model_open = () => {
  return {
    type: MODEL_OPEN,
  };
};

export const model_close = () => {
  return {
    type: MODEL_CLOSE,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
