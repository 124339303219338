export const LOCATIONS = 'locatons';
export const LOCATION_ADD = 'location_add';
export const LOCATION_DELETE = 'location_deleted';
export const LOCATION_EDIT = 'location_edit';

export const LOCATION_SETROWDATA = 'location_set_rowdata';
export const MODEL_OPEN = 'location_model_open';
export const MODEL_CLOSE = 'location_model_close';

export const LOCATION_LIST_SUCCESSFUL = 'locaton_list_successfull';
export const LOCATION_ADD_SUCCESSFUL = 'locaton_add_successfull';
export const LOCATION_EDIT_SUCCESSFUL = 'location_edit_successfull';

export const API_ERROR = 'api_failed';
