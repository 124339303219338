import {
  API_ERROR,
 DEALERS,
 DEALERS_LIST_SUCCESSFUL,
 DEALERS_ADD,
 DEALERS_ADD_SUCCESSFUL,
 DEALERS_EDIT,
 DEALERS_EDIT_SUCCESSFUL,
  DEALERS_DELETE,
  // DEALERS_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  Dealerslist: [],
  model: false,
  error_msg: null,
  rowdata: {},
};

const Dealers = (state = initialState, action) => {
  switch (action.type) {
    case DEALERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DEALERS_LIST_SUCCESSFUL:
      state = {
        ...state,
        Dealerslist: action.payload.data,
        loading: false,
      };
      break;
    case DEALERS_ADD:
      state = {
        ...state,
        loading: false,
      };
      break;
    case DEALERS_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
      };
      break;
    case DEALERS_EDIT:
      state = {
        ...state,
        model: true,
        rowdata: action.payload,
      };
      break;
    case DEALERS_EDIT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case DEALERS_DELETE:
      state = {
        ...state,
        loading: false,
      };
      break;
    // case DEALERS_SETROWDATA:
    //   state = {
    //     ...state,
    //     rowdata: action.payload,
    //     // model: true,
    //   };
      break;
    case MODEL_OPEN:
      state = {
        ...state,
        model: true,
      };
      break;
    case MODEL_CLOSE:
      state = {
        ...state,
        model: false,
        rowdata: { id: 0 },
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Dealers;
