export const VEHICLE_DETAILS = 'vehicle_details';
export const ROW_VEHICLE_DETAILS = 'row_vehicle_details';
export const VEHICLE_DETAILS_ADD = 'vehicle_details_add';
export const VEHICLE_DETAILS_DELETE = 'vehicle_details_deleted';
export const VEHICLE_DETAILS_EDIT = 'vehicle_details_edit';
export const VEHICLE_DETAILS_CHANGEVALUE = 'vehicle_details_changevalue';
export const VEHICLE_DETAILS_SELECT_CHANGEVALUE = 'vehicle_details_select_changevalue';

// export const VERSION_TYPES_SETROWDATA = 'version_types_set_rowdata';
// export const MODEL_OPEN = 'version_types_model_open';
// export const MODEL_CLOSE = 'version_types_model_close';

export const VEHICLE_DETAILS_LIST_SUCCESSFUL = 'vehicle_details_list_successfull';
export const ROW_VEHICLE_DETAILS_LIST_SUCCESSFUL = 'row_vehicle_details_list_successfull';
export const VEHICLE_DETAILS_ADD_SUCCESSFUL = 'vehicle_details_add_successfull';
export const VEHICLE_DETAILS_EDIT_SUCCESSFUL = 'vehicle_details_edit_successfull';

export const API_ERROR = 'api_failed';
