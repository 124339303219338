import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  MODEL_YEAR,
  MODEL_YEAR_ADD,
  MODEL_YEAR_DELETE,
  MODEL_OPEN,
} from "./actionTypes";
import {
  apiError,
  getModelYearList,
  ModelYearListSuccessful,
  ModelYearAddSuccessful,
  SetEditData,
} from "./actions";

// AUTH related methods
import {
  postAdd,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";

import Swal from "sweetalert2";

function* getModelYear() {
  try {
    const data = yield call(getList, "/masters/modelyears/", {});
    yield put(ModelYearListSuccessful({ data }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* OnSetEditData() {
  var today = new Date(),
    code =
      "MY" +
      today.getFullYear() +
      (today.getMonth() + 1) +
      today.getDate() +
      today.getHours() +
      today.getMinutes() +
      today.getSeconds();
  const createdon =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    " " +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  yield put(SetEditData({ id: 0, code, createdon }));
}
//If user is login then dispatch redux action's are directly from here.
function* AddModelYear({ payload: { ModelYear } }) {
  try {
    if (ModelYear.id == 0) {
      const response = yield call(postAdd, "/masters/modelyears/", {
        code: ModelYear.code,
        name: ModelYear.name,
        createdon: new Date(ModelYear.createdon),
      });
      if (response.status === 201) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your Model Year Added Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        yield put(getModelYearList());
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to add Model Year. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      yield put(ModelYearAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/modelyears/" + ModelYear.id,
        {
          code: ModelYear.code,
          name: ModelYear.name,
          modifiedon: new Date(ModelYear.createdon),
        }
      );
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your Model Year Edited Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        yield put(getModelYearList());
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to edit Model Year. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      yield put(ModelYearAddSuccessful(null));
    }
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });
    //yield put(apiError(error));
  }
}

//If user is login then dispatch redux action's are directly from here.
function* DeleteModelYear(MyId) {
  try {
    const response = yield call(
      postDelete,
      "/masters/modelyears/" + MyId.payload.MyId
    );
    if (response.status === 204) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Poof! Your Model Year has been deleted!",
        showConfirmButton: false,
        timer: 1500,
      });

      yield put(getModelYearList());
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Sorry! Your Model Year has been not deleted!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    // yield put(locationDeleteSuccessful(response.statusText))
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });
    yield put(apiError(error));
  }
}

export function* watchgetModelYearlist() {
  yield takeEvery(MODEL_YEAR, getModelYear);
}
export function* watchModelYearAdd() {
  yield takeEvery(MODEL_YEAR_ADD, AddModelYear);
}

export function* watchModelYearDelete() {
  yield takeEvery(MODEL_YEAR_DELETE, DeleteModelYear);
}
export function* watchSetEditData() {
  yield takeEvery(MODEL_OPEN, OnSetEditData);
}
function* ModelYearSaga() {
  yield all([
    fork(watchgetModelYearlist),
    fork(watchModelYearAdd),
    fork(watchModelYearDelete),
    fork(watchSetEditData),
  ]);
}

export default ModelYearSaga;
