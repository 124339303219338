import {
  API_ERROR,
  VEHICALT_TYPE,
  VEHICALT_TYPE_LIST_SUCCESSFUL,
  VEHICALT_TYPE_ADD,
  VEHICALT_TYPE_ADD_SUCCESSFUL,
  VEHICALT_TYPE_EDIT,
  VEHICALT_TYPE_EDIT_SUCCESSFUL,
  VEHICALT_TYPE_DELETE,
  // VEHICALT_TYPE_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

export const getVehicleTypesList = () => {
   return {
    type: VEHICALT_TYPE,
  };
};

export const VehicleTypesListSuccessful = (VehicleTypes) => {
  return {
    type: VEHICALT_TYPE_LIST_SUCCESSFUL,
    payload: VehicleTypes,
  };
};

export const VehicleTypesAdd = (VehicleTypes, history) => {
  return {
    type: VEHICALT_TYPE_ADD,
    payload: { VehicleTypes, history },
  };
};

export const VehicleTypesAddSuccessful = (response) => {
  return {
    type: VEHICALT_TYPE_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const VehicleTypesEdit = (data) => {
  return {
    type: VEHICALT_TYPE_EDIT,
    payload: data,
  };
};

export const VehicleTypesEditSuccessful = (response) => {
  return {
    type: VEHICALT_TYPE_EDIT_SUCCESSFUL,
    payload: response,
  };
};

export const VehicleTypesDelete = (MyId, history) => {
  return {
    type: VEHICALT_TYPE_DELETE,
    payload: { MyId, history },
  };
};

// export const SetEditData = (data) => {
//   return {
//     type: VEHICALT_TYPE_SETROWDATA,
//     payload: data,
//   };
// };

export const model_open = () => {
  return {
    type: MODEL_OPEN,
  };
};

export const model_close = () => {
  return {
    type: MODEL_CLOSE,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
