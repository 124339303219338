import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  GET_MASTERS_MENU,
  // ADD_MENU,
  // PUT_MENU,
  // DELETE_MENU,
  // API_ERROR,
} from "./actionTypes";

import { apiError, getMastersMenuSuccessful } from "./actions";
import {
  getList,
  // postAdd,
  // postEdit,
  // postDelete,
} from "../../../helpers/Helper";
//If user is login then dispatch redux action's are directly from here.
function* getMasterMenu({ payload: { mastersMenu, history } }) {
  try {
    const response = yield call(getList, "/system/menu/5", {});
    // localStorage.setItem("authUser", JSON.stringify(response));
    yield put(getMastersMenuSuccessful(response));

    history.push("/MastersDashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* addMenu({ payload: { history } }) {
//   try {
//     localStorage.removeItem("authUser");

//     history.push("/login");
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

export function* watchGetMenu() {
  yield takeEvery(GET_MASTERS_MENU, getMasterMenu);
}

// export function* watchAddMenu() {
//   yield takeEvery(ADD_MENU, addMenu);
// }

function* menuSaga() {
  yield all([
    fork(watchGetMenu),
    //  fork(watchAddMenu)
  ]);
}

export default menuSaga;
