import {
  API_ERROR,
  VEHICLE_DETAILS,
  ROW_VEHICLE_DETAILS,
  VEHICLE_DETAILS_LIST_SUCCESSFUL,
  VEHICLE_DETAILS_ADD,
  VEHICLE_DETAILS_ADD_SUCCESSFUL,
  ROW_VEHICLE_DETAILS_LIST_SUCCESSFUL,
  VEHICLE_DETAILS_EDIT,
  VEHICLE_DETAILS_EDIT_SUCCESSFUL,
  VEHICLE_DETAILS_DELETE,
  VEHICLE_DETAILS_CHANGEVALUE,
  VEHICLE_DETAILS_SELECT_CHANGEVALUE,

  // VEHICLE_DETAILS_SETROWDATA,
  // MODEL_OPEN,
  // MODEL_CLOSE,
} from "./actionTypes";

export const getVehicleDetailsList = () => {
  return {
    type: VEHICLE_DETAILS,
  };
};
export const getRowVehicleDetailsList = (VehicleDetailsID) => {
  // console.log("getActionRowVehicleDetailsListID",VehicleDetailsID)
  return {
    type: ROW_VEHICLE_DETAILS,
    payload: { VehicleDetailsID },
  };
};

export const VehicleDetailsListSuccessful = (VehicleDetails) => {
  return {
    type: VEHICLE_DETAILS_LIST_SUCCESSFUL,
    payload: VehicleDetails,
  };
};
export const VehicleDetailsChangeValue = ({ key, value }) => {
  return {
    type: VEHICLE_DETAILS_CHANGEVALUE,
    payload: { key, value },
  };
};

export const VehicleDetailsSelecteChangeValue = ({
  key,
  label,
  key2,
  value,
}) => {
  return {
    type: VEHICLE_DETAILS_SELECT_CHANGEVALUE,
    payload: { key, label, key2, value },
  };
};
export const RowVehicleDetailsListSuccessful = (VehicleDetails) => {
  return {
    type: ROW_VEHICLE_DETAILS_LIST_SUCCESSFUL,
    payload: VehicleDetails,
  };
};

export const VehicleDetailsAdd = (VehicleDetails, VDId, history) => {
  return {
    type: VEHICLE_DETAILS_ADD,
    payload: { VehicleDetails, VDId, history },
  };
};

export const VehicleDetailsAddSuccessful = (response) => {
  return {
    type: VEHICLE_DETAILS_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const VehicleDetailsEdit = (data) => {
  return {
    type: VEHICLE_DETAILS_EDIT,
    payload: data,
  };
};

export const VehicleDetailsEditSuccessful = (response) => {
  return {
    type: VEHICLE_DETAILS_EDIT_SUCCESSFUL,
    payload: response,
  };
};

export const VehicleDetailsDelete = (MyId, history) => {
  return {
    type: VEHICLE_DETAILS_DELETE,
    payload: { MyId, history },
  };
};

// export const SetEditData = (data) => {
//   return {
//     type: VEHICLE_DETAILS_SETROWDATA,
//     payload: data,
//   };
// };

// export const model_open = () => {
//   return {
//     type: MODEL_OPEN,
//   };
// };

// export const model_close = () => {
//   return {
//     type: MODEL_CLOSE,
//   };
// };

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
