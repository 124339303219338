import React, { Component } from "react";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";

import {
  getVersionTypesList,
  VersionTypesAdd,
  VersionTypesEdit,
  VersionTypesDelete,
  model_open,
  model_close,
  VersionTypesChangeValue,
  VersionTypesSelecteChangeValue,
} from "../../../store/Masters/VersionTypes/actions";
import { getbrandlist } from "../../../store/Masters/Brand/actions";
import { getVersionsList } from "../../../store/Masters/Versions/actions";
import { getVehicleTypesList } from "../../../store/Masters/VehicleTypes/actions";

class AddVersionTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // brand_name: null,
      // brand_id: 0,
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.ChangeHandeler = this.ChangeHandeler.bind(this);
  }
  componentDidMount() {
    this.props.getVersionTypesList();
    this.props.getbrandlist();
    this.props.getVersionsList();
    this.props.getVehicleTypesList();
  }
  handleValidSubmit() {
    console.log(this.props.rowdata);
    this.props.VersionTypesAdd(this.props.rowdata, this.props.history);
    // event.preventDefault();
  }

  ChangeHandeler(e) {
    // e.target.value
    // e.target.name
    this.props.VersionTypesChangeValue({
      key: e.target.name,
      value: e.target.value,
    });
  }
  render() {
    var data = this.props.rowdata;
    return (
      <React.Fragment>
        <Modal isOpen={this.props.model} backdrop="static" centered>
          <ModalHeader toggle={() => this.props.model_close()}>
            ADD Version Type
          </ModalHeader>
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.handleValidSubmit}
          >
            <ModalBody>
              <Row className="justify-content-center">
                <Col md={10}>
                  {/* <Row className="justify-content-center">
                    <Col md={9}> */}
                  {/* <FormGroup row>
                        <Col md={4}>
                          <Label
                            htmlFor="VersionTypesCode"
                            className="col-form-label float-right"
                          >
                            Model Years Code
                          </Label>
                        </Col>
                        <Col md={8}>
                          <AvField
                            name="code"
                            type="text"
                            readOnly
                            value={data.code}
                            className="form-control"
                            id="VersionTypesCode"
                            placeholder="Enter Model Year Code"
                            errormessage=" Please provide a valid Model Year Code"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </FormGroup> */}
                  <FormGroup row>
                    <Col md={4}>
                      <Label
                        htmlFor="VersionTypes"
                        className="col-form-label float-right"
                      >
                        Version Type
                      </Label>
                    </Col>
                    <Col md={8}>
                      <AvField
                        name="name"
                        type="text"
                        value={data.name}
                        onChange={this.ChangeHandeler}
                        className="form-control"
                        id="VersionTypes"
                        placeholder="Enter Version Type"
                        errormessage=" Please provide a valid Version Type"
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={4}>
                      <Label
                        htmlFor="brand-selection"
                        className="col-form-label float-right"
                      >
                        Brand
                      </Label>
                    </Col>
                    <Col md={8}>
                      <Select
                        options={this.props.brandslist.map((item, index) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        value={{
                          label: this.props.rowdata.brandLabel,
                          value: this.props.rowdata.brand,
                        }}
                        onChange={(e) => {
                          this.props.VersionTypesSelecteChangeValue({
                            key: "brandLabel",
                            label: e.label,
                            key2: "brand",
                            value: e.value,
                          });
                        }}
                        name="brand"
                        classNamePrefix="brand-selection"
                        id="brand-selection"
                        errormessage=" Please provide a valid brand"
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={4}>
                      <Label
                        htmlFor="brand-selection"
                        className="col-form-label float-right"
                      >
                        Version
                      </Label>
                    </Col>
                    <Col md={8}>
                      <Select
                        options={this.props.Versionslist.map((item, index) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        value={{
                          label: this.props.rowdata.versionlabel,
                          value: this.props.rowdata.version,
                        }}
                        onChange={(e) => {
                          this.props.VersionTypesSelecteChangeValue({
                            key: "versionlabel",
                            label: e.label,
                            key2: "version",
                            value: e.value,
                          });
                        }}
                        name="version"
                        classNamePrefix="version-selection"
                        id="version-selection"
                        errorMessage=" Please provide a valid brand"
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={4}>
                      <Label
                        htmlFor="brand-selection"
                        className="col-form-label float-right"
                      >
                        Vehicle Type
                      </Label>
                    </Col>
                    <Col md={8}>
                      <Select
                        options={this.props.VehicleTypeslist.map(
                          (item, index) => ({
                            label: item.name,
                            value: item.id,
                          })
                        )}
                        value={{
                          label: this.props.rowdata.vehicletypelabel,
                          value: this.props.rowdata.vehicletype,
                        }}
                        onChange={(e) => {
                          this.props.VersionTypesSelecteChangeValue({
                            key: "vehicletypelabel",
                            label: e.label,
                            key2: "vehicletype",
                            value: e.value,
                          });
                        }}
                        name="vehicletype"
                        classNamePrefix="brand-selection"
                        id="brand-selection"
                        errormessage=" Please provide a valid brand"
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    {/* <AvField
                      className="form-control"
                      type="hidden"
                      value={data.createdon}
                      onChange={this.ChangeHandeler}
                      id="createdon"
                      name="createdon"
                    />
                    <AvField
                      className="form-control"
                      type="hidden"
                      value={data.id}
                      onChange={this.ChangeHandeler}
                      id="id"
                      name="id"
                    /> */}
                  </FormGroup>
                </Col>
              </Row>
              {/* </Col>
              </Row> */}

              <ModalFooter>
                <Button
                  type="button"
                  className="btn btn-danger"
                  color="light"
                  onClick={() => this.props.model_close()}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  color="primary"
                >
                  Save
                </Button>
              </ModalFooter>
            </ModalBody>
          </AvForm>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { VersionTypeslist, model, rowdata } = state.VersionTypes;
  const { brandslist } = state.Brand;
  const { Versionslist } = state.Versions;
  const { VehicleTypeslist } = state.VehicleTypes;
  return {
    VehicleTypeslist,
    Versionslist,
    brandslist,
    VersionTypeslist,
    model,
    rowdata,
  };
};

export default connect(mapStatetoProps, {
  getVersionsList,
  getVehicleTypesList,
  getVersionTypesList,
  getbrandlist,
  VersionTypesAdd,
  VersionTypesEdit,
  VersionTypesDelete,
  model_open,
  model_close,
  VersionTypesChangeValue,
  VersionTypesSelecteChangeValue,
})(AddVersionTypes);
