export const VEHICALT_TYPE = 'vehicle_types';
export const VEHICALT_TYPE_ADD = 'vehicle_types_add';
export const VEHICALT_TYPE_DELETE = 'vehicle_types_deleted';
export const VEHICALT_TYPE_EDIT = 'vehicle_types_edit';

// export const VEHICALT_TYPE_SETROWDATA = 'vehicle_types_set_rowdata';
export const MODEL_OPEN = 'vehicle_types_model_open';
export const MODEL_CLOSE = 'vehicle_types_model_close';

export const VEHICALT_TYPE_LIST_SUCCESSFUL = 'vehicle_types_list_successfull';
export const VEHICALT_TYPE_ADD_SUCCESSFUL = 'vehicle_types_add_successfull';
export const VEHICALT_TYPE_EDIT_SUCCESSFUL = 'vehicle_types_edit_successfull';

export const API_ERROR = 'api_failed';
