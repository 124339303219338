import React, { Component } from "react";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
} from "reactstrap";

import {
  getModelYearList,
  ModelYearAdd,
  ModelYearEdit,
  ModelYearDelete,
  model_open,
  model_close,
} from "../../../store/Masters/ModelYears/actions";

class AddModelYear extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    this.props.ModelYearAdd(values, this.props.history);
  }
  handleModelYearChange = (event) => {
    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    this.setState({ value });
  };
  render() {
    var data = this.props.rowdata;
    return (
      <React.Fragment>
        <Modal isOpen={this.props.model} backdrop="static" centered>
          <ModalHeader toggle={() => this.props.model_close()}>
            ADD Model Years
          </ModalHeader>
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.handleValidSubmit}
          >
            <ModalBody>
              <Row className="justify-content-center">
                <Col md={10}>
                  {/* <Row className="justify-content-center">
                    <Col md={9}> */}
                  {/* <FormGroup row>
                        <Col md={4}>
                          <Label
                            htmlFor="ModelYearCode"
                            className="col-form-label float-right"
                          >
                            Model Years Code
                          </Label>
                        </Col>
                        <Col md={8}>
                          <AvField
                            name="code"
                            type="text"
                            readOnly
                            value={data.code}
                            className="form-control"
                            id="ModelYearCode"
                            placeholder="Enter Model Year Code"
                            errormessage=" Please provide a valid Model Year Code"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </FormGroup> */}
                  <FormGroup row>
                    <Col md={4}>
                      <Label
                        htmlFor="ModelYear"
                        className="col-form-label float-right"
                      >
                        Model Year
                      </Label>
                    </Col>
                    <Col md={8}>
                      <AvField
                        name="name"
                        type="number"
                        min="1990"
                        max={new Date().getFullYear()}
                        onChange={this.handleModelYearChange}
                        value={data.name}
                        className="form-control"
                        id="ModelYear"
                        placeholder="Enter Model Year"
                        errormessage="Please provide a valid Model Year"
                        validate={{ required: { value: true } }}
                      />
                      {/* <span>Note:Please enter model Year Between 1990 to {new Date().getFullYear()}</span> */}
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <AvField
                      className="form-control"
                      type="hidden"
                      value={data.createdon}
                      id="createdon"
                      name="createdon"
                    />
                    <AvField
                      className="form-control"
                      type="hidden"
                      value={data.id}
                      id="id"
                      name="id"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* </Col>
              </Row> */}

              <ModalFooter>
                <Button
                  type="button"
                  className="btn btn-danger"
                  color="light"
                  onClick={() => this.props.model_close()}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  color="primary"
                >
                  Save
                </Button>
              </ModalFooter>
            </ModalBody>
          </AvForm>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { ModelYearlist, model, rowdata } = state.ModelYear;
  return { ModelYearlist, model, rowdata };
};

export default connect(mapStatetoProps, {
  getModelYearList,
  ModelYearAdd,
  ModelYearEdit,
  ModelYearDelete,
  model_open,
  model_close,
})(AddModelYear);
