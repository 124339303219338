import React, { Component } from "react";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

// import {
//   Row,
//   Col,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   Button,
//   FormGroup,
//   Label,
// } from "reactstrap";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  Button,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";

import {
  getVehicleDetailsList,
  VehicleDetailsAdd,
  VehicleDetailsEdit,
  VehicleDetailsDelete,
  VehicleDetailsChangeValue,
  VehicleDetailsSelecteChangeValue,
  getRowVehicleDetailsList,
  // model_open,
  // model_close,
} from "../../../store/Masters/VehicleDetails/actions";
import { getVersionTypesList } from "../../../store/Masters/VersionTypes/actions";
import { getbrandlist } from "../../../store/Masters/Brand/actions";
import { getVersionsList } from "../../../store/Masters/Versions/actions";
import { getVehicleTypesList } from "../../../store/Masters/VehicleTypes/actions";

import { getModelYearList } from "../../../store/Masters/ModelYears/actions";
import CardFooter from "reactstrap/lib/CardFooter";

class AddVehicleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // brand_name: null,
      brand_id: 0,
      version_id: 0,
      vehicletypes_id: 0,
      modelyear_id: 0,
      VersionTypes_id: 0,
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.ChangeHandeler = this.ChangeHandeler.bind(this);
  }
  componentDidMount() {
    this.props.getRowVehicleDetailsList(this.props.match.params.id);
    this.props.getVersionTypesList();
    this.props.getbrandlist();
    this.props.getVersionsList();
    this.props.getVehicleTypesList();
    this.props.getModelYearList();
    // this.props.getVersionTypesList();
  }
  handleValidSubmit() {
    console.log("this.props.rowdata", this.props.rowdata);

    this.props.VehicleDetailsAdd(
      this.props.rowdata,
      this.props.match.params.id,
      this.props.history
    );
  }

  ChangeHandeler(e) {
    // e.target.value
    // e.target.name
    this.props.VehicleDetailsChangeValue({
      key: e.target.name,
      value: e.target.value,
    });
  }
  render() {
    const {
      match: { params },
    } = this.props;
    var data = this.props.rowdata;
    console.log(" this.props.rowdata.brand",  this.props.rowdata.brand,this.props.rowdata );
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <div className="row">
                      <div className="col-md-4">
                        <Link to="/VehicleDetails">
                          <i className="ri-arrow-go-back-fill font-size-18"></i>
                        </Link>
                        <h5 className="card-title-18 d-inline-block ml-2">
                          Add New Vehicle Details
                        </h5>
                      </div>

                      {/* <div className="col-md-4 text-right">
                        <Button
                          type="button"
                          color="primary"
                          className="waves-effect waves-light"
                          href="/AddVehicleDetails"
                          // onClick={() => this.props.model_open()}
                        >
                          Add Vehicle Details
                        </Button>
                      </div> */}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={this.handleValidSubmit}
                    >
                      <Row className="justify-content-center">
                        <Col md={10}>
                          <h4 className="card-title-18">Vehicle Details</h4>
                          <Row className="justify-content-center">
                            <Col md={6}>
                              <FormGroup row>
                                <Col md={4}>
                                  <Label
                                    htmlFor="brand-selection"
                                    className="col-form-label float-right"
                                  >
                                    Brand 
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <Select
                                    options={this.props.brandslist.map(
                                      (item, index) => ({
                                        label: item.name,
                                        value: item.id,
                                      })
                                    )}
                                    value={{
                                      label: this.props.rowdata.brandLabel,
                                      value: this.props.rowdata.brand,
                                    }}
                                    onChange={(e) => {
                                      this.props.VehicleDetailsSelecteChangeValue(
                                        {
                                          key: "brandLabel",
                                          label: e.label,
                                          key2: "brand",
                                          value: e.value,
                                        }
                                      );
                                    }}
                                    name="brand"
                                    classNamePrefix="brand-selection"
                                    id="brand-selection"
                                    errormessage=" Please provide a valid brand"
                                    validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>

                              <FormGroup row>
                                <Col md={4}>
                                  <Label
                                    htmlFor="Version-selection"
                                    className="col-form-label float-right"
                                  >
                                    Version
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <Select
                                    options={this.props.Versionslist.map(
                                      (item, index) => ({
                                        label: item.name,
                                        value: item.id,
                                      })
                                    )}
                                    value={{
                                      label: this.props.rowdata.versionlabel,
                                      value: this.props.rowdata.version,
                                    }}
                                    onChange={(e) => {
                                      this.props.VehicleDetailsSelecteChangeValue(
                                        {
                                          key: "versionlabel",
                                          label: e.label,
                                          key2: "version",
                                          value: e.value,
                                        }
                                      );
                                    }}
                                    name="version"
                                    classNamePrefix="version-selection"
                                    id="version-selection"
                                    errorMessage=" Please provide a valid brand"
                                    validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col md={4}>
                                  <Label
                                    htmlFor="modelyear-selection"
                                    className="col-form-label float-right"
                                  >
                                    Model Year
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <Select
                                    options={this.props.ModelYearlist.map(
                                      (item, index) => ({
                                        label: item.name,
                                        value: item.id,
                                      })
                                    )}
                                    value={{
                                      label: this.props.rowdata.modelyearlabel,
                                      value: this.props.rowdata.modelyear,
                                    }}
                                    onChange={(e) => {
                                      this.props.VehicleDetailsSelecteChangeValue(
                                        {
                                          key: "modelyearlabel",
                                          label: e.label,
                                          key2: "modelyear",
                                          value: e.value,
                                        }
                                      );
                                    }}
                                    name="modelyear"
                                    classNamePrefix="modelyear-selection"
                                    id="modelyear-selection"
                                    errormessage=" Please provide a valid Model Year"
                                    validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup row>
                                <Col md={4}>
                                  <Label
                                    htmlFor="VehicleType-selection"
                                    className="col-form-label float-right"
                                  >
                                    Vehicle Type
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <Select
                                    options={this.props.VehicleTypeslist.map(
                                      (item, index) => ({
                                        label: item.name,
                                        value: item.id,
                                      })
                                    )}
                                    value={{
                                      label: this.props.rowdata
                                        .vehicletypelabel,
                                      value: this.props.rowdata.vehicletype,
                                    }}
                                    onChange={(e) => {
                                      this.props.VehicleDetailsSelecteChangeValue(
                                        {
                                          key: "vehicletypelabel",
                                          label: e.label,
                                          key2: "vehicletype",
                                          value: e.value,
                                        }
                                      );
                                    }}
                                    name="vehicletype"
                                    classNamePrefix="brand-selection"
                                    id="brand-selection"
                                    errormessage=" Please provide a valid brand"
                                    validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col md={4}>
                                  <Label
                                    htmlFor="VersionTypes-selection"
                                    className="col-form-label float-right"
                                  >
                                    Version Types
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <Select
                                    options={this.props.VersionTypeslist.map(
                                      (item, index) => ({
                                        label: item.name,
                                        value: item.id,
                                      })
                                    )}
                                    value={{
                                      label: this.props.rowdata
                                        .versiontypeslabel,
                                      value: this.props.rowdata.versiontype,
                                    }}
                                    onChange={(e) => {
                                      this.props.VehicleDetailsSelecteChangeValue(
                                        {
                                          key: "versiontypeslabel",
                                          label: e.label,
                                          key2: "versiontype",
                                          value: e.value,
                                        }
                                      );
                                    }}
                                    name="VersionTypes-selection"
                                    classNamePrefix="VersionTypes-selection"
                                    id="VersionTypes-selection"
                                    errormessage=" Please provide a valid Version Type"
                                    validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={10}>
                          <h4 className="card-title-18">Engine Details</h4>
                          <Row className="justify-content-center">
                            <Col md={6}>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="EngineType-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Engine type
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Enginetype"
                                    type="text"
                                    value={data.Enginetype}
                                    className="form-control"
                                    onChange={this.ChangeHandeler}
                                    id="EngineType-text-input"
                                    placeholder="Enter Engine Type"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="ClutchType-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Clutch Type
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Clutchtype"
                                    type="text"
                                    value={data.Clutchtype}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="ClutchType-text-input"
                                    placeholder="Enter Clutch Type"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="TransmissionType-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Transmission type
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Transmissiontype"
                                    type="text"
                                    value={data.Transmissiontype}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="TransmissionType-text-input"
                                    placeholder="Enter Transmission type"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="Displacement-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Displacement
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Displacement"
                                    type="text"
                                    value={data.Displacement}
                                    onChange={this.ChangeHandeler}
                                    className="form-control"
                                    id="Displacement-text-input"
                                    placeholder="Enter Displacement"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="FuelSystem-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Fuel system
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Fuelsystem"
                                    type="text"
                                    value={data.Fuelsystem}
                                    onChange={this.ChangeHandeler}
                                    className="form-control"
                                    id="FuelSystem-text-input"
                                    placeholder="Enter Fuel System"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="StartingSystemType-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Starting System
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Startingsystem"
                                    type="text"
                                    value={data.Startingsystem}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="StartingSystemType-text-input"
                                    placeholder="Enter Starting system type"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={10}>
                          <h4 className="card-title-18">Dimensions Details</h4>
                          <Row className="justify-content-center">
                            <Col md={6}>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="OverallLength-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Overall length
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Overalllength"
                                    type="text"
                                    value={data.Overalllength}
                                    onChange={this.ChangeHandeler}
                                    className="form-control "
                                    id="OverallLength-text-input"
                                    placeholder="Enter Overall length"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="OverallHeight-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Overall height
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Overallheight"
                                    type="text"
                                    value={data.Overallheight}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="OverallHeight-text-input"
                                    placeholder="Enter Overall Height"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="SeatHeight-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Seat height
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="SeatHeight"
                                    type="text"
                                    value={data.Seatheight}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="SeatHeight-text-input"
                                    placeholder="Enter Seat Height"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="FuelTankCapacity-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Fuel tank capacity
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Fueltank"
                                    type="text"
                                    value={data.Fueltank}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="FuelTankCapacity-text-input"
                                    placeholder="Enter Fuel tank capacity"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="OverallWidth-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Overall width
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Overallwidth"
                                    type="text"
                                    value={data.Overallwidth}
                                    onChange={this.ChangeHandeler}
                                    className="form-control"
                                    id="OverallWidth-text-input"
                                    placeholder="Enter Overall width"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="MinimumGroundClearance-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Ground Clearance
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="GroundClearance"
                                    type="text"
                                    value={data.GroundClearance}
                                    onChange={this.ChangeHandeler}
                                    className="form-control"
                                    id="MinimumGroundClearance-text-input"
                                    placeholder="Enter Minimum ground clearance"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="Wheelbase-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Wheelbase
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Wheelbase"
                                    type="text"
                                    value={data.Wheelbase}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="Wheelbase-text-input"
                                    placeholder="Enter Wheelbase"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={10}>
                          <h4 className="card-title-18">Chassis Details</h4>
                          <Row className="justify-content-center">
                            <Col md={6}>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="FrameType-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Frame type
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Frametype"
                                    type="text"
                                    value={data.Frametype}
                                    onChange={this.ChangeHandeler}
                                    className="form-control "
                                    id="FrameType-text-input"
                                    placeholder="Enter Frame type"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor=" BrakeType-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Brake type (Rear)
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="BraketypeRear"
                                    type="text"
                                    value={data.BraketypeRear}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="BrakeType-text-input"
                                    placeholder="Enter Brake type (Rear)"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="TyreSize-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Tyre size (Front)
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="TyresizeFront"
                                    type="text"
                                    value={data.TyresizeFront}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="TyreSize-text-input"
                                    placeholder="Enter Tyre size (Front)"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="ABS-text-input"
                                    className="col-form-label float-right"
                                  >
                                    ABS
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="ABS"
                                    type="text"
                                    value={data.ABS}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="ABS-text-input"
                                    placeholder="Enter ABS"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="BrakeType-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Brake type (Front)
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="BraketypeFront"
                                    type="text"
                                    value={data.BraketypeFront}
                                    onChange={this.ChangeHandeler}
                                    className="form-control"
                                    id="BrakeType-text-input"
                                    placeholder="Enter Brake type (Front)"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="SuspensionType-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Suspension Type
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="SuspensionType"
                                    type="text"
                                    value={data.SuspensionType}
                                    onChange={this.ChangeHandeler}
                                    className="form-control"
                                    id="SuspensionType-text-input"
                                    placeholder="Enter Suspension Type (front/rear)"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="TyreSize-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Tyre size (Rear)
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="TyresizeRear"
                                    type="text"
                                    value={data.TyresizeRear}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="TyreSize-text-input"
                                    placeholder="Enter  Tyre size (Rear)"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={10}>
                          <h4 className="card-title-18">Other Information</h4>
                          <Row className="justify-content-center">
                            <Col md={6}>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="Battery-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Battery
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Battery"
                                    type="text"
                                    value={data.Battery}
                                    onChange={this.ChangeHandeler}
                                    className="form-control "
                                    id="Battery-text-input"
                                    placeholder="Enter Battery"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="FuelConsumptionIndicator-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Fuel Indicator
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="FuelIndicator"
                                    type="text"
                                    value={data.FuelIndicator}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="FuelConsumptionIndicator-text-input"
                                    placeholder="Enter Fuel consumption indicator"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="Headlight-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Headlight
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Headlight"
                                    type="text"
                                    value={data.Headlight}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="Headlight-text-input"
                                    placeholder="Enter Headlight"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor=" BrakelightTaillight-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Brakelight/Taillight
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Brakelight_Taillight"
                                    type="text"
                                    value={data.Brakelight_Taillight}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="BrakelightTaillight-text-input"
                                    placeholder="Enter Brakelight / Taillight"
                                    errorMessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor=" Tachometer-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Tachometer
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Tachometer"
                                    type="text"
                                    value={data.Tachometer}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="Tachometer-text-input"
                                    placeholder="Enter Tachometer"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="GearPositionIndicator-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Gear Position Indicator
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="GearPosition"
                                    type="text"
                                    value={data.GearPosition}
                                    onChange={this.ChangeHandeler}
                                    className="form-control"
                                    id="GearPositionIndicator-text-input"
                                    placeholder="Enter Gear Position Indicator"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="SidestandEngineCutOffSwitch-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Cut-off switch
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Cut_offSwitch"
                                    type="text"
                                    value={data.Cut_offSwitch}
                                    onChange={this.ChangeHandeler}
                                    className="form-control"
                                    id="SidestandEngineCutOffSwitch-text-input"
                                    placeholder="Enter Sidestand engine cut-off switch"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="PositionLight-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Position Light
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="PositionLight"
                                    type="text"
                                    value={data.PositionLight}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="PositionLight-text-input"
                                    placeholder="Enter Position Light"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="Speedometer-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Speedometer
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Speedometer"
                                    type="text"
                                    value={data.Speedometer}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="Speedometer-text-input"
                                    placeholder="Enter Speedometer"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row className="mb-0">
                                <Col md={4}>
                                  <Label
                                    htmlFor="FuelGauge-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Fuel gauge
                                  </Label>
                                </Col>
                                <Col md={8}>
                                  <AvField
                                    name="Fuelgauge"
                                    type="text"
                                    value={data.Fuelgauge}
                                    onChange={this.ChangeHandeler}
                                    className="form-control pb-0"
                                    id="FuelGauge-text-input"
                                    placeholder="Enter Fuel Gauge"
                                    errormessage=" Please provide a valid text"
                                    // validate={{ required: { value: true } }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        {/* <Col md={10}>
                          <h4 className="card-title-18">Extra Features</h4>
                        </Col> */}
                      </Row>
                      {/* <FormGroup>
                        <AvField
                          className="form-control"
                          type="hidden"
                          value={data.createdon}
                          id="createdon"
                          name="createdon"
                        />
                        <AvField
                          className="form-control"
                          type="hidden"
                          value={data.id}
                          id="id"
                          name="id"
                        />
                      </FormGroup> */}
                      <CardFooter className="bg-transparent">
                        <div className="float-right">
                          <Button
                            type="button"
                            className="btn btn-danger mr-2"
                            color="light"
                            href="/VehicleDetails"
                            // onClick={() => this.props.model_close()}
                          >
                            Close
                          </Button>
                          <Button
                            type="submit"
                            className="btn btn-primary"
                            color="primary"
                          >
                            Save
                          </Button>
                        </div>
                      </CardFooter>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    VehicleDetailslist,
    RowVehicleDetailslist,
    model,
    rowdata,
  } = state.VehicleDetails;
  const { VersionTypeslist } = state.VersionTypes;
  const { brandslist } = state.Brand;
  const { Versionslist } = state.Versions;
  const { VehicleTypeslist } = state.VehicleTypes;
  const { ModelYearlist } = state.ModelYear;

  return {
    ModelYearlist,
    VersionTypeslist,
    VehicleTypeslist,
    Versionslist,
    brandslist,
    VersionTypeslist,
    VehicleDetailslist,
    RowVehicleDetailslist,
    model,
    rowdata,
  };
};

export default connect(mapStatetoProps, {
  getModelYearList,
  getVersionsList,
  getVehicleTypesList,
  getVersionTypesList,
  getbrandlist,
  getVehicleDetailsList,
  VehicleDetailsAdd,
  VehicleDetailsEdit,
  VehicleDetailsDelete,
  VehicleDetailsChangeValue,
  VehicleDetailsSelecteChangeValue,
  getRowVehicleDetailsList,
  // model_open,
  // model_close,
})(AddVehicleDetails);
