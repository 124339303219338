import {
  API_ERROR,
  DEALERS,
  DEALERS_LIST_SUCCESSFUL,
  DEALERS_ADD,
  DEALERS_ADD_SUCCESSFUL,
  DEALERS_EDIT,
  DEALERS_EDIT_SUCCESSFUL,
  DEALERS_DELETE,
  //DEALERS_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

export const getDealersList = () => {
  return {
    type: DEALERS,
  };
};

export const DealersListSuccessful = (Dealers) => {
  return {
    type: DEALERS_LIST_SUCCESSFUL,
    payload: Dealers,
  };
};

export const DealersAdd = (Dealers, history) => {
  return {
    type: DEALERS_ADD,
    payload: { Dealers, history },
  };
};

export const DealersAddSuccessful = (response) => {
  return {
    type: DEALERS_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const DealersEdit = (data) => {
  return {
    type: DEALERS_EDIT,
    payload: data,
  };
};

export const DealersEditSuccessful = (response) => {
  return {
    type: DEALERS_EDIT_SUCCESSFUL,
    payload: response,
  };
};

export const DealersDelete = (MyId, history) => {
  return {
    type: DEALERS_DELETE,
    payload: { MyId, history },
  };
};

// export const SetEditData = (data) => {
//   return {
//     type:DEALERS_SETROWDATA,
//     payload: data,
//   };
// };

export const model_open = () => {
  return {
    type: MODEL_OPEN,
  };
};

export const model_close = () => {
  return {
    type: MODEL_CLOSE,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
