import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  DEALERS,
  DEALERS_ADD,
  DEALERS_DELETE,
  MODEL_OPEN,
} from "./actionTypes";
import {
  apiError,
  getDealersList,
  DealersListSuccessful,
  DealersAddSuccessful,
  // SetEditData,
} from "./actions";

// AUTH related methods
import {
  postAdd,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";

import Swal from "sweetalert2";

function* getDealers() {
  try {
    const data = yield call(getList, "/masters/dealers/", {});
    yield put(DealersListSuccessful({ data }));
  } catch (error) {
    yield put(apiError(error));
  }
}
// function* OnSetEditData() {
//   var today = new Date(),
//     code =
//       "MY" +
//       today.getFullYear() +
//       (today.getMonth() + 1) +
//       today.getDate() +
//       today.getHours() +
//       today.getMinutes() +
//       today.getSeconds();
//   const createdon =
//     today.getFullYear() +
//     "-" +
//     (today.getMonth() + 1) +
//     "-" +
//     today.getDate() +
//     " " +
//     today.getHours() +
//     ":" +
//     today.getMinutes() +
//     ":" +
//     today.getSeconds();

//   yield put(SetEditData({ id: 0, code, createdon }));
// }
//If user is login then dispatch redux action's are directly from here.
function* AddDealers({ payload: { Dealers } }) {
  try {
    if (Dealers.id == 0) {
      const response = yield call(postAdd, "/masters/dealers/", {
        code: Dealers.code,
        name: Dealers.name,
        // createdon: new Date(Dealers.createdon),
      });
      if (response.status === 201) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your Vehicle Type Added Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        yield put(getDealersList());
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to add Vehicle Type. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      yield put(DealersAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/dealers/" + Dealers.id,
        {
          code: Dealers.code,
          name: Dealers.name,
          modifiedon: new Date(Dealers.createdon),
        }
      );
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your Vehicle Type Edited Successfully.!",
          showConfirmButton: false,
          timer: 1500,
        });
        yield put(getDealersList());
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Sorry! Unable to edit Vehicle Type. Please try agine!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      yield put(DealersAddSuccessful(null));
    }
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });
    //yield put(apiError(error));
  }
}

//If user is login then dispatch redux action's are directly from here.
function* DeleteDealers(MyId) {
  try {
    const response = yield call(
      postDelete,
      "/masters/dealers/" + MyId.payload.MyId
    );
    if (response.status === 204) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Poof! Your Vehicle Type has been deleted!",
        showConfirmButton: false,
        timer: 1500,
      });

      yield put(getDealersList());
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Sorry! Your Vehicle Type has been not deleted!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    // yield put(locationDeleteSuccessful(response.statusText))
  } catch (error) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Sorry! Permission Denied!",
      showConfirmButton: false,
      timer: 1500,
    });
    yield put(apiError(error));
  }
}

export function* watchgetDealerslist() {
  yield takeEvery(DEALERS, getDealers);
}
export function* watchDealersAdd() {
  yield takeEvery(DEALERS_ADD, AddDealers);
}

export function* watchDealersDelete() {
  yield takeEvery(DEALERS_DELETE, DeleteDealers);
}
// export function* watchSetEditData() {
//   yield takeEvery(MODEL_OPEN, OnSetEditData);
// }
function* DealersSaga() {
  yield all([
    fork(watchgetDealerslist),
    fork(watchDealersAdd),
    fork(watchDealersDelete),
    // fork(watchSetEditData),
  ]);
}

export default DealersSaga;
