import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER } from "./actionTypes";
import { apiError, loginUserSuccessful } from "./actions";

// AUTH related methods
import { postLogin } from "../../../helpers/Helper";
import swal from "sweetalert";

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, "/users/login/", {
      username: user.username,
      email: "absolinsoft@gmail.com",
      password: user.password,
    });
    localStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginUserSuccessful(response));

    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
    swal("Sorry! please enter valid UserName & Password", {
      icon: "error",
    });
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* loginSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default loginSaga;
