import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  CustomInput,
  // InputGroup,
  // Form,
  // InputGroupAddon,
} from "reactstrap";
// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import "./UserProfile.scss";
import avatar2 from "../../assets/images/users/avatar-2.jpg";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { file: "", imagePreviewUrl: "" };

    
  }
  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (
        <img
          className="img-thumbnail rounded-circle"
          alt="User"
          src={imagePreviewUrl}
        />
      );
    } else {
      $imagePreview = (
        <img
          className="img-thumbnail rounded-circle"
          alt="User"
          src={avatar2}
        />
      );
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Card>
              <CardHeader className="bg-transparent border-bottom">
                <h3 className="card-title-18 pt-2">Personal Profile</h3>
              </CardHeader>
              <CardBody>
                <Row className="justify-content-center">
                  <Col xs={9}>
                    <Card>
                      <CardBody>
                        <h4 className="card-title-18">General</h4>
                        <form >
                          <Row className="justify-content-center">
                            <Col xs={9}>
                              <FormGroup row>
                                <Col md={3}></Col>
                                <Col md={9}>
                                  <Row className="justify-content-center">
                                    <Col md={4}>
                                      <div className="p-image">
                                        <div className="mt-1 align-items-center ">
                                          <label htmlFor="customFile" >
                                            <div className="img-icon">
                                              <i class="ri-camera-fill "></i>
                                            </div>
                                            {$imagePreview}
                                            {/* <img
                                            className="img-thumbnail rounded-circle"
                                            alt="User"
                                            src={avatar2}
                                          /> */}
                                          </label>
                                          {/* <label >
                                            <p>img</p>
                                          </label> */}


                                          <CustomInput
                                            type="file"
                                            className="file-upload"
                                            id="customFile"
                                            accept="image/*"
                                            multiple="false"
                                            onChange={(e)=>this.handleImageChange(e)}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col md={3}>
                                  <Label
                                    htmlFor="userName-text-input"
                                    className="col-form-label float-right"
                                  >
                                    User Name
                                  </Label>
                                </Col>
                                <Col md={9}>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    defaultValue="NagendraGopalK"
                                    id="userName-text-input"
                                    disabled={true}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col md={3}>
                                  <Label
                                    htmlFor="Name-text-input"
                                    className="col-form-label float-right"
                                  >
                                    Name
                                  </Label>
                                </Col>
                                <Col md={9}>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    defaultValue="Nagendra Gopal"
                                    id="Name-text-input"
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col md={3}>
                                  <Label
                                    htmlFor="user-email-input"
                                    className="col-form-label float-right"
                                  >
                                    Email
                                  </Label>
                                </Col>
                                <Col md={9}>
                                  <Input
                                    className="form-control"
                                    type="email"
                                    defaultValue="Gopal@gmail.com"
                                    id="user-email-input"
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col md={3}>
                                  <Label
                                    htmlFor="user-tel-input"
                                    className=" col-form-label float-right"
                                  >
                                    Mobile No
                                  </Label>
                                </Col>
                                <Col md={9}>
                                  <Input
                                    className="form-control"
                                    type="tel"
                                    defaultValue="91-(879)-008-8011"
                                    id="user-tel-input"
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col md={3}>
                                  <Label
                                    htmlFor="user-gender-input"
                                    className=" col-form-label float-right"
                                  >
                                    Select Gender
                                  </Label>
                                </Col>
                                <Col md={9}>
                                  <select
                                    className="form-control"
                                    id="user-gender-input"
                                  >
                                    <option>Select</option>
                                    <option>Male</option>
                                    <option>FeMale</option>
                                  </select>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col md={3}>
                                  <Label
                                    htmlFor="user-Profile-input"
                                    className=" col-form-label float-right "
                                    id="user-Profile-input"
                                  >
                                    Select Profile
                                  </Label>
                                </Col>
                                <Col md={9}>
                                  <select className="form-control">
                                    <option>Select</option>
                                    <option>Web Developer</option>
                                    <option>Admin</option>
                                  </select>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col md={3}>
                                  <Label
                                    htmlFor="user-location-input"
                                    className=" col-form-label float-right "
                                    id="user-location-input"
                                  >
                                    Select Location
                                  </Label>
                                </Col>
                                <Col md={9}>
                                  <select className="form-control">
                                    <option>Select</option>
                                    <option>Visakhapatnam</option>
                                    <option>Vijayawada</option>
                                  </select>
                                </Col>
                              </FormGroup>
                              <div className="float-right">
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="mr-2"
                                >
                                  Save
                                </Button>
                                <Button type="submit" color="light">
                                  Cancel
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default UserProfile;
