import React, { Component } from "react";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
} from "reactstrap";

import {
  getVersionsList,
  VersionsAdd,
  VersionsEdit,
  VersionsDelete,
  model_open,
  model_close,
} from "../../../store/Masters/Versions/actions";

class AddVersions extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    this.props.VersionsAdd(values, this.props.history);
  }
  render() {
    var data = this.props.rowdata;
    return (
      <React.Fragment>
        <Modal isOpen={this.props.model} backdrop="static" centered>
          <ModalHeader toggle={() => this.props.model_close()}>
            ADD Version
          </ModalHeader>
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.handleValidSubmit}
          >
            <ModalBody>
              <Row className="justify-content-center">
                <Col md={10}>
                  {/* <Row className="justify-content-center">
                    <Col md={9}> */}
                      {/* <FormGroup row>
                        <Col md={4}>
                          <Label
                            htmlFor="VersionsCode"
                            className="col-form-label float-right"
                          >
                            Model Years Code
                          </Label>
                        </Col>
                        <Col md={8}>
                          <AvField
                            name="code"
                            type="text"
                            readOnly
                            value={data.code}
                            className="form-control"
                            id="VersionsCode"
                            placeholder="Enter Model Year Code"
                            errormessage=" Please provide a valid Model Year Code"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </FormGroup> */}
                      <FormGroup row>
                        <Col md={4}>
                          <Label
                            htmlFor="Versions"
                            className="col-form-label float-right"
                          >
                            Version 
                          </Label>
                        </Col>
                        <Col md={8}>
                          <AvField
                            name="name"
                            type="text"
                            value={data.name}
                            className="form-control"
                            id="Versions"
                            placeholder="Enter Version"
                            errormessage=" Please provide a valid Version"
                            validate={{ required: { value: true } }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <AvField
                          className="form-control"
                          type="hidden"
                          value={data.createdon}
                          id="createdon"
                          name="createdon"
                        />
                        <AvField
                          className="form-control"
                          type="hidden"
                          value={data.id}
                          id="id"
                          name="id"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                {/* </Col>
              </Row> */}

              <ModalFooter>
                <Button
                  type="button"
                  className="btn btn-danger"
                  color="light"
                  onClick={() => this.props.model_close()}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  color="primary"
                >
                  Save
                </Button>
              </ModalFooter>
            </ModalBody>
          </AvForm>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Versionslist, model, rowdata } = state.Versions;
  return { Versionslist, model, rowdata };
};

export default connect(mapStatetoProps, {
  getVersionsList,
  VersionsAdd,
  VersionsEdit,
  VersionsDelete,
  model_open,
  model_close,
})(AddVersions);
