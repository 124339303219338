import {
  API_ERROR,
  VEHICALT_TYPE,
  VEHICALT_TYPE_LIST_SUCCESSFUL,
  VEHICALT_TYPE_ADD,
  VEHICALT_TYPE_ADD_SUCCESSFUL,
  VEHICALT_TYPE_EDIT,
  VEHICALT_TYPE_EDIT_SUCCESSFUL,
  VEHICALT_TYPE_DELETE,
  // VEHICALT_TYPE_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  VehicleTypeslist: [],
  model: false,
  error_msg: null,
  rowdata: {},
};

const VehicleTypes = (state = initialState, action) => {
  switch (action.type) {
    case VEHICALT_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VEHICALT_TYPE_LIST_SUCCESSFUL:
      state = {
        ...state,
        VehicleTypeslist: action.payload.data,
        loading: false,
      };
      break;
    case VEHICALT_TYPE_ADD:
      state = {
        ...state,
        loading: false,
      };
      break;
    case VEHICALT_TYPE_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
      };
      break;
    case VEHICALT_TYPE_EDIT:
      state = {
        ...state,
        model: true,
        rowdata: action.payload,
      };
      break;
    case VEHICALT_TYPE_EDIT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case VEHICALT_TYPE_DELETE:
      state = {
        ...state,
        loading: false,
      };
      break;
    // case VEHICALT_TYPE_SETROWDATA:
    //   state = {
    //     ...state,
    //     rowdata: action.payload,
    //     // model: true,
    //   };
      break;
    case MODEL_OPEN:
      state = {
        ...state,
        model: true,
      };
      break;
    case MODEL_CLOSE:
      state = {
        ...state,
        model: false,
        rowdata: { id: 0 },
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VehicleTypes;
