import {
  API_ERROR,
  MODEL_YEAR,
  MODEL_YEAR_LIST_SUCCESSFUL,
  MODEL_YEAR_ADD,
  MODEL_YEAR_ADD_SUCCESSFUL,
  MODEL_YEAR_EDIT,
  MODEL_YEAR_EDIT_SUCCESSFUL,
  MODEL_YEAR_DELETE,
  MODEL_YEAR_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  ModelYearlist: [],
  model: false,
  error_msg: null,
  rowdata: {},
};

const modelYear = (state = initialState, action) => {
  switch (action.type) {
    case MODEL_YEAR:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MODEL_YEAR_LIST_SUCCESSFUL:
      state = {
        ...state,
        ModelYearlist: action.payload.data,
        loading: false,
      };
      break;
    case MODEL_YEAR_ADD:
      state = {
        ...state,
        loading: false,
      };
      break;
    case MODEL_YEAR_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
      };
      break;
    case MODEL_YEAR_EDIT:
      state = {
        ...state,
        model: true,
        rowdata: action.payload,
      };
      break;
    case MODEL_YEAR_EDIT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case MODEL_YEAR_DELETE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case MODEL_YEAR_SETROWDATA:
      state = {
        ...state,
        rowdata: action.payload,
        // model: true,
      };
      break;
    case MODEL_OPEN:
      state = {
        ...state,
        model: true,
      };
      break;
    case MODEL_CLOSE:
      state = {
        ...state,
        model: false,
        rowdata: { id: 0 },
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default modelYear;
