import {
  GET_MASTERS_MENU,
  // ADD_MENU,
  // PUT_MENU,
  // DELETE_MENU,
  API_ERROR,
  GET_MASTERS_MENU_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  loginError: "aaa",
  message: null,
  loading: false,
  mastersMenuList: {},
};

const mastersMenu = (state = initialState, action) => {
  switch (action.type) {
    case GET_MASTERS_MENU:
      state = {
        ...state,
        // menu: action.payload,ssss
        loading: true,
      };
      break;

    case GET_MASTERS_MENU_SUCCESSFUL:
      // console.log("mastersMenuList", action.payload)
      state = {
        ...state,
        mastersMenuList: action.payload.Masters_Menu,

        loading: false,
      };

      break;

    // case ADD_MENU:
    //   state = {
    //     ...state,
    //     loading: false,
    //   };
    //   break;

    // case PUT_MENU:
    //   state = { ...state };
    //   break;

    // case DELETE_MENU:
    //   state = { ...state };
    //   break;

    case API_ERROR:
      state = {
        ...state,
        loading: false,
        loginError: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default mastersMenu;
