import React, { Component } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ButtonGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Label,
  UncontrolledPopover,
  // Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import classnames from "classnames";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";


// import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import CompanyDetails from "../CompanyDetails";
// import Users from "./Users";
// import Profile from "./Profile";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import "./SecurityDashboard.scss";

const optionUsers = [
  {
    options: [
      { label: "All Users", value: "All" },
      { label: "Nagendra Gopal", value: "01" },
    ],
  },
];

class SecurityDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Masters", link: "#" },
        { title: "Company Setting", link: "#" },
      ],
      activeTab: "1",
      isAlertOpen: false,
      selectedUsers: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.tog_static = this.tog_static.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }
  tog_static() {
    this.setState((prevState) => ({
      modal_static: !prevState.modal_static,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  handleSelectUsers = (selectedUsers) => {
    this.setState({ selectedUsers });
  };

  render() {
    const { selectedUsers } = this.state;
    const data = {
      columns: [
        {
          label: "USERS",
          field: "Users",
          sort: "asc",
          width: 150,
        },
        {
          label: "DEVICE",
          field: "Device",
          sort: "asc",
          width: 150,
        },
        {
          label: "LOCATION",
          field: "Location",
          sort: "asc",
          width: 150,
        },
        {
          label: "LOGIN",
          field: "Login",
          sort: "asc",
          width: 270,
        },
        {
          label: "LOGOUT",
          field: "Logout",
          sort: "asc",
          width: 200,
        },
      ],
      rows: [
        {
          Users: "Nagendra Gopal",
          Device: "Chrome, Windows",
          Location: "125.62.210.69",
          Login: "February 5, 2021 9:59 AM",
          Logout: "February 5, 2021 9:59 AM",
        },
        {
          Users: "Gopi",
          Device: "Chrome, Windows",
          Location: "125.62.210.69",
          Login: "February 5, 2021 9:59 AM",
          Logout: "February 5, 2021 9:59 AM",
        },
      ],
    };
    return (
      <React.Fragment>
        <Row className="no-gutters">
          <Col md={1}>
          <AdminSidebar />
          </Col>
          <Col md={11} className="pl-5">
            <div className="page-content">
              <Container fluid={true}>
                {/* <Breadcrumbs
              title="Company Setting"
              breadcrumbItems={this.state.breadcrumbItems}
            /> */}

                <Row>
                  <Col md={12}>
                    <Card>
                      <CardHeader className="bg-transparent border-bottom">
                        <h3 className="card-title-18 pt-2">
                          Security Dashboard
                        </h3>
                        <p className="card-title-desc">
                          An overview of access to your account, security events
                          and improvement suggestions
                        </p>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={6} className="h-100">
                            <div className="block-box">
                              <p className="card-title-desc">RIGHT NOW</p>
                              {/* <CardHeader className="bg-transparent border-bottom text-center">
                                <p className="card-title-desc">RIGHT NOW</p>
                              </CardHeader> */}
                              <div className="m-1 d-inline-flex ">
                                <Button
                                  type="button"
                                  color="light"
                                  id="popover1"
                                  className="waves-effect waves-light btn-box"
                                >
                                  Users Logged in <br />
                                  <samp className="font-size-24">1/</samp>
                                  <span>2</span>
                                </Button>
                                <UncontrolledPopover
                                  trigger="focus"
                                  target="popover1"
                                  placement="bottom"
                                >
                                  <PopoverHeader>LOGGED IN USERS</PopoverHeader>
                                  <PopoverBody>
                                    <div className="m-2">
                                      <i className="ri-user-line align-middle mr-2"></i>
                                      <span>Nagendra Gopal</span>{" "}
                                      <span className="ml-3">192.168.1.1</span>
                                    </div>
                                    <div className="m-2">
                                      <i className="ri-user-line align-middle mr-2"></i>
                                      <span>Nagendra Gopal</span>{" "}
                                      <span className="ml-3">192.168.1.1</span>
                                    </div>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </div>
                              <div className="m-1 d-inline-flex ">
                                <Button
                                  type="button"
                                  color="light"
                                  id="popoverloc"
                                  className="waves-effect waves-light btn-box"
                                >
                                  Unique locations
                                  <br />
                                  <samp className="font-size-24">1/</samp>
                                  <span>2</span>
                                </Button>
                                <UncontrolledPopover
                                  trigger="focus"
                                  target="popoverloc"
                                  placement="bottom"
                                >
                                  <PopoverHeader>
                                    Devices by location
                                  </PopoverHeader>
                                  <PopoverBody>
                                    <div className="m-2">
                                      <i className="ri-user-line align-middle mr-2"></i>
                                      <span>Nagendra Gopal</span>{" "}
                                      <span className="ml-3">192.168.1.1</span>
                                    </div>
                                    <div className="m-2">
                                      <i className="ri-user-line align-middle mr-2"></i>
                                      <span>Nagendra Gopal</span>{" "}
                                      <span className="ml-3">192.168.1.1</span>
                                    </div>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            {" "}
                            <div className=" ">
                              <p className="card-title-desc">LAST 7 DAYS</p>
                              {/* <CardHeader className="bg-transparent border-bottom text-center">
                                <p className="card-title-desc">RIGHT NOW</p>
                              </CardHeader> */}
                             
                               
                                  <div className="m-1 d-inline-flex ">
                                    <Button
                                      type="button"
                                      color="light"
                                      id="popoverdevices"
                                      className="waves-effect waves-light btn-box"
                                    >
                                      New Devices <br />
                                      <samp className="font-size-24">1</samp>
                                    </Button>
                                    <UncontrolledPopover
                                      trigger="focus"
                                      target="popoverdevices"
                                      placement="bottom"
                                    >
                                      <PopoverHeader>
                                        New devices used in the last 7 days
                                      </PopoverHeader>
                                      <PopoverBody>
                                        <div className="m-2">
                                          <i className="ri-smartphone-line align-middle mr-2"></i>
                                          <span> Chrome, Mobile</span>
                                        </div>
                                        <div className="m-2">
                                          <i className="ri-smartphone-line align-middle mr-2"></i>
                                          <span> Chrome, Mobile</span>
                                        </div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </div>
                             
                               
                                  <div className="m-1 d-inline-flex ">
                                    <Button
                                      type="button"
                                      color="light"
                                      id="popoverloc"
                                      className="waves-effect waves-light btn-box"
                                    >
                                      Security events
                                      <br />
                                      <samp className="font-size-24">1</samp>
                                    </Button>
                                    <UncontrolledPopover
                                      trigger="focus"
                                      target="popoverloc"
                                      placement="bottom"
                                    >
                                      <PopoverHeader>
                                        Devices by location
                                      </PopoverHeader>
                                      <PopoverBody>
                                        <div className="m-2">
                                          <i className="ri-user-line align-middle mr-2"></i>
                                          <span>Nagendra Gopal</span>{" "}
                                          <span className="ml-3">
                                            192.168.1.1
                                          </span>
                                        </div>
                                        <div className="m-2">
                                          <i className="ri-user-line align-middle mr-2"></i>
                                          <span>Nagendra Gopal</span>{" "}
                                          <span className="ml-3">
                                            192.168.1.1
                                          </span>
                                        </div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </div>
                              
                                
                                  <div className="m-1 d-inline-flex">
                                    <Button
                                      type="button"
                                      color="light"
                                      id="popoverdevices"
                                      className="waves-effect waves-light btn-box"
                                    >
                                      New locations <br />
                                      <samp className="font-size-24">1</samp>
                                    </Button>
                                    <UncontrolledPopover
                                      trigger="focus"
                                      target="popoverdevices"
                                      placement="bottom"
                                    >
                                      <PopoverHeader>
                                        New devices used in the last 7 days
                                      </PopoverHeader>
                                      <PopoverBody>
                                        <div className="m-2">
                                          <i className="ri-smartphone-line align-middle mr-2"></i>
                                          <span> Chrome, Mobile</span>
                                        </div>
                                        <div className="m-2">
                                          <i className="ri-smartphone-line align-middle mr-2"></i>
                                          <span> Chrome, Mobile</span>
                                        </div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </div>
                               
                             
                                  <div className="m-1 d-inline-flex">
                                    <Button
                                      type="button"
                                      color="light"
                                      id="popoverloc"
                                      className="waves-effect waves-light btn-box"
                                    >
                                      Force logged 
                                      <br />
                                      <samp className="font-size-24">1</samp>
                                    </Button>
                                    <UncontrolledPopover
                                      trigger="focus"
                                      target="popoverloc"
                                      placement="bottom"
                                    >
                                      <PopoverHeader>
                                        Devices by location
                                      </PopoverHeader>
                                      <PopoverBody>
                                        <div className="m-2">
                                          <i className="ri-user-line align-middle mr-2"></i>
                                          <span>Nagendra Gopal</span>{" "}
                                          <span className="ml-3">
                                            192.168.1.1
                                          </span>
                                        </div>
                                        <div className="m-2">
                                          <i className="ri-user-line align-middle mr-2"></i>
                                          <span>Nagendra Gopal</span>{" "}
                                          <span className="ml-3">
                                            192.168.1.1
                                          </span>
                                        </div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </div>
                               
                              
                            </div>
                          </Col>
                         
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      {/* <CardHeader className="bg-transparent border-bottom">
                    <h3 className="card-title pt-2">Users & Profile</h3>
                  </CardHeader> */}
                      <CardBody className="pt-0">
                        <Nav tabs className="nav-tabs-custom mb-4">
                          <NavItem>
                            <NavLink
                              onClick={() => {
                                this.toggleTab("1");
                              }}
                              className={classnames(
                                { active: this.state.activeTab === "1" },
                                "pt-3 font-weight-bold card-title-18"
                              )}
                            >
                              Active devices
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              onClick={() => {
                                this.toggleTab("2");
                              }}
                              className={classnames(
                                { active: this.state.activeTab === "2" },
                                "pt-3 font-weight-bold card-title-18"
                              )}
                            >
                              Device history
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              onClick={() => {
                                this.toggleTab("3");
                              }}
                              className={classnames(
                                { active: this.state.activeTab === "3" },
                                "pt-3 font-weight-bold card-title-18"
                              )}
                            >
                             Audit log
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId={"1"}>
                            <div className="mb-4">
                              {/* <h4 className="card-title-15">Device history</h4> */}
                              <div className="float-right font-size-18 ml-2">
                                <ButtonGroup className="mr-1 ">
                                  <Dropdown
                                    isOpen={this.state.btnAllUsers}
                                    toggle={() =>
                                      this.setState({
                                        btnAllUsers: !this.state.btnAllUsers,
                                      })
                                    }
                                  >
                                    <DropdownToggle caret color="light">
                                      <i className="mdi mdi-filter-variant mr-2"></i>
                                      All Users{" "}
                                      <i className="mdi mdi-chevron-down mr-2"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <Form className="p-1">
                                        <FormGroup className="m-0">
                                          <InputGroup>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search"
                                             
                                            />
                                            <InputGroupAddon addonType="append">
                                              <Button
                                                color="primary"
                                                type="submit"
                                              >
                                                <i className="ri-search-line"></i>
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </FormGroup>
                                      </Form>

                                      <DropdownItem header>
                                        All Users
                                      </DropdownItem>
                                      <DropdownItem text="true">
                                        <i className="ri-user-line align-middle mr-2"></i>
                                        Nagendra Gopal
                                      </DropdownItem>
                                      <DropdownItem text="true">
                                        <i className="ri-user-line align-middle mr-2"></i>
                                        Gopi
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </ButtonGroup>
                                <ButtonGroup className="mr-1">
                                  <Button
                                    type="button"
                                    color="light"
                                    className="waves-effect waves-light"
                                    onClick={this.tog_static}
                                  >
                                    Security Alerts
                                  </Button>
                                </ButtonGroup>
                                <ButtonGroup className="mr-1">
                                  <Dropdown
                                    isOpen={this.state.btnprimary1}
                                    toggle={() =>
                                      this.setState({
                                        btnprimary1: !this.state.btnprimary1,
                                      })
                                    }
                                  >
                                    <DropdownToggle caret color="light">
                                      <i className="mdi mdi-filter-variant "></i>

                                      {/* <i className="mdi mdi-chevron-down mr-2"></i> */}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem text="true">
                                        Log out all
                                      </DropdownItem>
                                      <DropdownItem divider />
                                      <DropdownItem text="true">
                                        Export as .csv
                                      </DropdownItem>
                                      <DropdownItem text="true">
                                        Export as .xls
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </ButtonGroup>
                              </div>

                              <p className="card-title-desc">
                                These are all the devices currently logged in by
                                your company's users. You can export filter
                                results and force log out devices.
                              </p>
                            </div>
                            <br />
                            <div className="mt-2">
                              <MDBDataTable
                                responsive
                                striped
                                bordered
                                data={data}
                              />
                            </div>
                          </TabPane>
                          <TabPane tabId={"2"}>
                            <div className="mb-4">
                              {/* <h4 className="card-title-15">Device history</h4> */}
                              <div className="float-right font-size-18 ml-2">
                                <ButtonGroup className="mr-1">
                                  <Dropdown
                                    isOpen={this.state.btnAllUsers}
                                    toggle={() =>
                                      this.setState({
                                        btnAllUsers: !this.state.btnAllUsers,
                                      })
                                    }
                                  >
                                    <DropdownToggle caret color="light">
                                      <i className="mdi mdi-filter-variant mr-2"></i>
                                      All Users{" "}
                                      <i className="mdi mdi-chevron-down mr-2"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <Form className="p-1">
                                        <FormGroup className="m-0">
                                          <InputGroup>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search"
                                            />
                                            <InputGroupAddon addonType="append">
                                              <Button
                                                color="primary"
                                                type="submit"
                                              >
                                                <i className="ri-search-line"></i>
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </FormGroup>
                                      </Form>

                                      <DropdownItem header>
                                        All Users
                                      </DropdownItem>
                                      <DropdownItem text="true">
                                        <i className="ri-user-line align-middle mr-2"></i>
                                        Nagendra Gopal
                                      </DropdownItem>
                                      <DropdownItem text="true">
                                        <i className="ri-user-line align-middle mr-2"></i>
                                        Gopi
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </ButtonGroup>
                                <ButtonGroup className="mr-1">
                                  <Dropdown
                                    isOpen={this.state.btncalendar}
                                    toggle={() =>
                                      this.setState({
                                        btncalendar: !this.state.btncalendar,
                                      })
                                    }
                                  >
                                    <DropdownToggle caret color="light">
                                      <i className="mdi mdi-calendar mr-2"></i>2
                                      Months
                                      <i className="mdi mdi-chevron-down mr-2"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      {/* <DropdownItem divider /> */}
                                      <DropdownItem text="true">7 Days</DropdownItem>
                                      <DropdownItem text="true">2 Weeks</DropdownItem>
                                      <DropdownItem text="true">1 Months</DropdownItem>
                                      <DropdownItem text="true">2 Months</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </ButtonGroup>
                                <ButtonGroup className="mr-1">
                                  <Dropdown
                                    isOpen={this.state.btnprimary1}
                                    toggle={() =>
                                      this.setState({
                                        btnprimary1: !this.state.btnprimary1,
                                      })
                                    }
                                  >
                                    <DropdownToggle caret color="light">
                                      <i className="mdi mdi-filter-variant"></i>
                                      {/* Export{" "}
                                      <i className="mdi mdi-chevron-down mr-2"></i> */}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      {/* <DropdownItem divider /> */}
                                      <DropdownItem text="true">
                                        Export as .csv
                                      </DropdownItem>
                                      <DropdownItem text="true">
                                        Export as .xls
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>{" "}
                                </ButtonGroup>
                              </div>

                              <p className="card-title-desc">
                                This is the device history of your company's
                                users. Use this list to see when, how and with
                                which devices your users have logged in during
                                the last 60 days. You can also export filter
                                results.
                              </p>
                            </div>
                            <br />
                            <div className="mt-2">
                              <MDBDataTable
                                responsive
                                striped
                                bordered
                                data={data}
                              />
                            </div>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Modal
                  isOpen={this.state.modal_static}
                  toggle={this.tog_static}
                  backdrop="static"
                  centered
                  size="lg"
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_static: false })}
                  >
                    Security Alert Message
                  </ModalHeader>
                  <ModalBody>
                    <AvForm onValidSubmit={this.addCustomer}>
                      <Row className="justify-content-center">
                        <Col md={8}>
                          <Alert
                            color="success"
                            isOpen={this.state.isAlertOpen}
                            toggle={() => this.setState({ isAlertOpen: false })}
                          >
                            Alert Message send Successfully...!
                          </Alert>
                          <FormGroup>
                            <Label className="Users-selection">
                              Select Users
                            </Label>
                            <Select
                              value={selectedUsers}
                              name="Role-selection"
                              onChange={this.handleSelectUsers}
                              options={optionUsers}
                              classNamePrefix="Users-selection"
                              id="Users-selection"
                              errorMessage=" Please provide a valid Users"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col md={8}>
                          <FormGroup>
                            <Label htmlFor="Alert">Alert Message</Label>
                            <AvField
                              name="Alert"
                              type="textarea"
                              className="form-control"
                              id="Alert"
                              placeholder="Enter Name"
                              errorMessage=" Please provide a valid Message"
                              validate={{ required: { value: true } }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6}></Col>
                      </Row>
                      <ModalFooter>
                        <Button
                          type="button"
                          color="light"
                          onClick={() => this.setState({ modal_static: false })}
                        >
                          Calcel
                        </Button>
                        <Button type="submit" color="primary">
                          Send Message
                        </Button>
                      </ModalFooter>
                    </AvForm>
                  </ModalBody>
                </Modal>
              </Container>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default SecurityDashboard;
