import React, { Component } from "react";
// Material  Items
import AddBrand from "../MastersDashboard/Brand/AddBrand";
import AddLocations from "../MastersDashboard/Locations/AddLocations";
import AddModelYear from "../MastersDashboard/ModelYears/AddModelYears";
import AddVehicleTypes from "../MastersDashboard/VehicleTypes/AddVehicleTypes";
import AddDealers from "../MastersDashboard/Dealers/AddDealers";
import AddVersions from "../MastersDashboard/Versions/AddVersions";
import AddVersionTypes from "../MastersDashboard/VersionTypes/AddVersionTypes";

class AllModels extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return (
      <React.Fragment>
        <AddBrand />
        <AddLocations />
        <AddModelYear />
        <AddVehicleTypes />
        <AddDealers/>
        <AddVersions/>
        <AddVersionTypes/>
      </React.Fragment>
    );
  }
}

export default AllModels;
