import {
  GET_MENU,
  ADD_MENU,
  PUT_MENU,
  DELETE_MENU,
  API_ERROR,
  GET_MENU_SUCCESSFUL,
} from "./actionTypes";

export const get_menu = (menu, history) => {
  return {
    type: GET_MENU,
    payload: { menu, history },
  };
};

export const get_menu_successful = (menu) => {
  return {
    type: GET_MENU_SUCCESSFUL,
    payload: { menu },
  };
};

export const add_menu = (menu) => {
  return {
    type: ADD_MENU,
    payload: menu,
  };
};

export const put_menu = (menu, history) => {
  return {
    type: PUT_MENU,
    payload: { menu, history },
  };
};

export const delete_menu = (menu) => {
  return {
    type: DELETE_MENU,
    payload: menu,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
