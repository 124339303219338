import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication Module
import Account from "./auth/register/reducer";
import Login from "./auth/login/reducer";
import Forget from "./auth/forgetpwd/reducer";
import menu from "./menu/reducer";
//Users
import Users from "./Users/reducer";

//Masters
import MastersMenu from './Masters/MastersMenu/reducer';
import Location from './Masters/Locations/reducer';
import Brand from './Masters/Brand/reducer';
import ModelYear from './Masters/ModelYears/reducer';
import VehicleTypes from './Masters/VehicleTypes/reducer';
import Dealers from './Masters/Dealers/reducer';
import Versions from './Masters/Versions/reducer';
import VersionTypes from './Masters/VersionTypes/reducer';
import VehicleDetails from './Masters/VehicleDetails/reducer';

const rootReducer = combineReducers({
  Layout,
  Account,
  Login,
  Forget,
  menu,
  
  //Users
  Users,

  //Masters
  MastersMenu,
  Location,
  Brand,
  ModelYear,
  VehicleTypes,
  Dealers,
  Versions,
  VersionTypes,
  VehicleDetails,

});

export default rootReducer;
