import React, { Component } from "react";
import {
  Container,
  Media,
  Card,
  // CardHeader,
  Button,
  CardBody,
  Row,
  Col,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
  // UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";

import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import Tooltip from "react-tooltip-lite";

import "./VehicleDashboard.scss";

//Import Images
import img1 from "../../assets/images/Products/Bike (1).jpg";
import img2 from "../../assets/images/Products/Bike (2).jpg";
import img3 from "../../assets/images/Products/Bike (3).jpg";
import img4 from "../../assets/images/Products/Bike (4).jpg";
import img5 from "../../assets/images/Products/Bike (1).jpg";
import img6 from "../../assets/images/Products/Bike (2).jpg";
import img7 from "../../assets/images/Products/Bike (3).jpg";
import img8 from "../../assets/images/Products/Bike (4).jpg";

class VehicleDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Vehicle: [
        {
          img: img1,
          title: "Benelli TRK 502",
          price: "76,000",
          road: "26000",
          cc: "155 CC",
          Dur: "2008",
          VehicleNumber: "AP39FR0000",
          Version: "R15 V3",
          Brand: "YAMAHA",
          Firm: "Gopal Finance",
          availZone: "Visakhapatnam",
          availCounter: "Visakhapatnam",
        },
        {
          img: img2,
          title: "Yamaha FZ S FI",
          price: "1,04,984",
          road: "26000",
          cc: "155 CC",
          Dur: "2014",
          VehicleNumber: "AP39FR0000",
          Version: "R15 V3",
          Brand: "YAMAHA",
          Firm: "Gopal Finance",
          availZone: "Visakhapatnam",
          availCounter: "Visakhapatnam",
        },
        {
          img: img3,
          title: "Royal Enfield Bullet 350",
          price: "1,27,093",
          road: "26000",
          cc: "155 CC",
          Dur: "2010",
          VehicleNumber: "AP39FR0000",
          Version: "R15 V3",
          Brand: "YAMAHA",
          Firm: "Gopal Krishna Finance",
          availZone: "Visakhapatnam",
          availCounter: "Visakhapatnam",
        },
        {
          img: img4,
          title: "Yamaha YZF R15 V3",
          price: "4,79,900",
          road: "26000",
          cc: "155 CC",
          Dur: "2005",
          VehicleNumber: "AP39FR0000",
          Version: "R15 V3",
          Brand: "YAMAHA",
          Firm: "Gopal Krishna Finance",
          availZone: "Visakhapatnam",
          availCounter: "Visakhapatnam",
        },
        {
          img: img5,
          title: "Benelli TRK 502",
          price: "76,000",
          road: "26000",
          cc: "155 CC",
          Dur: "2008",
          VehicleNumber: "AP39FR0000",
          Version: "R15 V3",
          Brand: "YAMAHA",
          Firm: "Gopal Finance",
          availZone: "Visakhapatnam",
          availCounter: "Visakhapatnam",
        },
        {
          img: img6,
          title: "Yamaha YZF R15 V3",
          price: "4,79,900",
          road: "26000",
          cc: "155 CC",
          Dur: "2005",
          VehicleNumber: "AP39FR0000",
          Version: "R15 V3",
          Brand: "YAMAHA",
          Firm: "Gopal Krishna Finance",
          availZone: "Visakhapatnam",
          availCounter: "Visakhapatnam",
        },
        {
          img: img7,
          name: "Keeney's",
          title: "Royal Enfield Bullet 350",
          price: "1,27,093",
          road: "26000",
          cc: "155 CC",
          Dur: "2010",
          VehicleNumber: "AP39FR0000",
          Version: "R15 V3",
          Brand: "YAMAHA",
          Firm: "Gopal Finance",
          availZone: "Visakhapatnam",
          availCounter: "Visakhapatnam",
        },
        {
          img: img8,
          title: "Yamaha YZF R15 V3",
          price: "4,79,900",
          road: "26000",
          cc: "155 CC",
          Dur: "2005",
          VehicleNumber: "AP39FR0000",
          Version: "R15 V3",
          Brand: "YAMAHA",
          Firm: "Gopal Finance",
          availZone: "Visakhapatnam",
          availCounter: "Visakhapatnam",
        },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row className="mb-0 pb-0">
              <Col className="pb-1">
                <Card className="p-1">
                  <CardBody>
                    <h5 className="card-title-18 pt-2 d-inline-block">
                      Vehicle Dashboard
                    </h5>
                    <div className="float-right">
                      <Dropdown
                        isOpen={this.state.isSocialPf}
                        toggle={() =>
                          this.setState({ isSocialPf: !this.state.isSocialPf })
                        }
                        className="d-lg-inline-block"
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-light mr-2"
                          // className="btn header-item noti-icon waves-effect"
                        >
                          <i className="mdi mdi-filter mr-1"></i>
                          Filters
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-lg" right>
                          <div className="px-lg-2">
                            <AvForm>
                              <Row
                              // className="no-gutters"
                              >
                                <Col md={6} className="mb-0">
                                  <FormGroup className="mb-0">
                                    <Label
                                      htmlFor="validationFromDate"
                                      className="mb-0 col-form-label"
                                    >
                                      From Date:
                                    </Label>

                                    <Input
                                      name="Fdate"
                                      placeholder="From Date"
                                      type="date"
                                      errormessage="Select From Date "
                                      className="form-control"
                                      validate={{ required: { value: true } }}
                                      id="validationFromDate"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-0">
                                  <FormGroup className="mb-0">
                                    <Label
                                      htmlFor="validationToDate"
                                      className="mb-0 col-form-label"
                                    >
                                      To Date:
                                    </Label>

                                    <Input
                                      name="Tdate"
                                      placeholder="To Date"
                                      type="date"
                                      errormessage="Select To Date "
                                      className="mb-0 form-control"
                                      validate={{ required: { value: true } }}
                                      id="validationToDate"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="mb-0">
                                    <Label
                                      htmlFor="Location-selection"
                                      className="col-form-label"
                                    >
                                      Locations :
                                    </Label>

                                    <Select
                                      //   options={this.props.locationslist.map(
                                      //     (item, index) => ({
                                      //       label: item.name,
                                      //       value: item.id,
                                      //     })
                                      //   )}
                                      //   value={{
                                      //     label: this.state.location_name,
                                      //     value: this.state.location_id,
                                      //   }}
                                      //   onChange={(e) => {
                                      //     this.setState({
                                      //       location_name: e.label,
                                      //       location_id: e.value,
                                      //     });
                                      //     this.setLocValues(e.label, e.value);
                                      //   }}
                                      name="location-selection"
                                      classNamePrefix="Location-selection"
                                      id="Location-selection"
                                      errormessage=" Please provide a valid Location"
                                      validate={{ required: { value: true } }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup className="mb-0">
                                    <Label
                                      htmlFor="Department-selection"
                                      className="col-form-label"
                                    >
                                      Department :
                                    </Label>

                                    <Select
                                      //   options={this.props.locationslist.map(
                                      //     (item, index) => ({
                                      //       label: item.name,
                                      //       value: item.id,
                                      //     })
                                      //   )}
                                      //   value={{
                                      //     label: this.state.location_name,
                                      //     value: this.state.location_id,
                                      //   }}
                                      //   onChange={(e) => {
                                      //     this.setState({
                                      //       location_name: e.label,
                                      //       location_id: e.value,
                                      //     });
                                      //     this.setLocValues(e.label, e.value);
                                      //   }}
                                      name="Department-selection"
                                      classNamePrefix="Department-selection"
                                      id="Location-selection"
                                      errormessage=" Please provide a valid Location"
                                      validate={{ required: { value: true } }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <div className="float-right pt-1">
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="waves-effect waves-light"
                                    >
                                      Apply
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </AvForm>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        type="button"
                        color="primary"
                        className="waves-effect waves-light"
                        href="#"
                      >
                        New Vehicle
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="pt-0 mt-0">
              {this.state.Vehicle.map((item, key) => (
                <Col xl={3} sm={6} key={key}>
                  <Card className="inner-box">
                    <CardBody className="p-0">
                      <div className="image">
                        <Link to="#">
                          <img
                            src={item.img}
                            alt=""
                            className="mt-2 mb-4    "
                          />
                        </Link>
                        <div className="price">&#x20b9; {item.price}</div>
                      </div>
                      <Media body>
                        <h3>
                          <Link to="#">{item.title}</Link>
                        </h3>

                        <Row className="pt-0">
                          <Col className="pt-0">
                            <div className="vehicle-list-column">
                              <ul className="vehicle-list-style p-2">
                                <li>
                                  <span className="ttl">Vehicle Number</span>
                                  <span className="dtl">
                                    {item.VehicleNumber}
                                  </span>
                                </li>
                                <li>
                                  <span className="ttl">Version Type</span>
                                  <span className="dtl">{item.Version}</span>
                                </li>
                                <li>
                                  <span className="ttl">Brand</span>
                                  <span className="dtl">{item.Brand}</span>
                                </li>
                                <li>
                                  <span className="ttl">Firm</span>
                                  <span className="dtl" id="firmTooltip">
                                    {item.Firm.length > 14 ? (
                                      <Tooltip
                                        content={item.Firm}
                                        tagName="firmTooltip"
                                        direction="right"
                                      >
                                        {item.Firm.substring(0, 14 - 3) + "..."}
                                      </Tooltip>
                                    ) : (
                                      item.Firm
                                    )}
                                  </span>
                                </li>
                                <li>
                                  <span className="ttl">Aval Zone</span>
                                  <span className="dtl" id="availZoneTooltip">
                                    {item.availZone.length > 12 ? (
                                      <Tooltip
                                        content={item.availZone}
                                        tagName="availZoneTooltip"
                                        direction="right"
                                      >
                                        {item.availZone.substring(0, 14 - 3) +
                                          "..."}
                                      </Tooltip>
                                    ) : (
                                      item.availZone
                                    )}
                                  </span>
                                </li>
                                <li>
                                  <span className="ttl">
                                  Aval Counter
                                  </span>
                                  <span className="dtl" id="availCounterTooltip">
                                  {item.availCounter.length > 12 ? (
                                      <Tooltip
                                        content={item.availCounter}
                                        tagName="availCounterTooltip"
                                        direction="right"
                                      >
                                        {item.availCounter.substring(0, 14 - 3) +
                                          "..."}
                                      </Tooltip>
                                    ) : (
                                      item.availCounter
                                    )}


                                   
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Media>
                      <div className="lower-box">
                        <Row>
                          <Col
                            xs={4}
                            sm={4}
                            md={4}
                            xl={4}
                            className="lower-box-border"
                          >
                            <div className="car-info">
                              <i className="mdi mdi-road-variant"></i>
                              <span className="ml-1">{item.road}</span>
                            </div>
                          </Col>
                          <Col
                            xs={4}
                            sm={4}
                            md={4}
                            xl={4}
                            className="lower-box-border"
                          >
                            <div className="car-info">
                              <i className="ri-e-bike-fill"></i>
                              <span className="ml-1">{item.cc}</span>
                            </div>
                          </Col>
                          <Col
                            xs={4}
                            sm={4}
                            md={4}
                            xl={4}
                            className="lower-box-border last-child"
                          >
                            <div className="car-info ">
                              <i className="mdi mdi-clock"></i>
                              <span className="ml-1">{item.Dur}</span>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      {/* <div className="text-center">
                        <div>
                          <img
                            src={Vehicle.img}
                            alt=""
                            className="avatar-lg mt-2 mb-2"
                          />
                        </div>
                        <Media body>
                          <h5 className="text-truncate">
                            <Link to="#" className="text-dark">
                              {Vehicle.name}
                            </Link>
                          </h5>
                          <p className="text-muted">
                            <i className="mdi mdi-account mr-1"></i>{" "}
                            {Vehicle.owner}
                          </p>
                        </Media>
                      </div>
                      <hr className="my-4" />
                      <Row className="text-center">
                        <Col xs={6}>
                          <p className="text-muted mb-2">Products</p>
                          <h5>{Vehicle.products}</h5>
                        </Col>
                        <Col xs={6}>
                          <p className="text-muted mb-2">Wallet Balance</p>
                          <h5>${Vehicle.balance}</h5>
                        </Col>
                      </Row> */}
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>

            <Row>
              <Col xl={12}>
                <div className="text-center my-3">
                  <Link to="#" className="text-primary">
                    <i className="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2"></i>{" "}
                    Load more{" "}
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default VehicleDashboard;
