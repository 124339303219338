import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Button,
  Label,
  // Input,
  Container,
  // CustomInput,
  // InputGroup,
  // Form,
  // InputGroupAddon,
} from "reactstrap";
import AdminSidebar from "./AdminSidebar/AdminSidebar";
import { AvForm, AvField } from "availity-reactstrap-validation";

class EmailSMS extends Component {
  render() {
    return (
      <React.Fragment>
        <Row className="no-gutters">
          <Col md={1}>
          <AdminSidebar />
          </Col>
          <Col md={11} className="pl-5">
            <div className="page-content">
              <Container fluid={true}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <h3 className="card-title-18 pt-2">Email & SMS</h3>
                  </CardHeader>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row>
                        <Col md={12}>
                          <h3 className="card-title-15 pt-2">Email Settings</h3>
                          <hr className="my-1" />
                        </Col>
                      </Row>
                      <Row className="justify-content-center mt-2">
                        <Col md="9">
                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationEmail"
                                className="col-form-label float-right"
                              >
                                Email Protocol :
                              </Label>
                            </Col>
                            <Col md={9}>
                              <AvField
                                name="Email"
                                placeholder="Email"
                                type="select"
                                errorMessage="Select Email Protocol"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationEmail"
                              >
                                <option>PHP Mail Funcation</option>
                                <option>SMTP</option>
                              </AvField>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <h3 className="card-title-15 pt-2">SMS Settings</h3>
                          <hr className="my-1" />
                        </Col>
                      </Row>
                      <Row className="justify-content-center mt-2">
                        <Col md="9">
                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationSMS"
                                className="col-form-label float-right"
                              >
                                Send SMS :
                              </Label>
                            </Col>
                            <Col md={9}>
                              <AvField
                                name="SendSMS"
                                placeholder="Send SMS"
                                type="select"
                                errorMessage="Select SMS "
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationSMS"
                              >
                                <option>Yes</option>
                                <option>No</option>
                              </AvField>
                            </Col>
                          </FormGroup>
                        </Col>

                        <Col md="9">
                          <FormGroup row>
                            <Col md={3}>
                              <Label
                                htmlFor="validationSMSPath"
                                className="col-form-label float-right"
                              >
                                SMS Path :
                              </Label>
                            </Col>
                            <Col md={9}>
                              <AvField
                                name="SMSPath"
                                placeholder="SMS Path"
                                type="text"
                                errorMessage="Enter SMS Path"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationSMSPath"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Button
                        color="primary"
                        type="submit"
                        className="float-right"
                      >
                        Save
                      </Button>
                    </AvForm>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default EmailSMS;
