import {
  API_ERROR,
  VEHICLE_DETAILS,
  ROW_VEHICLE_DETAILS,
  VEHICLE_DETAILS_LIST_SUCCESSFUL,
  ROW_VEHICLE_DETAILS_LIST_SUCCESSFUL,
  VEHICLE_DETAILS_ADD,
  VEHICLE_DETAILS_ADD_SUCCESSFUL,
  VEHICLE_DETAILS_EDIT,
  VEHICLE_DETAILS_EDIT_SUCCESSFUL,
  VEHICLE_DETAILS_DELETE,
  VEHICLE_DETAILS_CHANGEVALUE,
  VEHICLE_DETAILS_SELECT_CHANGEVALUE,
  // VEHICLE_DETAILS_SETROWDATA,
  // MODEL_OPEN,
  // MODEL_CLOSE,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  VehicleDetailsID: 0,
  VehicleDetailslist: [],
  RowVehicleDetailslist: [],
  model: false,
  error_msg: null,
  rowdata: {},
};

const VehicleDetails = (state = initialState, action) => {
  switch (action.type) {
    case VEHICLE_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VEHICLE_DETAILS_LIST_SUCCESSFUL:
      state = {
        ...state,
        VehicleDetailslist: action.payload.data,
        loading: false,
      };
      break;
    case ROW_VEHICLE_DETAILS:
      state = {
        ...state,
        loading: false,
        // VehicleDetailsID: action.payload,
      };
      break;
    case ROW_VEHICLE_DETAILS_LIST_SUCCESSFUL:
      console.log("roedata in reducer : ",action.payload.data)
      state = {
        ...state,
        rowdata: action.payload.data,
        loading: false,
      };
      break;
    case VEHICLE_DETAILS_ADD:
      state = {
        ...state,
        loading: false,
        // rowdata: {
        //   ...state.rowdata,
        //   [action.payload.data.key] : [action.payload.data.value]
        //  },
      };
      break;
    case VEHICLE_DETAILS_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
      };
      break;
    case VEHICLE_DETAILS_EDIT:
      state = {
        ...state,
        model: true,
        rowdata: action.payload,
      };
      break;
    case VEHICLE_DETAILS_EDIT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case VEHICLE_DETAILS_DELETE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case VEHICLE_DETAILS_CHANGEVALUE:
      state = {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
          // [e.target.name]: e.target.value
        },
      };
      break;
    case VEHICLE_DETAILS_SELECT_CHANGEVALUE:
      state = {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.label,
          [action.payload.key2]: action.payload.value,
          // [e.target.name]: e.target.value
        },
      };
      break;

    // case VEHICLE_DETAILS_SETROWDATA:
    //   state = {
    //     ...state,
    //     rowdata: action.payload,
    //     // model: true,
    //   };
    // break;
    // case MODEL_OPEN:
    //   state = {
    //     ...state,
    //     model: true,
    //   };
    //   break;
    // case MODEL_CLOSE:
    //   state = {
    //     ...state,
    //     model: false,
    //     rowdata: { id: 0 },
    //   };
    //   break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VehicleDetails;
