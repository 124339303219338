import { all } from "redux-saga/effects";

//public
import accountSaga from "./auth/register/saga";
import loginSaga from "./auth/login/saga";
import forgetSaga from "./auth/forgetpwd/saga";
import LayoutSaga from "./layout/saga";
import menuSaga from "./menu/saga";
//Users
import UsersSaga from "./Users/saga";

//Masters
import MastersMenuSaga from "./Masters/MastersMenu/saga";
import LocationSaga from './Masters/Locations/saga';
import BrandSaga from './Masters/Brand/saga';
import ModelYearSaga from './Masters/ModelYears/saga';
import VehicleTypesSaga from './Masters/VehicleTypes/saga';
import DealersSaga from './Masters/Dealers/saga';
import VersionsSaga from './Masters/Versions/saga';
import VersionTypesSaga from './Masters/VersionTypes/saga';
import VehicleDetailsSaga from './Masters/VehicleDetails/saga';

export default function* rootSaga() {
  yield all([
    accountSaga(),
    loginSaga(),
    forgetSaga(),
    LayoutSaga(),
    menuSaga(),
    //Users
    UsersSaga(),

    //Masters
    MastersMenuSaga(),
    LocationSaga(),
    BrandSaga(),
    ModelYearSaga(),
    VehicleTypesSaga(),
    DealersSaga(),
    VersionsSaga(),
    VersionTypesSaga(),
    VehicleDetailsSaga(),
    
  ]);
}
