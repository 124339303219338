import {
  API_ERROR,
  USERS_LIST,
  USERS_LIST_SUCCESS,
//   MODEL_OPEN,
//   MODEL_CLOSE,
//   USER_ADD,
//   USER_ADD_SUCCESSFUL,
//   GROUPS,
//   GROUPS_LIST_SUCCESS,
//   GROUP_ADD,
//   GROUP_ADD_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  userRowList: [],
//   groupslist: [],
//   model: false,
//   error_msg: null,
//   rowdata: {},
//   groupdata: {},
};

const UserList = (state = initialState, action) => {
  switch (action.type) {
    case USERS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case USERS_LIST_SUCCESS:
        console.log("userRowList",action.payload);
      state = {
        ...state,
        userRowList: action.payload,
       
        loading: false,
      };
     
      break;
    // case MODEL_OPEN:
    //   state = {
    //     ...state,
    //     model: true,
    //   };
    //   break;
    // case MODEL_CLOSE:
    //   state = {
    //     ...state,
    //     model: false,
    //   };
    //   break;
    // case USER_ADD:
    //   state = {
    //     ...state,
    //     loading: false,
    //   };
    //   break;
    // case USER_ADD_SUCCESSFUL:
    //   state = {
    //     ...state,
    //     message: action.payload,
    //     loading: false,
    //     model: false,
    //   };
    //   break;
    // case GROUPS:
    //   state = {
    //     ...state,
    //     loading: true,
    //   };
    //   break;
    // case GROUPS_LIST_SUCCESS:
    //   state = {
    //     ...state,
    //     groupslist: action.payload.response.data,
    //     loading: false,
    //   };
    //   break;
    // case GROUP_ADD:
    //   state = {
    //     ...state,
    //     loading: false,
    //   };
    //   break;
    // case GROUP_ADD_SUCCESSFUL:
    //   state = {
    //     ...state,
    //     message: action.payload,
    //     loading: false,
    //   };
    //   break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserList;
