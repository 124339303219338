import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  // FormGroup,
  // Button,
  ButtonGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  // Label,
  // Input,
  Container,
  // CustomInput,
  // InputGroup,
  // Form,
  // InputGroupAddon,
} from "reactstrap";
import { Link } from "react-router-dom";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss";

import AdminSidebar from "../AdminSidebar/AdminSidebar";
import "./yourdevices.scss";
// import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

class Yourdevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arr: [
        {
          id: 1,
          icon: "ri-computer-line",
          title: "Chrome, Windows",
          ip: "125.62.210.69",
          date: "February 5, 2021 9:59 AM",
          titlecol: false,
        },
        {
          id: 2,
          icon: "ri-smartphone-line",
          title: "Chrome, Mobile",
          ip: "165.62.110.69",
          date: "February 5, 2021 9:59 AM",
          titlecol: false,
        },
      ],
    };

    this.t_col1 = this.t_col1.bind(this);
  }

  t_col1(item) {
    var arr = this.state.arr.map((i, idx) => {
      if (i.id === item.id) {
        if (i.titlecol === true) {
          i.titlecol = !i.titlecol;
        } else {
          i.titlecol = !i.titlecol;
        }
      } else {
        i.titlecol = false;
      }

      return i;
    });
    this.setState({ arr: arr });
  }

  render() {
    const data = {
      columns: [
        {
          label: "DEVICE",
          field: "Device",
          sort: "asc",
          width: 150,
        },
        {
          label: "LOCATION",
          field: "Location",
          sort: "asc",
          width: 150,
        },
        {
          label: "LOGIN",
          field: "Login",
          sort: "asc",
          width: 270,
        },
        {
          label: "LOGOUT",
          field: "Logout",
          sort: "asc",
          width: 200,
        },
      ],
      rows: [
        {
          Device: "Chrome, Windows",
          Location: "125.62.210.69",
          Login: "February 5, 2021 9:59 AM",
          Logout: "February 5, 2021 9:59 AM",
        },
        {
          Device: "Chrome, Windows",
          Location: "125.62.210.69",
          Login: "February 5, 2021 9:59 AM",
          Logout: "February 5, 2021 9:59 AM",
        },
      ],
    };
    return (
      <React.Fragment>
        <Row className="no-gutters">
          <Col md={1}>
          <AdminSidebar />
          </Col>
          <Col md={11} className="pl-5">
            <div className="page-content">
              <Container fluid={true}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <h3 className="card-title-18 pt-2">Your Devices</h3>
                  </CardHeader>
                  <CardBody>
                    <h4 className="card-title-15">Active devices</h4>
                    <p className="card-title-desc">
                      Here are the devices currently logged in to your account,
                      ordered by login time.
                    </p>

                    <div id="accordion">
                      {this.state.arr.map((item) => (
                        <Card className="mb-1"  key={item.id}>
                          <Link
                            to="#"
                            onClick={() => this.t_col1(item)}
                            style={{ cursor: "pointer" }}
                            className="text-dark"
                          >
                            <CardHeader className="bg-transparent border-bottom ">
                              <div className="m-0 Header">
                                <Row>
                                  <Col md={4}>
                                    <div className="title mt-2">
                                      <i
                                        className={item.icon + " font-size-22"}
                                      ></i>
                                      <span className="ml-2 font-size-18">
                                        {item.title}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="title mt-2">
                                      <span className="ml-3 font-size-16 ">
                                        {item.id}
                                      </span>
                                      <span className="ml-3 font-size-16">
                                        {item.date}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col md={2}>
                                    <div className="device-pill mt-2">
                                      <span className="device-pill-label">
                                        This device
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                {/* </div> */}
                              </div>
                            </CardHeader>
                          </Link>
                          <Collapse isOpen={item.titlecol}>
                            <CardBody className="device-body">
                              <Row className="justify-content-center">
                                <Col md={6}>
                                  <Row>
                                    <Col md={6}>
                                      <span className="float-right">
                                        IP address
                                      </span>
                                    </Col>
                                    <Col md={6}>
                                      <span>{item.id}</span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={6}>
                                      <span className="float-right">
                                        Login method
                                      </span>
                                    </Col>
                                    <Col md={6}>
                                      <span>Password</span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={6}>
                                      <span className="float-right">
                                        Remember login
                                      </span>
                                    </Col>
                                    <Col md={6}>
                                      <span>No</span>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={6}></Col>
                              </Row>
                            </CardBody>
                          </Collapse>
                        </Card>
                      ))}
                      <p className="card-title-desc mt-2">
                        If you notice any suspicious logins, we recommend
                        logging the suspicious device out, enabling two-factor
                        authentication, and changing the password to something
                        more secure.
                        <br />
                        For security purposes, this information is also shown to
                        other admins on Security Dashboard.
                      </p>
                      <hr />
                    </div>
                    <div id="accordion">
                      <div className="float-right ml-3">
                        <ButtonGroup className="mr-1 mt-2">
                          <Dropdown
                            isOpen={this.state.btnprimary1}
                            toggle={() =>
                              this.setState({
                                btnprimary1: !this.state.btnprimary1,
                              })
                            }
                          >
                            <DropdownToggle caret color="light" size="sm">
                              Export <i className="mdi mdi-chevron-down"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem>Export as .csv</DropdownItem>
                              <DropdownItem>Export as .xls</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>{" "}
                        </ButtonGroup>
                      </div>
                      <h4 className="card-title-15">Device history</h4>
                      <p className="card-title-desc">
                        This is your device history. You can use this list to
                        see how, when, and by which devices your account has
                        been accessed in the last 60 days.
                      </p>
                      <MDBDataTable responsive striped bordered data={data} />
                    </div>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Yourdevices;
