import React, { Component } from "react";
// import { FormGroup, Label, Input } from "reactstrap";
import {
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // Row,
  // Col,
  Media,
} from "reactstrap";
import { connect } from "react-redux";
import {
  hideRightSidebar,
  // changeLayout,
  // changeLayoutWidth,
  // changeSidebarTheme,
  // changeSidebarType, changePreloader,
  // changeTopbarTheme
} from "../../store/actions";


//SimpleBar
import SimpleBar from "simplebar-react";



import { Link } from "react-router-dom";

import "./rightbar.scss";
//Import images
// import layout1 from "../../assets/images/layouts/layout-1.jpg";
// import layout2 from "../../assets/images/layouts/layout-2.jpg";
// import layout3 from "../../assets/images/layouts/layout-3.jpg";

class RightSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [
        {
          id:"01",
          title: "Your order is placed",
          link: "#",
        },
        {
          id:"02",
          title: "Your order is placed",
          link: "#",
        },
        {
          id:"03",
          title: "Your order is placed",
          link: "#",
        },
       
      ],
      // layoutType: this.props.layoutType,
      // sidebarType: this.props.leftSideBarType,
      // layoutWidth: this.props.layoutWidth,
      // sidebarTheme: this.props.leftSideBarTheme,
      // topbarTheme: this.props.topbarTheme,
    };
    this.hideRightbar = this.hideRightbar.bind(this);
    // this.changeLayout = this.changeLayout.bind(this);
    // this.changeLayoutWidth = this.changeLayoutWidth.bind(this);
    // this.changeLeftSidebarTheme = this.changeLeftSidebarTheme.bind(this);
    // this.changeLeftSidebarType = this.changeLeftSidebarType.bind(this);
    // this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
    // this.changeThemePreloader = this.changeThemePreloader.bind(this);
  }

  /**
   * Hides the right sidebar
   */
  hideRightbar(e) {
    e.preventDefault();
    this.props.hideRightSidebar();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps !== this.props) {
  //     this.setState({

  //       layoutType: this.props.layoutType,
  //       sidebarType: this.props.leftSideBarType,
  //       layoutWidth: this.props.layoutWidth,
  //       sidebarTheme: this.props.leftSideBarTheme,
  //       topbarTheme: this.props.topbarTheme
  //     });
  //   }
  // }

  // changeThemePreloader = () => {
  //   this.props.changePreloader(!this.props.isPreloader);
  // }
  // /**
  //  * Change the layout
  //  * @param {*} e
  //  */
  // changeLayout(e) {
  //   if (e.target.checked) {
  //     this.props.changeLayout(e.target.value);
  //   }
  // }

  // /**
  //  * Changes layout width
  //  * @param {*} e
  //  */
  // changeLayoutWidth(e) {
  //   if (e.target.checked) {
  //     this.props.changeLayoutWidth(e.target.value);
  //   }
  // }

  // // change left sidebar design
  // changeLeftSidebarType(e) {
  //   if (e.target.checked) {
  //     this.props.changeSidebarType(e.target.value);
  //   }
  // }

  // // change left sidebar theme
  // changeLeftSidebarTheme(e) {
  //   if (e.target.checked) {
  //     this.props.changeSidebarTheme(e.target.value);
  //   }
  // }

  // // change topbar theme
  // changeTopbarTheme(e) {
  //   if (e.target.checked) {
  //     this.props.changeTopbarTheme(e.target.value);
  //   }
  // }

  render() {
    return (
      <React.Fragment>
        <div className="right-bar">
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={this.hideRightbar}
                className="right-bar-toggle float-right"
              >
                <i className="mdi mdi-close noti-icon"></i>
              </Link>
              <h5 className="m-0">Notifications</h5>
            </div>
            <hr className="my-0" />
            <SimpleBar style={{ maxHeight: "100%" }}>
              <div className="p-1">
                {this.state.reports.map((report) => (
                  <Link key={report.id} to={report.link} className="text-reset notification-item">
                    <Media>
                      <div className="avatar-xs mr-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="ri-shopping-cart-line"></i>
                        </span>
                      </div>
                      <Media body>
                        <h6 className="mt-0 mb-1">{report.title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">
                            If several languages coalesce the grammar
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i> 3 min ago
                          </p>
                        </div>
                      </Media>
                    </Media>
                  </Link>
                  // <hr className="my-0" />
                ))}
              </div>
            </SimpleBar>
          </div>
        </div>

        <div className="rightbar-overlay" onClick={this.hideRightbar}>
          {" "}
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  hideRightSidebar,
})(RightSidebar);
