import React, { Component } from "react";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  Button,
  Label,
  Input,
} from "reactstrap";
import "./VehicleDetails.scss";
import Tooltip from "react-tooltip-lite";

import {
  getVehicleDetailsList,
  getRowVehicleDetailsList,
  // VehicleDetailsAdd,
  // VehicleDetailsEdit,
  // VehicleDetailsDelete,
  // model_open,
  // model_close,
} from "../../../store/Masters/VehicleDetails/actions";

class ViewVehicleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };

    // this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  componentDidMount() {
    this.props.getRowVehicleDetailsList(this.props.match.params.id);
  }

  render() {
    const {
      match: { params },
    } = this.props;
    var VehicleData = this.props.rowdata;
    console.log("getVehicleDetailsList", this.props.rowdata);
   
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <Link to="/VehicleDetails">
                      <i className="ri-arrow-go-back-fill font-size-18"></i>
                    </Link>
                    <h5 className="card-title-18 d-inline-block ml-2">
                      Vehicle Details Of ( {VehicleData.name} )
                    </h5>

                    {/* <div className="float-right">
                      <Button
                        type="button"
                        color="light"
                        className="waves-effect waves-light"
                        href="/AddVehicleDetails"
                        // onClick={() => this.props.model_open()}
                      >
                        Back
                      </Button>
                    </div> */}
                  </CardHeader>
                  <CardBody>
                    <AvForm
                      className="form-horizontal"
                      // onValidSubmit={this.handleValidSubmit}
                    >
                      <Row className="justify-content-center">
                        <Col md={11}>
                          <h4 className="card-title-18">Vehicle Details</h4>
                          <Row className="justify-content-center">
                            <Col md={10}>
                              <div className="VehicleDetails-panel-content">
                                <div className="list-column">
                                  <Row className="justify-content-center">
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">Brand:</span>
                                          <span className="dtl">
                                            {VehicleData.brandLabel}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">Version:</span>
                                          <span className="dtl">
                                            {VehicleData.versionlabel}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Model Year:
                                          </span>
                                          <span className="dtl">
                                            {VehicleData.modelyearlabel}
                                          </span>
                                        </li>
                                      </ul>
                                    </Col>
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Vehicle Type:
                                          </span>
                                          <span className="dtl">
                                            {VehicleData.vehicletypelabel}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Version Types:
                                          </span>
                                          <span className="dtl">
                                            {VehicleData.versionlabel}
                                          </span>
                                        </li>
                                      </ul>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={11}>
                          <h4 className="card-title-18">Engine Details</h4>
                          <Row className="justify-content-center">
                            <Col md={10}>
                              <div className="VehicleDetails-panel-content">
                                <div className="list-column">
                                  <Row className="justify-content-center">
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Engine Type:
                                          </span>
                                          <span className="dtl">
                                          {VehicleData.Enginetype && VehicleData.Enginetype.length > 25 ? (
                                            <Tooltip
                                              content={VehicleData.Enginetype}
                                              tagName="firmTooltip"
                                              direction="right"
                                            >
                                              {VehicleData.Enginetype.substring(
                                                0,
                                                25 - 3
                                              ) + "..."}
                                            </Tooltip>
                                          ) : (
                                            VehicleData.Enginetype
                                          )}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Clutch Type:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.Clutchtype}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Transmission type:
                                          </span>
                                          <span className="dtl">
                                            {VehicleData.Transmissiontype && VehicleData.Transmissiontype.length > 20 ? (
                                              <Tooltip
                                                content={VehicleData.Transmissiontype}
                                                tagName="firmTooltip"
                                                direction="right"
                                              >
                                                {VehicleData.Transmissiontype.substring(
                                                  0,
                                                  20 - 3
                                                ) + "..."}
                                              </Tooltip>
                                            ) : (
                                              VehicleData.Transmissiontype
                                            )}

                                           
                                          </span>
                                        </li>
                                      </ul>
                                    </Col>
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Displacement:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.Displacement}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Fuel system:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.Fuelsystem}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Starting system type:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.Startingsystem}
                                          </span>
                                        </li>
                                      </ul>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>{" "}
                        <Col md={11}>
                          <h4 className="card-title-18">Dimensions Details</h4>
                          <Row className="justify-content-center">
                            <Col md={10}>
                              <div className="VehicleDetails-panel-content">
                                <div className="list-column">
                                  <Row className="justify-content-center">
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Overall Length:
                                          </span>
                                          <span className="dtl">{VehicleData.Overalllength}</span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Overall Height:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.Overallheight}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Seat Height:
                                          </span>
                                          <span className="dtl">
                                            {VehicleData.Seatheight}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Fuel tank capacity:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.Fueltank}
                                          </span>
                                        </li>
                                      </ul>
                                    </Col>
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Overall width:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.Overallwidth}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Min ground clearance:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.GroundClearance}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Wheelbase:
                                          </span>
                                          <span className="dtl">{VehicleData.Wheelbase}</span>
                                        </li>
                                      </ul>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={11}>
                          <h4 className="card-title-18">Chassis Details</h4>
                          <Row className="justify-content-center">
                            <Col md={10}>
                              <div className="VehicleDetails-panel-content">
                                <div className="list-column">
                                  <Row className="justify-content-center">
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Frame Type:
                                          </span>
                                          <span className="dtl">{VehicleData.Frametype}</span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Brake type (Rear):
                                          </span>
                                          <span className="dtl">
                                         {VehicleData.BraketypeRear}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Tyre size (Front):
                                          </span>
                                          <span className="dtl">
                                            {VehicleData.TyresizeFront && VehicleData.TyresizeFront.length > 20 ? (
                                              <Tooltip
                                                content={VehicleData.TyresizeFront}
                                                tagName="firmTooltip"
                                                direction="right"
                                              >
                                                {VehicleData.TyresizeFront.substring(0, 20 - 3) +
                                                  "..."}
                                              </Tooltip>
                                            ) : (
                                              VehicleData.TyresizeFront
                                            )}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">ABS:</span>
                                          <span className="dtl">
                                           {VehicleData.ABS}
                                          </span>
                                        </li>
                                      </ul>
                                    </Col>
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Brake type (Front):
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.BraketypeFront}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Suspension type (front/rear)
                                          </span>
                                          <span className="dtl">
                                            {VehicleData.SuspensionType && VehicleData.SuspensionType.length > 20 ? (
                                              <Tooltip
                                                content={VehicleData.SuspensionType}
                                                tagName="firmTooltip"
                                                direction="right"
                                              >
                                                {VehicleData.SuspensionType.substring(
                                                  0,
                                                  20 - 3
                                                ) + "..."}
                                              </Tooltip>
                                            ) : (
                                              VehicleData.SuspensionType
                                            )}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Tyre size (Rear):
                                          </span>
                                          <span className="dtl">
                                            {VehicleData.TyresizeRear && VehicleData.TyresizeRear.length > 20 ? (
                                              <Tooltip
                                                content={VehicleData.TyresizeRear}
                                                tagName="firmTooltip"
                                                direction="right"
                                              >
                                                {VehicleData.TyresizeRear.substring(0, 20 - 3) +
                                                  "..."}
                                              </Tooltip>
                                            ) : (
                                              VehicleData.TyresizeRear
                                            )}
                                          </span>
                                        </li>
                                      </ul>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={11}>
                          <h4 className="card-title-18">Other Information</h4>
                          <Row className="justify-content-center">
                            <Col md={10}>
                              <div className="VehicleDetails-panel-content">
                                <div className="list-column">
                                  <Row className="justify-content-center">
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">Battery:</span>
                                          <span className="dtl">
                                           {VehicleData.Battery}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Fuel consumption indicator:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.FuelIndicator}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">Headlight</span>
                                          <span className="dtl">
                                            {VehicleData.Headlight}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Brakelight / Taillight:
                                          </span>
                                          <span className="dtl">
                                          {VehicleData.Brakelight_Taillight}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Tachometer:
                                          </span>
                                          <span className="dtl">
                                         {VehicleData.Tachometer}
                                          </span>
                                        </li>
                                      </ul>
                                    </Col>
                                    <Col md={6}>
                                      <ul className="list-style-two p-0">
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Gear Position Indicator:
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.GearPosition}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Cut-off switch
                                          </span>
                                          <span className="dtl">
                                           {VehicleData.Cut_offSwitch}
                                          </span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Position Light:
                                          </span>
                                          <span className="dtl"> {VehicleData.PositionLight}</span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Speedometer:
                                          </span>
                                          <span className="dtl">{VehicleData.Speedometer}</span>
                                        </li>
                                        <li className="clearfix">
                                          <span className="ttl">
                                            Fuel gauge:
                                          </span>
                                          <span className="dtl">{VehicleData.Fuelgauge}</span>
                                        </li>
                                      </ul>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {/* <Col md={10}>
                          <h4 className="card-title-18">Extra Features</h4>
                        </Col> */}
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    VehicleDetailslist,
    message,
    error_msg,
    rowdata,
  } = state.VehicleDetails;

  return {
    VehicleDetailslist,
    message,
    error_msg,
    rowdata,
  };
};

export default connect(mapStatetoProps, {
  getVehicleDetailsList,
  getRowVehicleDetailsList,
  // VehicleDetailsAdd,
  // VehicleDetailsEdit,
  // VehicleDetailsDelete,
  // model_open,
  // model_close,
})(ViewVehicleDetails);
