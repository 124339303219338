export const VERSIONS = 'versions';
export const VERSIONS_ADD = 'versions_add';
export const VERSIONS_DELETE = 'versions_deleted';
export const VERSIONS_EDIT = 'versions_edit';

// export const VERSIONS_SETROWDATA = 'versions_set_rowdata';
export const MODEL_OPEN = 'versions_model_open';
export const MODEL_CLOSE = 'versions_model_close';

export const VERSIONS_LIST_SUCCESSFUL = 'versions_list_successfull';
export const VERSIONS_ADD_SUCCESSFUL = 'versions_add_successfull';
export const VERSIONS_EDIT_SUCCESSFUL = 'versions_edit_successfull';

export const API_ERROR = 'api_failed';
