import React, { Component, Fragment } from "react";
import { MDBDataTableV5 } from 'mdbreact';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";

import {
  getVehicleTypesList,
  VehicleTypesAdd,
  VehicleTypesEdit,
  VehicleTypesDelete,
  model_open,
  model_close,
} from "../../../store/Masters/VehicleTypes/actions";

class VehicleTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getVehicleTypesList();
  }

  handleDelete(values) {
    this.props.VehicleTypesDelete(values, this.props.history);
  }

  deleteRecord = (record) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success mr-2',
        cancelButton: 'btn btn-danger mr-2'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.handleDelete(record.id);
        // swalWithBootstrapButtons.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })
  };

  render() {
    var listdata = this.props.VehicleTypeslist.map((row, order) => ({
      ...row,
      action: (
        <Fragment>
          <i
            className="mdi mdi-pencil-box-outline mr-2 font-size-18"
            style={{ cursor: "pointer", color: "#fc6f03" }}
            onClick={() => this.props.VehicleTypesEdit(row)}
          ></i>
          <i
            className="mdi mdi-delete mr-2 font-size-18"
            style={{ cursor: "pointer", color: "#ff0000" }}
            onClick={() => this.deleteRecord(row)}
          ></i>
        </Fragment>
      ),
    }));

    const data = {
      columns: [
        {
          label: "Code",
          field: "code",
          sort: 'disabled',
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 800,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
          },
        },
        {
          label: "Actions",
          field: "action",
          key: "id",
          text: "Actions",
          sort: 'disabled',
          width: 50,
        },
      ],
      rows: listdata,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <div className="row">
                      <div className="col-md-4">
                        <Link to="/MastersDashboard">
                          <i className="ri-arrow-go-back-fill font-size-18"></i>
                        </Link>
                        <h5 className="card-title-18 d-inline-block ml-2">
                        Vehicle Types Master
                        </h5>
                      </div>
                      <div className="col-md-4 text-center">
                        {this.props.message == null ? (
                          <div className="text-success">
                            {this.props.message}
                          </div>
                        ) : (
                          <div className="text-success">
                            Vehicle Types {this.props.message} Successfull.
                          </div>
                        )}
                        {/* <div className="text-danger">
                          {this.props.error_msg}
                        </div> */}
                      </div>
                      <div className="col-md-4 text-right">
                        <Button
                          type="button"
                          color="primary"
                          className="waves-effect waves-light"
                          onClick={() => this.props.model_open()}
                        >
                          Add Vehicle Type
                        </Button>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <MDBDataTableV5 
                      hover
                      striped
                      responsive
                      bordered
                      paging={true}
                      data={data}
                      searchTop 
                      searchBottom={false}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { VehicleTypeslist, model, message, error_msg, rowdata } = state.VehicleTypes;
  return { VehicleTypeslist, model, message, error_msg, rowdata };
};

export default connect(mapStatetoProps, {
  getVehicleTypesList,
  VehicleTypesAdd,
  VehicleTypesEdit,
  VehicleTypesDelete,
  model_open,
  model_close,
})(VehicleTypes);
