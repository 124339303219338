import React, { Component } from "react";
import {
  Container,
  Card,
  // CardHeader,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import CompanyDetails from "../CompanyDetails";
import Users from "./Users";
import Profile from "./Profile";
import AdminSidebar from "../AdminSidebar/AdminSidebar";

class Companysetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Masters", link: "#" },
        { title: "Company Setting", link: "#" },
      ],
      activeTab: "1",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Row className="no-gutters">
          <Col md={1}>
          <AdminSidebar />
          </Col>
          <Col md={11} className="pl-5">
            <div className="page-content">
              <Container fluid>
                {/* <Breadcrumbs
              title="Company Setting"
              breadcrumbItems={this.state.breadcrumbItems}
            /> */}

                <Row>
                  <Col lg={12}>
                    <Card>
                      {/* <CardHeader className="bg-transparent border-bottom">
                    <h3 className="card-title pt-2">Users & Profile</h3>
                  </CardHeader> */}
                      <CardBody className="pt-0">
                        <Nav tabs className="nav-tabs-custom mb-4">
                          <NavItem>
                            <NavLink
                              onClick={() => {
                                this.toggleTab("1");
                              }}
                              className={classnames(
                                { active: this.state.activeTab === "1" },
                                "pt-3 font-weight-bold card-title-18"
                              )}
                            >
                             Users
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              onClick={() => {
                                this.toggleTab("2");
                              }}
                              className={classnames(
                                { active: this.state.activeTab === "2" },
                                "pt-3 font-weight-bold card-title-18"
                              )}
                            >
                              Profiles
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId={"1"}>
                            <Users />
                          </TabPane>
                          <TabPane tabId={"2"}>
                            <Profile />
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default Companysetting;
