import React, { Component } from "react";
import {
  Container,
  Card,
  CardHeader,
  Button,
  CardBody,
  CardFooter,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";

import "./Order.scss";
import Imgslidewithcontrol from "./imgslidewithcontrol";

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      isAlertOpen: false,
      Vehicle: [
        { id: 1, ttl: "Vehicle Number", dtl: "AP39FR0000" },
        { id: 2, ttl: "Version", dtl: "R15 V3" },
        { id: 3, ttl: "Model/year", dtl: "2020" },
        { id: 4, ttl: "Brand", dtl: "YAMAHA" },
        { id: 5, ttl: "Colour", dtl: "Racing blue" },
        { id: 6, ttl: "Engine number", dtl: "ME4JF507EHT239418" },
        { id: 7, ttl: "Chassis number", dtl: "MD62AG79K2E04059" },
      ],
    };

    this.tog_static = this.tog_static.bind(this);
  }
  tog_static() {
    this.setState((prevState) => ({
      modal_static: !prevState.modal_static,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <AvForm className="needs-validation">
              <Row>
                <Col md={12}>
                  <Card className="mb-0 mt-0">
                    <CardHeader className="bg-transparent border-bottom ">
                      <Link
                        to="/OrdersDashboard"
                        className="action-icon text-primary mr-2"
                      >
                        {" "}
                        <i className="mdi mdi-arrow-left-bold-circle-outline font-size-20"></i>
                      </Link>

                      <h5 className="card-title-18 pt-2 d-inline-block">
                        Order Details <span>(#OD001)</span>
                      </h5>
                    </CardHeader>
                    <CardBody className="pt-1  ">
                      <Row className="p-1 ">
                        <Col md={12} className="p-1 m-0">
                          <Card className="p-1 m-0 ">
                            <CardBody className="pt-1 pb-1">
                              <Row className="p-0">
                                <Col md={8} className="p-1 m-0">
                                  <div className="Indent-Request-panel-content">
                                    <div className="list-column">
                                      <Row>
                                        <Col md={6}>
                                          <ul className="list-style-two p-0">
                                            <li className="clearfix">
                                              <span className="ttl">
                                                Customer Name:
                                              </span>
                                              <span className="dtl">
                                                Nagendra Gopal
                                              </span>
                                            </li>
                                            <li className="clearfix">
                                              <span className="ttl">
                                                Customer City:{" "}
                                              </span>
                                              <span className="dtl">
                                                {" "}
                                                Vizag
                                                {/* {Material.dDate} */}
                                              </span>
                                            </li>
                                            <li className="clearfix">
                                              <span className="ttl">
                                                Customer Phone:
                                              </span>
                                              <span className="dtl">
                                                {" "}
                                                8790088011
                                                {/* {Material.SUBMITED} */}
                                              </span>
                                            </li>
                                          </ul>
                                        </Col>
                                        <Col md={6}>
                                          <ul className="list-style-two p-0">
                                            <li className="clearfix">
                                              <span className="ttl">
                                                Product Name:
                                              </span>
                                              <span className="dtl">
                                                {" "}
                                                HONDA SP 125
                                                {/* {Material.SUBMITED} */}
                                              </span>
                                            </li>
                                            <li className="clearfix">
                                              <span className="ttl">
                                                Price:
                                              </span>
                                              <span className="dtl">
                                                {" "}
                                                ₹ 24050
                                                {/* {Material.SUBMITED} */}
                                              </span>
                                            </li>
                                            <li className="clearfix">
                                              <span className="ttl">
                                                Order Date:
                                              </span>
                                              <span className="dtl">
                                                {" "}
                                                Jan 20 2021
                                                {/* {Material.SUBMITED} */}
                                              </span>
                                            </li>
                                          </ul>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={12} className="p-1">
                          <Card className="p-1">
                            <CardHeader className="bg-transparent">
                              <h3 className="card-title-18">Product Details</h3>
                            </CardHeader>

                            <CardBody className="pt-1 pb-0">
                              <Row className="p-0">
                                <Col md={6}>
                                  <Imgslidewithcontrol />
                                </Col>
                                <Col md={6}>
                                  <div className="info-column ">
                                    <div className="details-header">
                                      <h4>Honda SP 125</h4>
                                      <div className="item-price">
                                        <h2>&#x20b9; 76,195</h2>
                                      </div>
                                    </div>

                                    <div className="Indent-Request-panel-content">
                                      {this.state.Vehicle.map(
                                        (Vehicle, key) => (
                                          <div className="list-column">
                                            <ul className="list-style-two p-0">
                                              <li className="">
                                                <span className="ttl">
                                                  {Vehicle.ttl}
                                                </span>
                                                <span className="dtl">
                                                  {Vehicle.dtl}
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <CardFooter className="bg-transparent pt-0">
                        <div className="float-right">
                          <Button
                            type="reset"
                            color="light"
                            className="waves-effect waves-light mr-2"
                            href="/OrdersDashboard"
                          >
                            {/* <i className="mdi mdi-filter mr-1"></i> */}
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="success"
                            className="waves-effect waves-light mr-2"
                          >
                            <i className="mdi mdi-thumb-up mr-2"></i>
                            Approved
                          </Button>
                          <Button
                            type="submit"
                            color="danger"
                            className="waves-effect waves-light"
                            // href="/Material"
                          >
                            <i className="mdi mdi-thumb-down mr-2"></i>
                            Reject
                          </Button>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PurchaseOrder;
