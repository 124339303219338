import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";


import { Row, Col, Card, CardBody, Modal,Container, ModalHeader, ModalBody, ModalFooter, Button,UncontrolledTooltip, Alert, FormGroup, Label  } from "reactstrap";


// import "./datatables.scss";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
//import "./datatables.scss";

//import BootstrapTable from 'react-bootstrap-table-next';
//import ToolkitProvider from 'react-bootstrap-table2-toolkit';

class Companys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
        { title : "Masters", link : "#" },
        { title : "Company's", link : "#" },
    ],
    isAlertOpen : false,
    
    };
    this.tog_static = this.tog_static.bind(this);
  }
  tog_static() {
    this.setState(prevState => ({
      modal_static: !prevState.modal_static
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }




  render() {

    const data = {
      columns: [
        {
          label: "Code",
          field: "Code",
          sort: "asc",
          width: 150
        },
        {
          label: "Name",
          field: "Name",
          sort: "asc",
          width: 270
        },
        {
          label: "Mobile",
          field: "Mobile",
          sort: "asc",
          width: 100
        },
        {
          label: "Email",
          field: "Email",
          sort: "asc",
          width: 200
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 150
        },
       
      ],
      
      rows: [
        
        {        
            Code: "01",
            Name: "Nagendra Gopal K ",
            Mobile: "8790000000",
            Email: "gopal@gmail.com",
            action : <><Link to="#" className="mr-3 text-primary" id="edit2"><i className="mdi mdi-pencil font-size-18"></i></Link>
                      <UncontrolledTooltip placement="top" target="edit2">
                       Edit
                       </UncontrolledTooltip >
                      <Link to="#" className="text-danger" id="delete2"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                      <UncontrolledTooltip placement="top" target="delete2">
                          Delete
                      </UncontrolledTooltip >
                   </>
          
        },
        
      ]
    };
    

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

          <Breadcrumbs title="Company's" breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                     
                  <Col xs={12}className="mt-4">
                        <div className="my-4 text-right">
                          <Button
                            type="button"
                            color="primary" className="waves-effect waves-light"
                            onClick={this.tog_static}
                          >
                            Add Company
                        </Button>
                        </div>

                        <Modal
                          isOpen={this.state.modal_static}
                          toggle={this.tog_static}
                          backdrop="static"
                          centered
                          size = "lg"
                        >
                          <ModalHeader toggle={() => this.setState({ modal_static: false })}>
                          Add Company
                          </ModalHeader>
                          <ModalBody>
                          <AvForm onValidSubmit={this.addCustomer}>
                                <Row>
                                    <Col lg={12}>
                                    <Alert color="success" isOpen={this.state.isAlertOpen} toggle={ () => this.setState({isAlertOpen : false}) }>
                                        Company Added Successfully...!
                                    </Alert>
                                    <FormGroup>
                                        <Label htmlFor="name">Company Name</Label>
                                        <AvField
                                            name="custname"
                                            type="text"
                                            className="form-control"
                                            id="custname"
                                            placeholder="Enter Customer Name"
                                            required
                                        />
                                    </FormGroup>
                                    </Col>
                                </Row>

                                    <Row>
                                        <Col lg={6}>
                                        <FormGroup>
                                            <Label htmlFor="email">Email</Label>
                                            <AvField
                                            name="custemail"
                                            type="email"
                                            className="form-control"
                                            id="custemail"
                                            placeholder="Enter Email"
                                            required
                                            />
                                        </FormGroup>
                                        </Col>

                                        <Col lg={6}>
                                        <FormGroup>
                                            <Label htmlFor="email">Phone Number</Label>
                                            <AvField
                                            name="phonenumber"
                                            type="number"
                                            className="form-control"
                                            id="phonenumber"
                                            placeholder="Enter Phone Number"
                                            required
                                            />
                                        </FormGroup>
                                        </Col>

                                        {/* <Col lg={4}>
                                        <FormGroup>
                                            <Label htmlFor="email">Wallet Balance</Label>
                                            <AvField
                                            name="wBalance"
                                            type="number"
                                            className="form-control"
                                            id="wBalance"
                                            placeholder="Wallet Balance"
                                            required
                                            />
                                        </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <ModalFooter>
                                        <Button type="button" color="light" onClick={() => this.setState({ modal_static: false }) }>Calcel</Button>
                                        <Button type="submit" color="primary">Add Company</Button>
                                    </ModalFooter> 
                            
                            </AvForm>
                          </ModalBody>
                        </Modal>
                      </Col>
                     
                    <MDBDataTable responsive bordered data={data} className="mt-4" />


                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Companys;
