export const MODEL_YEAR = 'model_years';
export const MODEL_YEAR_ADD = 'model_years_add';
export const MODEL_YEAR_DELETE = 'model_years_deleted';
export const MODEL_YEAR_EDIT = 'model_years_edit';

export const MODEL_YEAR_SETROWDATA = 'model_years_set_rowdata';
export const MODEL_OPEN = 'model_years_model_open';
export const MODEL_CLOSE = 'model_years_model_close';

export const MODEL_YEAR_LIST_SUCCESSFUL = 'model_years_list_successfull';
export const MODEL_YEAR_ADD_SUCCESSFUL = 'model_years_add_successfull';
export const MODEL_YEAR_EDIT_SUCCESSFUL = 'model_years_edit_successfull';

export const API_ERROR = 'api_failed';
