import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import OrdersDashboard from "../pages/OrdersDashboard/index";
import Order from "../pages/OrdersDashboard/Order/Order";

import VehicleDashboard from "../pages/VehicleDashboard/VehicleDashboard";

import UserProfile from "../pages/UserProfile/UserProfile";
import ChangePassword from "../pages/UserProfile/ChangePassword";

//
import MastersDashboard from "../pages/MastersDashboard/Index";
import Companysetting from "../pages/CompanySettings/CompanyDetails";

//
import Companylist from "../pages/CompanySettings/UsersProfile/Index";

import AddProfile from "../pages/CompanySettings/UsersProfile/AddProfile/Index";
import EmailSMS from "../pages/CompanySettings/EmailSMS";
import ImportData from "../pages/CompanySettings/ImportData/ImportData";
import Yourdevices from "../pages/CompanySettings/yourdevices/yourdevices";
import SecurityDashboard from "../pages/CompanySettings/SecurityDashboard/SecurityDashboard";

// //Mater pages
import Companys from "../pages/Companys/Companys";
import locations from "../pages/MastersDashboard/Locations/Locations";
import Brand from "../pages/MastersDashboard/Brand/Brand";
import ModelYears from "../pages/MastersDashboard/ModelYears/ModelYears";
import VehicleTypes from "../pages/MastersDashboard/VehicleTypes/VehicleTypes";
import Dealers from "../pages/MastersDashboard/Dealers/Dealers";
import Versions from "../pages/MastersDashboard/Versions/Versions";
import VersionTypes from "../pages/MastersDashboard/VersionTypes/VersionTypes";
import VehicleDetails from "../pages/MastersDashboard/VehicleDetails/VehicleDetails";
import AddVehicleDetails from "../pages/MastersDashboard/VehicleDetails/AddVehicleDetails";
import ViewVehicleDetails from "../pages/MastersDashboard/VehicleDetails/ViewVehicleDetails";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/OrdersDashboard", component: OrdersDashboard },
  { path: "/Order", component: Order },

  { path: "/VehicleDashboard", component: VehicleDashboard },

  { path: "/UserProfile", component: UserProfile },
  { path: "/ChangePassword", component: ChangePassword },
  { path: "/MastersDashboard", component: MastersDashboard },
  { path: "/Getcompany", component: Companysetting },

  { path: "/Users", component: Companylist },
  { path: "/AddProfile", component: AddProfile },
  { path: "/EmailSMS", component: EmailSMS },
  { path: "/ImportData", component: ImportData },
  { path: "/yourdevices", component: Yourdevices },
  { path: "/SecurityDashboard", component: SecurityDashboard },

  // Mater pages
  { path: "/Companys", component: Companys },
  { path: "/Locations", component: locations },
  { path: "/Brand", component: Brand },
  { path: "/ModelYears", component: ModelYears },
  { path: "/VehicleTypes", component: VehicleTypes },
  { path: "/Dealers", component: Dealers },
  { path: "/Versions", component: Versions },
  { path: "/VersionTypes", component: VersionTypes },
  { path: "/VehicleDetails", component: VehicleDetails },
  { path: "/AddVehicleDetails/:id", component: AddVehicleDetails },
  { path: "/ViewVehicleDetails/:id", component: ViewVehicleDetails },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auth-lock-screen", component: AuthLockScreen },
];

export { authProtectedRoutes, publicRoutes };
