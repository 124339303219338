import {
  API_ERROR,
  BRAND,
  BRAND_LIST_SUCCESSFUL,
  BRAND_ADD,
  BRAND_ADD_SUCCESSFUL,
  BRAND_EDIT,
  BRAND_EDIT_SUCCESSFUL,
  BRAND_DELETE,
  BRAND_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  brandslist: [],
  model: false,
  error_msg: null,
  rowdata: {},
};

const brand = (state = initialState, action) => {
  switch (action.type) {
    case BRAND:
      
      state = {
        ...state,
        loading: true,
      };
      break;
    case BRAND_LIST_SUCCESSFUL:
      state = {
        ...state,
        brandslist: action.payload.data,
        loading: false,
      };
      break;
    case BRAND_ADD:
      state = {
        ...state,
        loading: false,
      };
      break;
    case BRAND_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
      };
      break;
    case BRAND_EDIT:
      state = {
        ...state,
        model: true,
        rowdata: action.payload,
      };
      break;
    case BRAND_EDIT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case BRAND_DELETE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case BRAND_SETROWDATA:
      state = {
        ...state,
        rowdata: action.payload,
        // model: true,
      };
      break;
    case MODEL_OPEN:
      state = {
        ...state,
        model: true,
      };
      break;
    case MODEL_CLOSE:
      state = {
        ...state,
        model: false,
        rowdata: { id: 0 },
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default brand;
